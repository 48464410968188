import { fansupportPublic } from "../index";

export const getAllCountry = async () => {
  try {
    const res = fansupportPublic.get('/sport/countries-all');

    return res;
    
  } catch (error) {
    console.log("getAllCountry",error)
  }
};