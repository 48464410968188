import { fansupportPrivate } from "../index"

export const getCertificate = async ({ campaignId, size, format }) => {
    try {
        const data = fansupportPrivate.get('/document/certificate', {params: {
            campaignId,
            size,
            format
        }})

        return data;
    } catch (error) {
        console.log("getCertificate",error)
    }
}