import { fansupportPublic } from "../index";

export const getRss = async ({ LanguageId, SportId, CountryId, page, perPage }) => {
    return fansupportPublic.get('/rss', {params: {
        LanguageId,
        SportId,
        CountryId,
        page,
        perPage,
    }})
}