import React from 'react';
import { NavbarLandingPage } from '../../components';
import AboutUs from '../help/AboutUs';
import styled from "styled-components";

const LandingPage = ({ onButtonClick }) => {
  return (
    <>
        <NavbarContainerWrapper>
            <NavbarLandingPage onButtonClick={onButtonClick}/>
        </NavbarContainerWrapper>
        <AboutUs />
    </>
  )
}

export default LandingPage

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;