import React, { useEffect, useState } from 'react';
import { Flex, Grid } from '@mantine/core';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Accordions, Card, AppFeatures, SEO } from "../../components";
import { homeRail, homeRail2 } from "../../utils/config";
import { getAdsMain } from '../../services/ads/adsMainService';
import LanguageTag from '../../enums/LanguageTag';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { useSelector } from 'react-redux';

const AboutUs = () => {

  const [opened, { open, close }] = useDisclosure(true);
  const [localCountryId, setLocalCountryId] = useState(localStorage.getItem("startCountryId"))
  const [adsMainData, setAdsMainData] = useState([]);
  const [adsMainInterval, setAdsMainInterval] = useState();
  const [lastShown, setLastShown] = useState(null);

  const user  = useSelector((state) => state.user.user);

  const { t } = useTranslation();


  const fetchAdsMain = async () => {
    try {
      const storedLanguageId = localStorage.getItem("languageId");
      const originalLanguage = navigator.language;
      const newLanguage = originalLanguage.split('-')[0];

      const language = Object.keys(LanguageTag).find(
        (key) => LanguageTag[key] === newLanguage
      );
      
      const languageId = storedLanguageId ? storedLanguageId : language;

      const countryId = localCountryId;
      const platformType = 1;
  
      const res = await getAdsMain({ countryId, languageId, platformType })
  
      setAdsMainData(res?.data?.data?.url || "");
      setAdsMainInterval(res?.data?.data?.interval || []);
    } catch (error) {
      console.log("Ads Error:",error);
    }
  }

  useEffect(() => {
    fetchAdsMain();
    // adsMainInterval değerini milisaniyeye çeviriyoruz
    const intervalDuration = adsMainInterval * 1000;

    // Modalı gösteren ve belirli süre sonra kapatan fonksiyon
    const showModal = () => {
      open();
      setTimeout(() => {
        close();
      }, intervalDuration); // adsMainInterval değeri kadar süre bekle
    };

    // İlk başta modalı göster
    showModal();

    if (user) {
    // İlk başta modalı göster
      // const now = new Date().getTime();
      // const oneHour = 3600000;

      // if (!lastShown || now - lastShown >= oneHour) {
      //   open();
      //   setLastShown(now);
      // }

      // const interval = setInterval(() => {
      //   open();
      //   setLastShown(new Date().getTime());
      // }, oneHour);

      // open();
      // setLastShown(Date.now());

      // adsMainInterval değerini milisaniyeye çeviriyoruz

      // Modalı gösteren ve belirli süre sonra kapatan fonksiyon
      
      
      setInterval(() => {
        open();
        setLastShown(Date.now());
        showModal();
      }, 60000); // 10000 milliseconds = 1 seconds

    }
  }, [user, adsMainData]);


  return (
    <>
      <div>
        <SEO
          title={t("homeMetaTitle")}
          description={t("homeMetaDescription")}
          keywords={t("homeMetaKeywords")}
          url="https://www.fansupport.com/"
        />
      </div>
        <HomeMain className='about-us-main'>    
          <WhatFsBanner id="what" className='single-sections'>

            <WhatFsBannerContainer>



                <WhatFsBannerLeft>
                  
                      <FanSupportDownloadTitleFirst className='animate__animated animate__fadeInDown'>{t("title")}</FanSupportDownloadTitleFirst>
                      {/* <FanSupportDownloadTitleSecond>{t("staticLabel")}</FanSupportDownloadTitleSecond> */}
                      <FanSupportDownloadDesc className='animate__animated animate__fadeInDown'>{t("staticDesc")}</FanSupportDownloadDesc>
                    
                      <Flex
                        mih={50}
                        bg=""
                        justify="center"
                        align="center"
                        dirction={{ base: 'column', sm: 'row' }}
                        gap={{ base: 'sm', sm: 'lg' }}
                        wrap="wrap"
                      >
                        <NavbarLink href="https://apps.apple.com/tr/app/fansupport/id1569960194" target='blank'>
                          <DownloadImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/da757abe-bc5e-4285-7e65-05818cc2b100/original" alt=""/>
                        </NavbarLink>
                        <NavbarLink href="https://play.google.com/store/apps/details?id=com.fansupport.fansupport_mobile">
                          <DownloadImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/0b74d68a-0d36-4325-a9c2-13b2b9c1d700/original" alt="" />
                        </NavbarLink>
                        <NavbarLink href="https://appgallery.huawei.com/app/C104672889">
                          <DownloadImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a872b0c-e413-4f04-1384-84339f1d9a00/original" alt="" />
                        </NavbarLink>                                                                                                 
                      </Flex>
                       
                </WhatFsBannerLeft>

                <WhatFsBannerRight>
                    <FsDownloadİmage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/2bb5a834-fae2-4401-4f3f-655fdec0bd00/original" alt="mobile"/>
                </WhatFsBannerRight>
                
              

            </WhatFsBannerContainer>

          </WhatFsBanner>

          <ConditionalSupportBanner id="support-conditions" className='double-sections'>

          <ConditionalSupportContainer>
            <CardTitle>{t("staticSupportTypes")}</CardTitle>

            <ConditionalSupportContent>
              <Grid justify="center" gutter="xl" mx={0}>
                {homeRail.map((data, index) => (
                  <Grid.Col md={6} lg={3}>
                    <Card
                      key={index}
                      img={data.img}
                      title={t(data.title)}
                      desc={t(data.desc)}
                      url={data.url}
                      imageWidth="50px"
                      imageHeight="50px"
                      backgroundColor="white"
                      textColor="black"
                      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                      className="conditional-support-card animate__animated animate__fadeInDown"
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </ConditionalSupportContent>
          </ConditionalSupportContainer>

          </ConditionalSupportBanner>

          <HowSupportBanner id="how" className='single-sections'>

          <HowSupportContainer>
            <CardTitle>{t("whyTitle")}</CardTitle>

            <HowSupportBannerContent>
              <Grid justify="center" gutter="xl" mx={0}>
                {homeRail2.map((data, index) => (
                  <Grid.Col md={6} lg={3}>
                    <Card
                      key={index}
                      img={data.img}
                      title={t(data.title)}
                      desc={t(data.desc)}
                      url={data.url}
                      imageWidth="150px"
                      imageHeight="150px"
                      backgroundColor="none"
                      textColor="white"
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </HowSupportBannerContent>
          </HowSupportContainer>

          </HowSupportBanner>

          <FeaturesBanner id="features" className='double-sections'>            

            <AppFeatures />
              
          </FeaturesBanner>

          <DownloadFsBanner id="download">
            
            <DownloadBgImageLg src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/83390920-717b-42c3-61ec-d4f94ed6e400/original" alt=''/>
            <DownloadBgImageSm src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/83305ac4-c1b0-43be-89ca-347b042f4d00/original" alt=''/>
            <DownloadImageContainer>
              <Flex
                mih={50}
                bg=""
                justify="center"
                align="center"
                dirction={{ base: 'column', sm: 'row' }}
                gap={{ base: 'sm', sm: 'lg' }}
                wrap="wrap"
              >
                <NavbarLink href="https://apps.apple.com/tr/app/fansupport/id1569960194" target='blank'>
                  <DownloadFsImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/da757abe-bc5e-4285-7e65-05818cc2b100/original" alt=""/>
                </NavbarLink>
                <NavbarLink href="https://play.google.com/store/apps/details?id=com.fansupport.fansupport_mobile">
                  <DownloadFsImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/0b74d68a-0d36-4325-a9c2-13b2b9c1d700/original" alt="" />
                </NavbarLink>
                <NavbarLink href="https://appgallery.huawei.com/app/C104672889">
                  <DownloadFsImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a872b0c-e413-4f04-1384-84339f1d9a00/original" alt="" />
                </NavbarLink> 
                </Flex>
            </DownloadImageContainer>
            
          </DownloadFsBanner>

          <AccordionSection id="questions" className='double-sections'>
            <AccordionTitle className='accordion-title'>{t("faq")}</AccordionTitle>
            <Accordions />
          </AccordionSection>   

          

        </HomeMain>
        {adsMainData && adsMainData.length > 0 && 
          <div>
            <Modal
              opened={opened}
              onClose={close}
              title="FANSUPPORT"
              size="auto"
              centered
            >
              <img
                src={adsMainData}
                style={{ width: "auto", height: "300px" }}
                alt=""
              />     
            </Modal>
          </div>
        }
    </>
  );
};

export default AboutUs;



export const HomeMain = styled.main``;

export const WhatFsBanner = styled.section`
  background-color: var(--bg-navbar);
  color: white;
`;

export const DownloadImage = styled.img`
  width: 150px;
  height: auto;
`;
export const DownloadFsImage = styled.img`
  width: 150px;
  height: auto;
`;
export const DownloadImageContainer = styled.div`
  position: absolute;
  bottom: 25px; 
  left: 80px; 

  @media (max-width: 900px) {
    width: 212px;
    bottom: 15px; 
    left: 0; 

    img {
      width: 70px;
      height: auto;
    }
  }
`;

export const FanSupportDownloadTitleFirst = styled.h2`
  text-align: center;
  color: orange;
  font-size: 27px;
`;

export const FanSupportDownloadDesc = styled.p`
  font-size: 21px;
  text-align: center;
  margin: 5% 0;
  line-height: 1.8;
`;

export const FsDownloadİmage = styled.img`
  max-width: 450px;

  @media (max-width: 900px) {
    max-width: 350px;
  }
`;
export const ConditionalSupportContainer = styled.div`
  padding: 50px 0;
`;
export const ConditionalSupportContent = styled.div``;
export const ConditionalSupportBanner = styled.section``;

export const CardTitle = styled.div`
  color: orange;
  padding-bottom: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

export const HowSupportContainer = styled.div`
  padding: 50px 0;
`;
export const HowSupportBannerContent = styled.div``;
export const HowSupportBanner = styled.section`
  background-color: var(--bg-navbar);
`;

export const FeaturesBanner = styled.section`
  // width: 1100px;
  margin: 50px auto;
`;

export const AccordionSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 4% auto;
  max-width: 70%;
  text-align: center;

  @media (max-width: 900px) {
    max-width: 100%;
    margin-bottom: 10%;
  }
`;
export const AccordionTitle = styled.h2`
  color: black;
  margin-bottom: 5%;
`;
export const DownloadFsBanner = styled.section`
  margin: 50px 0;
  position: relative;
`;
export const DownloadBgImageLg = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 900px) {
    display: none;
  }
`;
export const DownloadBgImageSm = styled.img`
  width: 100%;
  height: auto;

  @media (min-width: 900px) {
    display: none;
  }
`;
export const NavbarLink = styled.a``;

export const WhatFsBannerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: 0px auto;
  padding: 50px 0;
`;
// export const WhatFsBannerContent = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   max-width: 1300px;
// `;

export const WhatFsBannerLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const WhatFsBannerRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
`;