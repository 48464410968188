const CampaignType = {
    0: 'transfer',
    1: 'event',
    2: 'championship',
    3: 'score',
    4: 'unconditional',
    5: 'round',
    6: 'social',
};

export const CampaignTypes = {
    TRANSFER: 0,
    EVENT: 1,
    CHAMPIONSHIP: 2,
    SCORE: 3,
    UNCONDITIONAL: 4,
    ROUND: 5,
    SOCIAL: 6,
};

export default CampaignType;
