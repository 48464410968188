import { object, string, date, bool } from "yup";

const userSchema  = (t) => object({   

    username: string().min(2).required(t("numberCharactersLeastTwo")),
    firstname: string().min(2).required(t("numberCharactersLeastTwo")),
    lastname: string().min(2).required(t("numberCharactersLeastTwo")),
    phoneNumber: string().min(7,t("enterValidPhoneNumber")).required(t("fieldEmpty")),

    email: string().email().required(t("invalidEmailError")),
    birthday: date()
        .required(t("enterValidDate"))
        .min(new Date(new Date().setFullYear(new Date().getFullYear() - 100)), t("ageCannotExceed100"))
        .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), t("notBeEighteenAge"))
        .default(() => new Date()),
    password: string().min(8, t("numberCharactersLeastEight"))
        .matches(/[A-Z]/, t("mustContainUppercase"))
        .matches(/[0-9]/, t("mustContainDigit"))
        .required(t("fieldEmpty")),
    isKvKK: bool().oneOf([true], t("confirmUserAndClearConsentAgreement")),  
})

export default userSchema