import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DateTimeFormatter } from "../../components";
import { NotFound } from "../../pages";
import { useSelector } from "react-redux";
import { getStatuses } from "../../services/sport/statusesService";
import { useViewportSize } from "@mantine/hooks";
import { Skeleton } from '@mantine/core';

const FixtureCard = ( fixtureData ) => {

  const [statusesData,setStatusesData] = useState();
  const [loading, setLoading] = useState(true);

  const filterFixture = useSelector((state) => state.filter.filterFixture);
  const {t} = useTranslation();
  const { width: screenWidth } = useViewportSize();

  const originalLanguage = navigator.language;
  const newLanguage = originalLanguage.split('-')[0];
  const storedLanguage = localStorage.getItem("selectedLanguage");
  const userLanguage = storedLanguage ? storedLanguage : newLanguage;

  // console.log("fixtureData",fixtureData);

  const fetchStatuses = async () => {
    try {
      const res = await getStatuses();
      setStatusesData(res?.data?.data || []);
      setLoading(false);
    } catch (error) {
      console.log("fetchStatuses Error",error);
      setLoading(false);
    } 
  }
  // console.log("fetch",statusesData);

  useEffect(() => {
    fetchStatuses();
  },[])

  return (
    <>
      { loading ? (
        <SkeletonCardContainer>
          <Skeleton height={50} radius="md" />
          <Skeleton height={50} mt="md" radius="md" />
          <Skeleton height={50} mt="md" radius="md" />
        </SkeletonCardContainer>
      ) : (
      
      fixtureData.data ? (
        fixtureData.data?.map((list) => (
          
        <FixtureCardContainer key={list.id} className="fixture-card-container">
          <FixtureCardLeft>
            

              <MatchHour>
                <DateTimeFormatter apiTarih={new Date(list.startDate || "")} dil={userLanguage} />
              </MatchHour>

          </FixtureCardLeft>

          <FixtureCardCenter>
            <FixtureCardSubCenter>
              <HomeTeamLink>
                <HomeTeam>{screenWidth <= 1100 ? t(list?.homeTeam?.shortName) : t(list?.homeTeam?.name)}</HomeTeam>
                <HomeTeamLogo src={list.homeTeam.mediumBadge}></HomeTeamLogo>
              </HomeTeamLink>
              {statusesData?.filter((status) => status.id === list?.status.id) ? (
                <> 
                  <TeamScoreStatusContent>
                   <CampaignsVsImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/ed3ebaf6-acdf-4260-1820-64b0289a9e00/original" />                                                                       

                    {list?.status.id === '6498b1ea1d6341510544c4f9' ? (
                      <>
                        <TeamScore>
                          <HomeTeamScore>{list?.homeTeam?.score?.current}</HomeTeamScore>
                          <TeamScoreSplit>-</TeamScoreSplit>
                          <AwayTeamScore>{list?.awayTeam?.score?.current}</AwayTeamScore>
                        </TeamScore>
                        <TeamScoreStatusName>{list?.status?.name}</TeamScoreStatusName>
                      </>
                    ) : (
                      <>
                        <TeamScore>
                          <HomeTeamScore>{list?.homeTeam?.score?.current}</HomeTeamScore>
                          <TeamScoreSplit>-</TeamScoreSplit>
                          <AwayTeamScore>{list?.awayTeam?.score?.current}</AwayTeamScore>
                        </TeamScore>
                        <TeamScoreStatusName>{list?.status?.name}</TeamScoreStatusName>
                      </>
                    )} 

                  </TeamScoreStatusContent>
                </>
              ) : (
                <TeamScore>
                  <CampaignsVsImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/ed3ebaf6-acdf-4260-1820-64b0289a9e00/original" />                
                </TeamScore>
              )}
              <AwayTeamLink>
                <AwayTeamLogo src={list.awayTeam.mediumBadge}></AwayTeamLogo>            
                <AwayTeam>{screenWidth <= 1100 ? t(list?.awayTeam?.shortName) : t(list?.awayTeam?.name)}</AwayTeam>
              </AwayTeamLink>
            </FixtureCardSubCenter>
          </FixtureCardCenter>

          <FixtureCardRight>

              <Stadium>
                  <StadiumTitle>{t("stadium")}</StadiumTitle>
                  <StadiumName>{list.info.stadium.name}</StadiumName>
              </Stadium>
              <Referee>
                  <RefereeTitle>{t("referee")}</RefereeTitle>
                  <RefereeName>{list.info.referee.name}</RefereeName>
              </Referee>
              
          </FixtureCardRight>

        </FixtureCardContainer>
      ))
      ) : (    
        <NotFound text={t("noFixtureData")} />  
      )
      )}
    </>
  );
};

export default FixtureCard;

const SkeletonCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
`;

export const FixtureCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid orange;
  border-radius: 20px;
  padding: 10px 5px;
  margin-bottom: 10px;
`;

export const FixtureCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 97px;
  gap: 10%;
  height: 92px;
`;
export const MatchHour = styled.div`
  font-size: 18px;
  font-weight: 300;
`;

export const FixtureCardCenter = styled.div`
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    max-width: 340px;
    width: 340px;
  }
`;

export const FixtureCardSubCenter = styled.div`
  // height: 92px;
  display: flex;
  align-items: center;
`;

export const HomeTeamLink = styled.a`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    width: auto;
  }
  @media (max-width: 900px) {
    width: auto;
  }
`;
export const HomeTeam = styled.span``;
export const HomeTeamLogo = styled.img`
  width: 36px;
  height: 36px;
  margin: 0 10px;

  @media (max-width: 700px) {
    display: none;
  }
`;
export const HomeTeamScore = styled.span``;

export const TeamScore = styled.a`
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
`;
export const TeamScoreStatusContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0 10px;
`;
export const TeamScoreStatusName = styled.span`
  font-size: 10px;
  font-weight: 300;
  // background-color: grey;
  color: black;
  padding: 10px;
  border-radius: 20px;
`;
export const TeamScoreSplit = styled.span`
  margin: 0 5px
`;

export const AwayTeamLink = styled.a`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    width: auto;
  }
  @media (max-width: 900px) {
    width: auto;
  }
`;
export const AwayTeam = styled.span``;
export const AwayTeamLogo = styled.img`
  width: 36px;
  height: 36px;
  margin: 0 10px;

  @media (max-width: 700px) {
    display: none;
  }
`;
export const AwayTeamScore = styled.span``;

export const FixtureCardRight = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Stadium = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  gap: 2px;
`;
export const StadiumTitle = styled.p`
  border-radius: 10px;
  margin: 0px;
  padding: 4px;
  text-align: center;
  font-size: 12px;
  background: grey;
  width: 50px;
  color: white;
`;
export const StadiumName = styled.p`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  text-align: center;
  font-weight: 300;
  font-size: 13px;

  @media (max-width: 720px){
    max-width: 50px;
  }
`;
export const Referee = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const RefereeTitle = styled.p`
  border-radius: 10px;
  padding: 4px;
  margin: 0px;
  text-align: center;
  font-size: 12px;
  background: grey;
  width: 50px;
  color: white;
`;
export const RefereeName = styled.p`
  font-weight: 300;
  margin: 0;
  text-align: center;
  font-size: 13px;

  @media (max-width: 720px){
    max-width: 50px;
  }
`;

export const CampaignsVsImage = styled.img`
  width: 30px;
  height: 30px;
`;