import { fansupportPublic } from "../index";

export const getStatuses= async () => {
  try {
    const res = fansupportPublic.get('/sport/statuses');

    return res;
    
  } catch (error) {
    console.log("getStatuses Error",error);
  }
};