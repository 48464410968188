import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FiPlusCircle } from "react-icons/fi";
import { RemoveFavoritesCard, Breadcrumbs } from "../../components";
import { NotFound } from "../../pages";
import { getFavoriteTeams } from "../../services/favoriteTeamsService";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";

const FavouriteTeams = () => {
  const userData = useSelector((state) => state.user.user?.data);

  const [favouriteTeamsData, setFavouriteTeamsData] = useState([]);


  const { t } = useTranslation();

  const fetchFavouriteTeams = async () => {

    const sportId = "";
    const page = 1;
    const perPage = 25;
    const res = await getFavoriteTeams({ sportId, page, perPage });
    setFavouriteTeamsData(res.data?.data?.data);
  };

  // console.log("favouriteTeamsData",favouriteTeamsData);

  const groupedTeams = {
    Football: [],
    Basketball: [],
    Volleyball: [],
  };
  
  favouriteTeamsData.forEach((team) => {
    switch (team.sportId) {
      case "6498b1e91d6341510544c3a7":
        groupedTeams.Football.push(team);
        break;
      case "6498b1e91d6341510544c3a8": 
        groupedTeams.Basketball.push(team);
        break;
      case "6498b1e91d6341510544c3a9": 
        groupedTeams.Volleyball.push(team);
        break;
      default:
        break;
    }
  });

  const footballButtonClick = () => {
    window.location.href = `${t("football").toLowerCase()}/${RemoveTurkishChars({ text: t("teams") })}`;
  };
  const basketballButtonClick = () => {
    window.location.href = `${t("basketball").toLowerCase()}/${RemoveTurkishChars({ text: t("teams") })}`;
  };
  const volleyballButtonClick = () => {
    window.location.href = `${t("volleyball").toLowerCase()}/${RemoveTurkishChars({ text: t("teams") })}`;
  };


  useEffect(() => {
    fetchFavouriteTeams();
  }, []);


  return (
    <>
      <FavouriteTeamsMain>
        <FavouriteTeamsBackground>
          <FavouriteTeamsDetailImg src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/f798c193-4b31-45dc-ac85-e68ed3691300/original" />

          <FavouriteTeamsDetailTitleBreadcrumbsContainer>
            <FavouriteTeamsDetailTitleContainer>
              <FavouriteTeamsDetailIcon src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a644523-45d9-4e66-7609-a62c110a2f00/original" />
              <FavouriteTeamsTitle>{t("favouriteTeams")}</FavouriteTeamsTitle>
            </FavouriteTeamsDetailTitleContainer>

            <Breadcrumbs />
          </FavouriteTeamsDetailTitleBreadcrumbsContainer>
        </FavouriteTeamsBackground>

        

        {userData ? (
         <Container>
          <FavouriteTeamsSection>

            <FavouriteSportCardContainer>

              <FavouriteSportCard className="favourite-sport-card">
                  {groupedTeams.Football.length > 0 ? (
                    <FavouriteSportCardContent>
                      <SportTitleContainer>
                        <SportTitle>{t("football")}</SportTitle>
                        
                        <RouteAddFavouriteButton 
                          onClick={footballButtonClick}
                        >
                          <FiPlusCircle size={16}/>
                            {t("addx")}
                        </RouteAddFavouriteButton>
                        
                      </SportTitleContainer>
                      
                      <RemoveFavoritesCard 
                        width="315px"
                        fontSize="9px"
                        favouriteTeamsData={groupedTeams.Football} 
                      />
                    </FavouriteSportCardContent>
                  ) : (
                  <RouteAddFavourite>
                    <SportTitle>{t("football")}</SportTitle>
                    <img src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/d37bf258-a3ef-453d-bb1f-90bbaa846300/original" alt=""/>
                    <RouteAddFavouriteButton 
                    onClick={footballButtonClick}
                    >
                      <FiPlusCircle size={16}/>
                      {t("addx")}
                    </RouteAddFavouriteButton>
                </RouteAddFavourite>
                )}
            </FavouriteSportCard>
            
            <FavouriteSportCard className="favourite-sport-card">
              
              {groupedTeams.Basketball.length > 0 ? (
                <div>
                  <FavouriteSportCardContent>
                    <SportTitleContainer>
                      <SportTitle>{t("basketball")}</SportTitle>
                      <RouteAddFavouriteButton 
                      onClick={basketballButtonClick}
                      >
                          <FiPlusCircle size={16}/>
                          {t("addx")}
                        </RouteAddFavouriteButton>
                    </SportTitleContainer>
                    
                    <RemoveFavoritesCard 
                      width="315px"
                      fontSize="9px"
                      favouriteTeamsData={groupedTeams.Basketball} 
                    />
                  </FavouriteSportCardContent>
                </div>
                ) : (
                  <RouteAddFavourite>
                    <SportTitle>{t("basketball")}</SportTitle>
                    <img src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5b92e6dd-5fbf-4578-1ab3-9c61648d3400/original" alt=""/>
                    <RouteAddFavouriteButton 
                    onClick={basketballButtonClick}
                    >
                      <FiPlusCircle size={16}/>
                      {t("addx")}
                    </RouteAddFavouriteButton>
                  </RouteAddFavourite>
              )}
            </FavouriteSportCard>

            <FavouriteSportCard className="favourite-sport-card">
              {groupedTeams.Volleyball.length > 0 ? (
                <div>
                  <FavouriteSportCardContent>
                    <SportTitleContainer>
                      <SportTitle>{t("volleyball")}</SportTitle>
                        <RouteAddFavouriteButton 
                        onClick={volleyballButtonClick}
                        >
                        <FiPlusCircle size={16}/>
                        {t("addx")}
                      </RouteAddFavouriteButton>
                    </SportTitleContainer>
                    <RemoveFavoritesCard 
                      width="315px"
                      fontSize="9px"
                      favouriteTeamsData={groupedTeams.Volleyball} 
                    />
                  </FavouriteSportCardContent>
                </div>
                ) : (
                  <RouteAddFavourite>
                    <SportTitle>{t("volleyball")}</SportTitle>
                    <img src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/98523bb1-26a9-494c-3be7-3c96d38fb800/original" alt=""/>
                    <RouteAddFavouriteButton
                    onClick={volleyballButtonClick}
                    >
                      <FiPlusCircle size={16}/>
                      {t("addx")}
                    </RouteAddFavouriteButton>
                  </RouteAddFavourite>
                )}
              </FavouriteSportCard>

            </FavouriteSportCardContainer>

           </FavouriteTeamsSection>
          </Container>
        ) : (
          <NotFound text={t("notFoundTeams")} />
        )}

      </FavouriteTeamsMain>
    </>
  );
};

export default FavouriteTeams;

export const FavouriteTeamsMain = styled.main`
  // background-color: #f8f8f8;
  padding-bottom: 80px;
`;

export const FavouriteTeamsBackground = styled.div`
  width: 100%;
`;

export const FavouriteTeamsDetailImg = styled.img`
  width: 100%;
  height: 230px;
`;

export const FavouriteTeamsDetailTitleBreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 4px;
  padding: 10px 30px;
  background-color: #033036;
`;

export const FavouriteTeamsDetailTitleContainer = styled.div`
  display: flex;
`;

export const FavouriteTeamsDetailIcon = styled.img`
  position: relative;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 15px;
  padding: 5px;
`;

export const FavouriteTeamsTitle = styled.div`
  position: relative;
  width: 190px;
  top: 15px;
  left: 25px;
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const FavouriteTeamsImg = styled.img`
  width: 100%;
  height: 230px;
`;

export const Container = styled.div`
  position: relative;
  max-width: 1100px;
  padding: 40px;
  margin: 40px auto;
  font-family: "Arial", sans-serif;
  border-radius: 5px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const FavouriteTeamsSection = styled.section``;
export const FavouriteSportCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
`;

export const FavouriteSportCard = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
`;

export const RouteAddFavourite = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  gap: 10px;
`;

export const RouteAddFavouriteButton = styled.button`
  display: flex;
  justify-conten: center;
  align-items: center;
  gap: 5px;
  color: #4CAF50;
  text-decoration: none;
  padding: 10px;
  border: 2px solid #4CAF50;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: #4CAF50;
  }
`;

export const SportTitle = styled.h2`
    position: relative;
    font-size: 24px;
    font-weight: 600;
    margin-top: 15px;
    text-align: start;
    padding-left: 5px;

    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background-color: #4CAF50;
      transition: width 0.3s ease-in-out;
    }

    &:hover::before {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
  }
`;

export const FavouriteSportCardContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  border-radius: 15px;
`;

export const SportTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;