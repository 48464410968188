import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CampaignDonorsThreeCard } from "../../components";
import { NotFound } from "../../pages";


const CampaignDonorsList = ({
  title,
  participants,
  logo,
  homeId,
  homeLogo,
  awayId,
  awayLogo
}) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  let noDonationMessage;
  if (title === "general") {
    noDonationMessage = t("noDonationYet");
  } else {
    noDonationMessage = `${title} ${t("noDonationForX")}`;
  }

  if (!participants || participants.length === 0) {
    return (
      <CampaignDonorsContainer>
      <CampaignDonorsTitle>{title}</CampaignDonorsTitle>
      <CampaignDonorsGrid className="campaign-donors-grid">
        <NotFoundsContainer>
          <NotFound  width="200px" text={t(noDonationMessage)} />
        </NotFoundsContainer>
      </CampaignDonorsGrid>

    </CampaignDonorsContainer>
    );
  }

  const visibleParticipants = showAll ? participants : participants.slice(0, 5);

  return (
    <CampaignDonorsContainer>
      <CampaignDonorsTitle>{title}</CampaignDonorsTitle>
      <CampaignDonorsGrid className="campaign-donors-grid">
        <DonorContainer>
          {visibleParticipants?.map((participant, index) => (
            <CampaignDonorsThreeCard
              key={participant.joinId}
              joinId={participant.joinId}
              username={participant.username}
              desc={title}
              firstAmount={participant.amount}
              logoFirst={logo}
              homeLogo={homeLogo}
              homeId={homeId}
              awayLogo={awayLogo}
              awayId={awayId}
              isOdd={index % 2 !== 0}
            />
          ))}
        </DonorContainer>
      </CampaignDonorsGrid>
      {participants.length > 5 && (
        <ShowMoreButton onClick={() => setShowAll(!showAll)}>
          {showAll ? t("showLess") : t("showMore")}
        </ShowMoreButton>
      )}
    </CampaignDonorsContainer>
  );
};


export default CampaignDonorsList;

export const CampaignDonorsContainer = styled.div`
  width: 100%;
  height: 480px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const CampaignDonorsTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 5px 10px;
  color: orange;
  border-bottom: 1px solid #eee;
`;

export const CampaignDonorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px; 
  height: 380px;
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
  background-color: white;
  padding: 0 10px;

  /* Width and height of the scrollbar track */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 15px;
  }

  /* Background color of the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Color of the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 55px;
  }

  /* Color of the scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
 
export const ShowMoreButton = styled.button`
  margin-top: 20px;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
`;
export const DonorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 5px;
  padding-top: 20px;
`;
export const NotFoundsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-top: 20px;
`;