import { fansupportPublic } from "../index";

export const getSlider = async ({ languageId, sportId, countryId, tournamentId, teamId, page, perPage }) => {
    return fansupportPublic.get('/slider', {params: {
        languageId,
        sportId, 
        countryId, 
        tournamentId, 
        teamId,
        page,
        perPage
    }})
}