import { fansupportPrivate } from "../index";

export const getCampaignsUserJoined = async ({ page, perPage }) => {
    try {
        const data = fansupportPrivate.get('/campaign/user-joined-campaigns', {params: {
            page,
            perPage,
        }})
        return data;
    } catch (error) {
        console.log("getCampaignsUserJoined",error)
    }

}