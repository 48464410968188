import { fansupportPublic } from "../index";

export const getNews = async (
    { 
        id, 
        title, 
        description, 
        heading, 
        body, 
        languageId, 
        slug, 
        publishDate,
        status,
        sportId,
        countryId,
        tournamentId,
        teamId,
        page, 
        perPage
    }) => {
    try {
        return fansupportPublic.get('/news',{ params: { 
            id, 
            title, 
            description, 
            heading, 
            body, 
            languageId,
            slug, 
            publishDate,
            status,
            sportId,
            countryId,
            tournamentId,
            teamId,            
            page,
            perPage
        }})   
    } catch (error) {
        console.log("getNews",error);
    }    
} 