import React, { useState, useEffect, useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import { rem, Skeleton } from '@mantine/core';
import styled from 'styled-components';
import { CampaignCompetitionCard } from "../../components";
import { getCampaign } from "../../services/campaign/campaignService";
import { NotFound } from '../../pages';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
 
const CampaignsCarousel = () => {
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  
  const [challangeCampaign, setChallangeCampaign] = useState([]);
  const [loadingEventCampaign, setLoadingEventCampaign] = useState(true);

  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedCountryId = useSelector(state => state.filter.countryId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedTeamId = useSelector((state) => state.filter.teamId);

  const { t } = useTranslation();


  const getChallangeCampaign = async () => {
    setLoadingEventCampaign(true);
    
    const sportId = selectedSportId;
    const countryId = selectedCountryId;
    const tournamentId = selectedTournamentId;
    const teamId = selectedTeamId;
    const campaignType = 1;
    const status = 1;
    const sort = "ASC";
    const page = 1;
    const perPage = 5;

    try {
      const resCampaign = await getCampaign({ sportId, countryId, tournamentId, teamId, campaignType, status, sort, page, perPage })
      setChallangeCampaign(resCampaign?.data?.data?.data || []);
      setLoadingEventCampaign(false);
    } catch (error) {
      console.error('Error fetching campaign data:', error);
      setLoadingEventCampaign(false);
    }
  };
  // console.log("first",challangeCampaign);
  
  useEffect(() => {
    getChallangeCampaign();
  },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId]) 
  
  const slides = challangeCampaign?.map((campaignData) => (
    <Carousel.Slide key={campaignData.id}>
      <CarouselSlideContainer>

        <CampaignsBox className="campaign-challange-carousel-card">
          <CampaignsBoxTitle>{campaignData.title}</CampaignsBoxTitle>

          <CampaignCompetitionCard 
            key={campaignData.id}
            campaignData={campaignData}
          />

        </CampaignsBox>
          
      </CarouselSlideContainer>
    </Carousel.Slide>
  ));

  const carouselPlugins = challangeCampaign.length >= 4 ? [autoplay.current] : [];

  return (
    <>
      {loadingEventCampaign ? (
          <SkeletonContainer>
            <Skeleton height={150} radius="md" animate />
          </SkeletonContainer>
        ) : (
        challangeCampaign && challangeCampaign.length > 0 ? (
        <CarouselContainer
          className='campaign-challange-carousel'
          mx="auto"
          withIndicators
          loop
          slideSize={[1, '33.333333%']}
          slideGap="xl"
          align="start"
          slidesToScroll={1}
          styles={{
            indicator: {
              width: rem(12),
              height: rem(6),
              backgroundColor:'grey',
              transition: 'width 250ms ease',
              '&[data-active]': {
                width: rem(40),
                backgroundColor:'#000000d9',
              },
            },
            control: {
              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default',
              },
            },
          }}
          plugins={carouselPlugins}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
        >
          {slides}
        </CarouselContainer>
      ) : (
        <NotFound text={t("notFoundCampaigns")} />
      )
      )}
      </>
  );
}

export default CampaignsCarousel

export const CarouselContainer = styled(Carousel)`
  border-radius: 5px;
  background-color: #FFFFF;
  padding: 40px 25px;
`;

export const CarouselSlideContainer = styled.div`

`; 

export const CampaignsBox = styled.div`
  max-width: 100%;
  width: 350px; 
  height: 150px;
  border-radius: 0;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 20px;
  background: #fff;
`; 
export const CampaignsBoxTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  max-width: 350px;
  width: 350px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 150px;
  padding: 20px;
`;