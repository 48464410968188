import React from "react";
import { AppFeatures, Card } from "../../components";
import { useTranslation } from "react-i18next";
import { Container, Flex, Grid } from '@mantine/core';
import { homeRail, homeRail2 } from "../../utils/config";
import styled from "styled-components";

const SupportType = () => {

    const { t } = useTranslation();

  return (
    <>
      <HomeMain className="about-us-main">
        <ConditionalSupportBanner
          id="support-conditions"
          className="double-sections"
        >
          <ConditionalSupportContainer>
            <CardTitle>{t("staticSupportTypes")}</CardTitle>

            <ConditionalSupportContent>
              <Grid justify="center" gutter="xl" mx={0}>
                {homeRail.map((data, index) => (
                  <Grid.Col md={6} lg={3}>
                    <Card
                      key={index}
                      img={data.img}
                      title={t(data.title)}
                      desc={t(data.desc)}
                      url={data.url}
                      imageWidth="50px"
                      imageHeight="50px"
                      backgroundColor="white"
                      textColor="black"
                      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                      className="conditional-support-card"
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </ConditionalSupportContent>
          </ConditionalSupportContainer>
        </ConditionalSupportBanner>
        <FeaturesBanner id="features" className="double-sections">
          <AppFeatures />
        </FeaturesBanner>
      </HomeMain>
    </>
  );
};

export default SupportType;

export const HomeMain = styled.main`
  padding: 100px 0;
`;

export const ConditionalSupportContainer = styled.div`

`;
export const ConditionalSupportContent = styled.div``;
export const ConditionalSupportBanner = styled.section``;

export const CardTitle = styled.div`
  color: orange;
  padding-bottom: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

export const FeaturesBanner = styled.section`
  // width: 1100px;
  margin: 50px auto;
`;
