import React from "react";
import styled from "styled-components";

const RegisterInput = ({
  id,
  type,
  name,
  value,
  onChange,
  label,
}) => {
  return (
    <InputContainer>
      <InputField
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder=""
      />
      <InputLabel htmlFor={id}>{label}</InputLabel>
    </InputContainer>
  );
};

export default RegisterInput;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const InputField = styled.input`
  height: 40px;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid #ced4da;
  background: var(--background-color);
  color: inherit;
  outline: none;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &:hover{
    border: 1px solid black;
    transition: border-color 0.2s ease, background-color 0.2s ease;
  }
  &:focus{
    border: 1px solid blue;
  }

  &:focus,
  &:valid {
    background-color: white;
  }
`;

export const InputLabel = styled.label`
  position: absolute;
  left: 10px;
  font-size: 12px;
  color: #999;
  pointer-events: none;
  background: transparent;
  transition: transform 0.2s ease-out, color 0.2s ease,
  background-color 0.2s ease;
  border-radius: 3px;

  ${InputField}:focus ~ &,
  ${InputField}:not(:placeholder-shown) ~ & {
    padding: 5px;
    transform: translateY(-23px) scale(1);
    color: rgb(60, 60, 60);
    background-color: white;
  }
`;
