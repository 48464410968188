import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const AllPlayersCard = () => {
  const [isActive, setIsActive] = useState("all");

  const {t} = useTranslation();


  return (
    <>
      <AllPlayersContainer>

      {/* <AllPlayersButtonContainer>
          {["all", "goal-keeper", "guard", "midfielder", "forward"].map(
            (playerType) => (
              <AllPlayersButton
                key={playerType}
                active={isActive === playerType}
                onClick={() => setIsActive(playerType)}
              >
                {t(playerType.charAt(0).toUpperCase() + playerType.slice(1))}
              </AllPlayersButton>
            )
          )}
        </AllPlayersButtonContainer> */}

        <AllPlayersButtonContainer>
          <AllPlayersButton
            active={isActive === "all"}
            onClick={() => setIsActive("all")}
          >
            {t("all")}
          </AllPlayersButton>

          <AllPlayersButton
            active={isActive === "goal-keeper"}
            onClick={() => setIsActive("goal-keeper")}
          >
            {t("goalKeeper")}
          </AllPlayersButton>

          <AllPlayersButton
            active={isActive === "guard"}
            onClick={() => setIsActive("guard")}
          >
            {t("defender")}
          </AllPlayersButton>

          <AllPlayersButton
            active={isActive === "midfielder"}
            onClick={() => setIsActive("midfielder")}
          >
            {t("midfielder")}
          </AllPlayersButton>

          <AllPlayersButton
            active={isActive === "forward"}
            onClick={() => setIsActive("forward")}
          >
            {t("forward")}
          </AllPlayersButton>
        </AllPlayersButtonContainer>


        {isActive === "all" && (
          <AllContainer>

            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>
            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>
            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>
            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>
            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>

          </AllContainer>
        )}
        {isActive === "goal-keeper" && (
          <AllContainer>
            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>
            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>
            <AllPlayersAvatarWrapper>
                <AllPlayersImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" />
                <AllPlayersDescContainer>
                  <AllPlayersDescNo>#7</AllPlayersDescNo>
                  <AllPlayersDescName>Henry Onyekuru</AllPlayersDescName>
                  <small>{t("forward")}</small>
                </AllPlayersDescContainer>
            </AllPlayersAvatarWrapper>
          </AllContainer>
        )}
        {isActive === "guard" && (
          <AllContainer>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
          </AllContainer>
        )}
        {isActive === "midfielder" && (
          <AllContainer>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
          </AllContainer>
        )}
        {isActive === "forward" && (
          <AllContainer>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
            <div>ercan yesil</div>
          </AllContainer>
        )}


      </AllPlayersContainer>
    </>
  );
};

export default AllPlayersCard;

export const AllPlayersContainer = styled.div`
  margin: 5px 0;
`;

export const AllPlayersButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

export const AllPlayersButton = styled.div`
  padding: 10px 20px;
  // background-color: ${({ active }) => (active ? "blue" : "transparent")};
  color: ${({ active }) => (active ? "black" : "black")};
  border-bottom: ${({ active }) => (active ? "3px solid #033036" : "none")};
  font-weight: ${({ active }) => (active ? "500" : "none")};
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;

export const AllContainer = styled.div`
  height: 420px;
  max-height: 420px;
  overflow: auto;
  overflow-x: hidden;
  background-color: white;

  /* Width and height of the scrollbar track */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 15px;
  }

  /* Background color of the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Color of the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  /* Color of the scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

`;

export const AllPlayersAvatarWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin: 30px;
`;

export const AllPlayersImage = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
`;

export const AllPlayersDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const AllPlayersDescNo = styled.div`
  font-size: 24px;
  font-weight: 700;
`;
export const AllPlayersDescName = styled.div`
  font-weight: 500;
`;
