import { Outlet } from "react-router-dom";
import { NavbarSubHome } from "../../components";
import styled from "styled-components";

const SportsLayout = () => {
  return (
    <>
    <NavbarContainerWrapper>
      <NavbarSubHome/>
    </NavbarContainerWrapper>

    <Outlet/>
    </>
  )
}

export default SportsLayout

export const NavbarContainerWrapper = styled.div`
`;