import "./App.css";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HelpLayout ,AboutUs, SportsLayout, Teams, Standings, Fixtures, Login, Register, Reset, AuthLayout, Account, TeamDetail, CampaignDetail, NotFound, ErrorComponent, FavouriteTeams, UserJoinedCampaigns, Help, Forgot, HomeSportDetail, HelpDetail, UserCertificates, SmartLink, PrivacyPolicy, OrganisationsDetail, HomePage, LandingPage, CampaignAllDetail, NewsFromUs, NewsFromUsDetail, NotFoundImage, ForTheGoals, SupportType, FansupportPlatform, Notifications  } from "./pages";
import { Footer, LanguageData, ScrollToTop } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { getRound } from "./services/roundService";
import RemoveTurkishChars from "./logic/RemoveTurkishChars";
import { getStartLanguage } from "./logic/getStartLanguage";
import { setRoundId, setStageId } from "./redux/features/filter/filterSlice";

export const ThemeContext = createContext(null);


function App() {

  const [theme, setTheme] = useState("light");
  const [activePage, setActivePage] = useState(false);
  const [stageRoundsData,setStageRoundsData] = useState();

  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedSeasonId = useSelector(state => state.filter.seasonId);

  const user  = useSelector((state) => state.user);
  
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const handleButtonClick = () => {
    setActivePage(true);
  };

  const fetchStageRounds = async () => {
    try {
      const sportId = selectedSportId;
      const tournamentId = selectedTournamentId;
      const seasonId = selectedSeasonId;
  
      const res = await getRound({ sportId, tournamentId, seasonId })

      const stages = res?.data?.data || [];

      const activeStage = stages.find((stage) => stage.active);
      const stageId = activeStage ? activeStage.id : stages[0]?.id;
      const stageIdName = activeStage ? activeStage.name : stages[0]?.name;
      if (stageId) {
        localStorage.setItem("stageIds",stageId);
        localStorage.setItem("stageIdsName",stageIdName);
        dispatch(setStageId(stageId));
      }
      if (stages.length < 1) {
        const activeRound = stages[0].rounds.find(round => round.active);
        const roundId = activeRound ? activeRound.id : stages[0].rounds[0]?.id;
        if (roundId) {
          localStorage.setItem("roundIds", roundId);
          dispatch(setRoundId(roundId));
        }
      }

      if (stages.length > 1) {

        {stages.map((list) => {
          console.log("stage",list?.rounds.find(round => round.active));
        })}

      }

      setStageRoundsData(res?.data?.data || {});

    } catch (error) {
      console.log("fetchStageRounds",error)
    }
  };


  useEffect(()=>{
    fetchStageRounds();
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  },[selectedSportId, selectedTournamentId, selectedSeasonId ])

  useEffect(() => {
    const langParam = window.location.pathname.split('/')[1];
    if (["tr", "en", "es"].includes(langParam)) { 
      i18n.changeLanguage(langParam);
      localStorage.setItem("tag",langParam);
      localStorage.setItem("languageLabelTag",langParam);
    } else {
      const startLanguage = getStartLanguage();
      i18n.changeLanguage(startLanguage);
      // navigate("/en");
    }
  }, []); 

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }} >

      <div className="App" id={theme}>

        <ErrorComponent />
      
        <BrowserRouter>
          <ScrollToTop />
          <Routes>

          <Route path={"/:lang"} element={user.user ? <HomePage /> : (activePage ? <HomePage /> : <LandingPage onButtonClick={handleButtonClick} />)} />

          <Route path={"/"} element={user.user ? <HomePage /> : (activePage ? <HomePage /> : <LandingPage onButtonClick={handleButtonClick} />)} />
            
              <Route path={`/:lang/:sportName/`} element={<SportsLayout/>} >
                <Route index={true} element={<HomeSportDetail />} />

                <Route path={RemoveTurkishChars({ text: t("teams") })} element={<Teams />} />
                <Route path={RemoveTurkishChars({ text: t("standings") })} element={<Standings />} />
                <Route path={RemoveTurkishChars({ text: t("fixture") })} element={<Fixtures stageRoundsData={stageRoundsData} />} />

                <Route path=":CountryName" element={<SportsLayout />} >
                  <Route index={true} element={<HomeSportDetail />} />
                  <Route path=":TournamentName" element={<SportsLayout />} >
                    <Route index={true} element={<HomeSportDetail />} />
                    <Route path=":TeamName" element={<SportsLayout />} >
                      <Route index={true} element={<HomeSportDetail />} />
                    </Route>
                  </Route>
                </Route>
                
                <Route path={`${RemoveTurkishChars({ text: t("team") })}/:teamName/:teamId`} element={<TeamDetail/>} />
                <Route path={`${RemoveTurkishChars({ text: t("competition") })}/:leagueType/:campaignTitle/:campaignId`} element={<CampaignDetail/>}/>               
                <Route path={`${RemoveTurkishChars({ text: t("championship") })}/:leagueType/:campaignTitle/:campaignId`} element={<CampaignDetail/>}/>
                <Route path={`${RemoveTurkishChars({ text: t("score") })}/:leagueType/:campaignTitle/:campaignId`} element={<CampaignDetail/>}/>
                <Route path={`${RemoveTurkishChars({ text: t("transfer") })}/:leagueType/:campaignTitle/:campaignId`} element={<CampaignDetail/>}/>
                
                <Route  path="*" element={<NotFound text={t("genericError")}/>}/>
                <Route  path="notfound" element={<NotFound text={t("genericError")}/>}/>
              </Route>

              <Route path={`/:lang/${RemoveTurkishChars({ text: t("social") })}`} element={<SportsLayout/>} >
                <Route index={true} element={<HomeSportDetail />} />
                <Route path=":socialName/:socialId" element={<OrganisationsDetail/>}/>
                <Route  path="*" element={<NotFound text={t("genericError")}/>}/>
                <Route  path="notfound" element={<NotFound text={t("genericError")}/>}/>
              </Route>
              
              <Route path={`/:lang/${RemoveTurkishChars({ text: t("campaigns") })}`} element={<SportsLayout/>} >
                <Route index={true} element={<CampaignAllDetail />} />
                <Route path=":campaignsName/" element={<CampaignAllDetail/>}/>
                <Route  path="*" element={<NotFoundImage/>}/>
                <Route  path="notfound" element={<NotFoundImage/>}/>
              </Route>
            

              <Route path="/help" >
                <Route path="about-us" element={<HelpLayout/>} >
                  <Route index={true} element={<AboutUs />} />                
                </Route>
                <Route path=":type" element={<SportsLayout/>} >
                  <Route index={true} element={<HelpDetail/>}/>
                </Route>
                <Route path="hakkımızda" element={<HelpLayout/>} >
                  <Route index={true} element={<AboutUs />} />  
                </Route>
                <Route path="nedir" element={<HelpLayout/>} >
                  <Route index={true} element={<AboutUs />} />  
                </Route>
              </Route>
              
              <Route path="/:lang/slider" >
                <Route path="football-for-the-goals" element={<SportsLayout/>} >
                  <Route index={true} element={<ForTheGoals />} />                
                </Route>
                <Route path="support-type" element={<SportsLayout/>} >
                  <Route index={true} element={<SupportType />}/>
                </Route>
                <Route path="fansupport-platform" element={<SportsLayout/>} >
                  <Route index={true} element={<FansupportPlatform />}/>
                </Route>
              </Route>
              
              <Route element={<AuthLayout/>}>
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("login") })}`} element={<Login />} />
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("register") })}`} element={<Register />} />
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("resetPassword") })}`} element={<Forgot />} />
                <Route path="/:lang/reset-password/:resetToken" element={<Reset/>} />
                <Route path="/reset-password/:resetToken" element={<Reset/>} />
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("accountSettings") })}`} element={<Account />} /> 
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("notifications") })}`} element={<Notifications />} /> 
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("favouriteTeams") })}`} element={<FavouriteTeams/>} />
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("myJoinedCampaigns") })}`} element={<UserJoinedCampaigns/>} />
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("myCertificates") })}`} element={<UserCertificates/>} />
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("newsFromUs") })}`} element={<NewsFromUs/>} />    
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("newsFromUs") })}/:newsfromusId`} element={<NewsFromUsDetail/>}/>          
                <Route path={`/:lang/${RemoveTurkishChars({ text: t("userScore") })}`} element={<UserJoinedCampaigns/>} />
              </Route>

              <Route path="/app" element={<SmartLink/>} />

              <Route path="/privacy-policy" element={<PrivacyPolicy/>} />

              <Route  path="*" element={<NotFound text={t("genericError")}/>}/>

            </Routes>

          <Footer toggle={toggleTheme} theme={theme} />

        </BrowserRouter>
        
      </div>

  </ThemeContext.Provider>
    
  );
}

export default App; 
