import React from "react";
import { Flex, Grid } from "@mantine/core";
import styled from "styled-components";
import { homeRail2 } from "../../utils/config";
import { useTranslation } from "react-i18next";
import { Card } from "../../components";

const FansupportPlatform = () => {
  const { t } = useTranslation();

  return (
    <>
      <HomeMain className="about-us-main">
        <WhatFsBanner id="what" className="single-sections">
          <WhatFsBannerContainer>
            <WhatFsBannerLeft>
              <FanSupportDownloadTitleFirst>
                {t("title")}
              </FanSupportDownloadTitleFirst>
              {/* <FanSupportDownloadTitleSecond>{t("staticLabel")}</FanSupportDownloadTitleSecond> */}
              <FanSupportDownloadDesc>{t("staticDesc")}</FanSupportDownloadDesc>

              <Flex
                mih={50}
                bg=""
                justify="center"
                align="center"
                dirction={{ base: "column", sm: "row" }}
                gap={{ base: "sm", sm: "lg" }}
                wrap="wrap"
              >
                <NavbarLink
                  href="https://apps.apple.com/tr/app/fansupport/id1569960194"
                  target="blank"
                >
                  <DownloadImage
                    src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/da757abe-bc5e-4285-7e65-05818cc2b100/original"
                    alt=""
                  />
                </NavbarLink>
                <NavbarLink href="https://play.google.com/store/apps/details?id=com.fansupport.fansupport_mobile">
                  <DownloadImage
                    src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/0b74d68a-0d36-4325-a9c2-13b2b9c1d700/original"
                    alt=""
                  />
                </NavbarLink>
                <NavbarLink href="https://appgallery.huawei.com/app/C104672889">
                  <DownloadImage
                    src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5a872b0c-e413-4f04-1384-84339f1d9a00/original"
                    alt=""
                  />
                </NavbarLink>
              </Flex>
            </WhatFsBannerLeft>

            <WhatFsBannerRight>
              <FsDownloadİmage
                src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/2bb5a834-fae2-4401-4f3f-655fdec0bd00/original"
                alt="mobile"
              />
            </WhatFsBannerRight>
          </WhatFsBannerContainer>
        </WhatFsBanner>

        <HowSupportBanner id="how" className="single-sections">
          <HowSupportContainer>
            <CardTitle>{t("whyTitle")}</CardTitle>

            <HowSupportBannerContent>
              <Grid justify="center" gutter="xl" mx={0}>
                {homeRail2.map((data, index) => (
                  <Grid.Col md={6} lg={3}>
                    <Card
                      key={index}
                      img={data.img}
                      title={t(data.title)}
                      desc={t(data.desc)}
                      url={data.url}
                      imageWidth="150px"
                      imageHeight="150px"
                      backgroundColor="none"
                      textColor="black"
                      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </HowSupportBannerContent>
          </HowSupportContainer>
        </HowSupportBanner>
      </HomeMain>
    </>
  );
};

export default FansupportPlatform;

export const HomeMain = styled.main``;

export const WhatFsBanner = styled.section`
  background-color: var(--bg-navbar);
  color: white;
`;
export const WhatFsBannerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: 0px auto;
  padding: 50px 0;
`;
export const WhatFsBannerLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const FanSupportDownloadTitleFirst = styled.h2`
  text-align: center;
  color: orange;
  font-size: 27px;
`;
export const FanSupportDownloadDesc = styled.p`
  font-size: 21px;
  text-align: center;
  margin: 5% 0;
  line-height: 1.8;
`;
export const NavbarLink = styled.a``;
export const DownloadImage = styled.img`
  width: 150px;
  height: auto;
`;
export const WhatFsBannerRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const FsDownloadİmage = styled.img`
  max-width: 450px;

  @media (max-width: 900px) {
    max-width: 350px;
  }
`;
export const HowSupportBanner = styled.section`
//   background-color: var(--bg-navbar);
`;
export const HowSupportContainer = styled.div`
  padding: 50px 0;
`;
export const CardTitle = styled.div`
  color: orange;
  padding-bottom: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;
export const HowSupportBannerContent = styled.div``;
