import { fansupportPrivate } from "../index";

export const getCampaignRecommended = async ({ sportId, countryId, tournamentId, teamId }) => {
    try {
        return fansupportPrivate.get('/campaign/recommended', {params: {
            sportId, 
            countryId,
            tournamentId,
            teamId,
        }})
    } catch (error) {
        console.log("getCampaignRecommended",error)
    }    
};