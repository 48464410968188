import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CurrencyData, CurrencyFormat, DateTimeFormatter, JoinButton, ShareButtons } from "../../components";
import fadedMedallion from "../../assets/certificates/faded_medallion.webp";
import { BsChevronRight } from "react-icons/bs";
import { getCampaignsUserJoinedList } from "../../services/campaign/campaignUserJoinedList";
import { getCertificate } from "../../services/document/certificateService";
import { useDisclosure } from "@mantine/hooks";
import { Loader, Modal } from "@mantine/core";
import { MdDownload } from "react-icons/md";
import { NotFound } from "../../pages";

const certificatesTypes = {
    0: "No",
    1: "SILVER",
    2: "GOLD",
    3: "PLATINUM",
};

const certificateImages = {
    No: fadedMedallion,
    SILVER: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/b7fd38a7-3fda-4bcd-71e3-2cd669375300/original",
    GOLD: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/12126cd9-dbbe-4b9e-376c-f9d2b18de000/original",
    PLATINUM: "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/cce3f264-7daf-4a89-0cf8-8d469bbc4f00/original",
};

const CampaignsUserCertificatesCard = () => {

    const [userJoinedData, setUserJoinedData] = useState([]);
    const [userJoinedCampaignId, setUserJoinedCampaignId] = useState();
    const [certificateData, setCertificateData] = useState([]);
    const [opened, { open, close }] = useDisclosure(false);
    const [downloadFormat, setDownloadFormat] = useState(0);
    const [downloadForm, setDownloadForm] = useState(1);
    const [loading, setLoading] = useState(false);

    const currencySymbol  = CurrencyData();
    const symbol = localStorage.getItem("selectedCurrency") || currencySymbol || '€';

    const { t } = useTranslation();
    const title = 'FanSupport Certificate'

    const storedLanguage = localStorage.getItem("selectedLanguage");

    const fetchCertificate = async (id) => {
        try {
          setLoading(true);
    
          const campaignId = id;
          const size = null;
          const format = null;
      
          const res = await getCertificate({ campaignId, size, format});
      
          setCertificateData(res?.data?.data || []);
          
        } catch (error) {
          console.log("certificates Error",error);
        } 
      }
    
      // console.log("certificeteData",certificateData);
    
      const imageUrl = `data:image/png;base64, ${certificateData}`;

    const fetchCampaignsUserJoinedList = async () => {
        try {
            const page = 1;
            const perPage = 25;

            const res = await getCampaignsUserJoinedList({ page, perPage });

            setUserJoinedData(res?.data?.data?.data || {});
        } catch (error) {
            console.log("fetchCampaignsUserJoinedList",error);
        }
    };
    // console.log("userJoinedData", userJoinedData);

    const handleCertificateClick = (id) => {
        setUserJoinedCampaignId(id)
        fetchCertificate(id);
        setLoading(false);
        open();
    };

    const handleDownload = async () => {
        if (downloadFormat !== 0 && downloadFormat !== 1 && downloadFormat !== 2) {
          console.error("Invalid download format");
        }
        if(downloadForm !== 0 && downloadForm !== 1 && downloadForm !== 2 && downloadForm !== 3 ){
            console.error("Invalid download size");
          }
    
        try {
          const campaignId = userJoinedCampaignId;
          const size = null;
          const format = downloadFormat;
    
          const res = await getCertificate({ campaignId, size, format });
    
          if (res?.data?.data) {
            const downloadLink = document.createElement("a");
            downloadLink.href = `data:image/${getFormatExtension(downloadFormat)};base64, ${res.data.data}`;
            downloadLink.download = `certificate.${getFormatExtension(downloadFormat)}`;
            downloadLink.click();
          } else {
            console.error("Error downloading certificate");
          }
        } catch (error) {
          console.error("Error downloading certificate", error);
        }
    };
    
    const getFormatExtension = (format) => {
        switch (format) {
          case 0:
            return "jpg";
          case 1:
            return "png";
          case 2:
            return "pdf";
          default:
            return "";
        }
    };
    

    useEffect(() => {
    fetchCampaignsUserJoinedList();
    }, []);

  return (
    <>
      {userJoinedData && userJoinedData.length === 0 ? (
        <NotFound text={t("noCertificateFound")} />
      ) :(
      userJoinedData.map((campaign) => (
      <Card key={campaign.id}>
        <div>
          <CardDescription className="campaigns-user-certificates-desc">{campaign.campaignTitle}</CardDescription>

          <CardContent>
            <CardContentLeft>
              {campaign.certificateType === 0 ? (
                <>
                  <CertificateFoundTitle>{campaign.description}</CertificateFoundTitle>
                  <CardContentLeftError>
                    <Certificateİmage
                      src={
                        certificateImages[
                          certificatesTypes[campaign.certificateType]
                        ]
                      }
                      alt=""
                    />
                    <NoCertificateText>
                      {t("noCertificateFound")}
                    </NoCertificateText>
                  </CardContentLeftError>
                </>
              ) : (
                <>
                  <CardContentLeftError>
                    <CertificateClick >
                      <Certificateİmage
                          onClick={() => handleCertificateClick(campaign.id)}
                          src={
                          certificateImages[
                              certificatesTypes[campaign.certificateType]
                          ]
                          }
                          alt=""
                      />
                      <ShareButtonsContainer>
                          <ShareButtons url={campaign.certificateUrl} title={title} />
                          <span>
                          {t(certificatesTypes[campaign.certificateType])}
                          </span>
                          <span> {t("certificate")}</span>
                      </ShareButtonsContainer>
                    </CertificateClick>

                  </CardContentLeftError>
                </>
              )}
            </CardContentLeft>

            <CardContentRight>
              <CardContentRightContainer>
                <CampaignsBoxAmountText>
                  {t("campaignAmount")}
                </CampaignsBoxAmountText>
                <CampaignsBoxAmount symbol={symbol}>
                  <CurrencyFormat amount={campaign.amount || 0}/>
                </CampaignsBoxAmount>
                {/* <CardType>
                  <span>{t("status")}: </span>
                  <span>{t(statusTypes[campaign.status])}</span>
                </CardType> */}
              </CardContentRightContainer>
            </CardContentRight>

          </CardContent>

          <CardBottom type={campaign.certificateType}>
              <DateTimeText>
                  <DateTimeFormatter apiTarih={new Date(campaign.certificateDate || "")} dil={storedLanguage} />
              </DateTimeText>
            <CertificateClick onClick={() => handleCertificateClick(campaign.id)}>
              <JoinButton
                width="35px"
                backgroundColor="transparent"
                textColor="black"
              >
                  <BsChevronRight size={20} />
              </JoinButton>
            </CertificateClick>

          </CardBottom>
        </div>
      </Card>
      ))
      )}

        <CampaignsUserJoinedModal>
            <Modal
            opened={opened}
            onClose={() => {
                close();
            }}
            title={t("myCertificates")}
            centered
            transitionProps={{ transition: "fade", duration: 200 }}
            overlayProps={{
                backgroundOpacity: 0,
                blur: 3,
            }}
            >
            <ModalContent>
                {loading ? <Loader />
                : <ModalCertificateImage src={imageUrl} alt=""/>}            
            </ModalContent>

            <ModalButtonContainer>

                <DropdownContainer>
                <DropdownSelect onChange={(e) => setDownloadForm(Number(e.target.value))}>
                    <DefaultOption>{t("chooseSize")}</DefaultOption>
                    <DropdownSelectOption value={0}>INSTAGRAM POST</DropdownSelectOption>
                    <DropdownSelectOption value={1}>INSTAGRAM STORY</DropdownSelectOption>
                    <DropdownSelectOption value={2}>A4 VERTICAL</DropdownSelectOption>
                    <DropdownSelectOption value={3}>A4 HORIZONTAL</DropdownSelectOption>
                </DropdownSelect>
                </DropdownContainer>

                <DropdownContainer>
                <DropdownSelect onChange={(e) => setDownloadFormat(Number(e.target.value))}>
                    <DefaultOption>{t("selectFormat")}</DefaultOption>
                    <DropdownSelectOption value={0}>JPG</DropdownSelectOption>
                    <DropdownSelectOption value={1}>PNG</DropdownSelectOption>
                    {/* <DropdownSelectOption value={2}>PDF</DropdownSelectOption> */}
                </DropdownSelect>
                </DropdownContainer>

                <ModalDownloadButton
                onClick={handleDownload}
                >
                <DownloadButtonContent>
                    <MdDownload size={16}/>
                    <div>{t("download")}</div>    
                </DownloadButtonContent>
            
                </ModalDownloadButton>
                
            </ModalButtonContainer>

            </Modal>
      </CampaignsUserJoinedModal>
    </>
  )
}

export default CampaignsUserCertificatesCard

export const Card = styled.div`
  max-width: 330px;
  width: 330px;
  margin: 0 auto;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 30px 30px 0 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const CardTitleBottom = styled.div``;

export const CardDescription = styled.h4`
  text-align: center;
  border-bottom: 2px solid black;
  padding-bottom: 15px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const CardContentLeft = styled.div``;

export const CardContentLeftError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Certificateİmage = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
`;

export const NoCertificateText = styled.div`
  font-size: 12px;
  text-align: center;
  max-width: 141px;
`;

export const CampaignsUserJoinedModal = styled.div`
  display: inline-block;
  position: relative;
`;
export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ModalCertificateImage = styled.img`
  width: auto;
  height: 530px;
`;
export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;
export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;
export const DropdownSelect = styled.select`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
`;
export const DefaultOption = styled.option`
  display: none;
  color: #999;
`;
export const DropdownSelectOption = styled.option`
  text-align: center;
  width: 20px;
`;
export const ModalDownloadButton = styled.button`
  background-color: #28a745;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  border: none;

  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;
export const DownloadButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  border-radius: 30px 0px 0px 150px;

  ${(props) =>
    props.type === 0 &&
    `
        background-color: white;
    `}

  ${(props) =>
    props.type === 1 &&
    `
        background-color: #C0C0C0;
    `}

    ${(props) =>
    props.type === 2 &&
    `
        background-color: #FFD700;
    `}
    ${(props) =>
    props.type === 3 &&
    `
        background-color: #E5E4E2;
    `}
`;

export const CardContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
`;

export const CardContentRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
`;

export const CampaignsBoxAmountText = styled.div`
  max-width: 135px;
  text-align: center;
`;

export const CampaignsBoxAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;

  ::after {
    content: "${(props) => props.symbol}";
    margin-left: 8px;
    color: #4caf50;
    font-size: 16px;
  }
`;
// export const CardType = styled.div``;

export const CertificateClick = styled.div`
    // cursor: pointer;
`;

export const DateTimeText = styled.small`
  color: #888;
`;
export const ShareButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
`;
export const CertificateFoundTitle = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 5px 0;
`;