import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStandings } from "../../../services/standingsService";
import { getTeams } from "../../../services/teamsService";
import { getTournament } from "../../../services/tournamentService";
// import { getFixture } from "../../../services/fixtureService";
import { getCountry } from "../../../services/countryService";
// import { getRound } from "../../../services/roundService";
import { getTournamentSeasons } from "../../../services/sport/tournamentSeasonsService";
import { getRss } from "../../../services/rss/rssService";
import LanguageTag from "../../../enums/LanguageTag";

const SPORT_ID_KEY = "selectedSportId";
const COUNTRY_ID_KEY = "selectedCountryId";
const TOURNAMENT_ID_KEY = "selectedTournamentId";
const TEAM_ID_KEY = "selectedTeamId";
const SEASON_ID_KEY = "selectedSeasonId";


export const getTeamsThunk = createAsyncThunk(
  "filter/getTeamsThunk",
  async ({sportId, countryId, tournamentId}) => {
    const res = await getTeams({sportId, countryId, tournamentId});

    return {
      data: res,
      sportId,
      countryId,
      tournamentId
    };
  }
);

export const getStandingsThunk = createAsyncThunk(
  "filter/getStandingsThunk",
  async ({sportId, countryId, tournamentId}) => {
    const res = await getStandings({sportId, countryId, tournamentId});

    return {
      data: res,
      sportId,
      countryId,
      tournamentId
    };
  }
);

// export const getFixtureThunk = createAsyncThunk(
//   "filter/getFixtureThunk",
//   async ({sportId, countryId, tournamentId, stageId, roundId}) => {
//     const res = await getFixture({sportId, countryId, tournamentId, stageId, roundId});

//     return {
//       data: res,
//       sportId, 
//       countryId, 
//       tournamentId,
//       stageId,
//       roundId
//     };
//   }
// )

// export const getRoundThunk = createAsyncThunk(
//   "filter/getRoundThunk",
//   async ({sportId, tournamentId}) => {
//     const res = await getRound({sportId, tournamentId});
//     return {sportId, tournamentId, data: res};
//   }
// )

export const getRssThunk = createAsyncThunk(
  "filter/getRssThunk",
  async ({
      languageId,
      sportId, 
      countryId,
      tournamentId, 
      page,
      perPage
    }) => {
    const res = await getRss({
      LanguageId: languageId,
      SportId: sportId,
      CountryId: countryId,
      TournamentId: tournamentId,  
      page,
      perPage
    });
    // console.log("getRssThunk",res?.data?.data);
    return {
      languageId,
      sportId, 
      countryId,
      tournamentId, 
      page,
      perPage,
      data: res};
  }
)

export const getCountryThunk = createAsyncThunk(
  "filter/getCountryThunk",
  async (sportId) => {
    const res = await getCountry({sportId});
    return {
      sportId,
      data: res
    };
  }
)

export const getTournamentThunk = createAsyncThunk(
  "filter/getTournamentThunk",
  async ({countryId, sportId}, { dispatch, getState}) => {
    const res = await getTournament({
      sportId,
      countryId,
    });
  const state = getState();
  const storedLanguageId = localStorage.getItem("languageId");
  const originalLanguage = navigator.language;
  const newLanguage = originalLanguage.split('-')[0];
  const language = Object.keys(LanguageTag).find(
    (key) => LanguageTag[key] === newLanguage
  );
  const languageId = storedLanguageId ? storedLanguageId : language;
  // let newTournamentId = '';
  // if(countryId === '6498b1e91d6341510544c3b0' && sportId === '6498b1e91d6341510544c3a7') { 
  //   newTournamentId = '64f0411f7bf55f571f120cb4'; 
  // } else {
  //    const newTournamentId = res.data.data.tournaments?.length ? res.data.data.tournaments[0].id : "";
  // }
  // console.log("newTournamentId", newTournamentId);
  // if(newTournamentId) {
    // dispatch(getRoundThunk({sportId, tournamentId: state.filter.tournamentId}));
    dispatch(getSeasonsThunk({tournamentId: state.filter.tournamentId}));
    dispatch(getTeamsThunk({sportId, countryId, tournamentId: state.filter.tournamentId}));
    dispatch(getRssThunk({sportId, countryId, tournamentId: state.filter.tournamentId, teamId: state.filter.teamId, page: state.filter.page, perPage: state.filter.perPage, languageId: languageId}));
    dispatch(getStandingsThunk({sportId, countryId, tournamentId: state.filter.tournamentId}));
    // dispatch(getFixtureThunk({sportId, countryId, tournamentId: state.filter.tournamentId, stageId: state.filter.stageId, roundId: state.filter.roundId, teamId: state.filter.teamId}));
  // }

    return {
      countryId,
      sportId,
      tournamentId: state.filter.tournamentId,
      data:res
    };
  }
);

export const getSeasonsThunk = createAsyncThunk(
  "filter/getSeasonsThunk",
  async ({tournamentId}) => {
    const res = await getTournamentSeasons({tournamentId});
    return {tournamentId, data: res};
  }
)



const filterSlice = createSlice({
  name: "filter",
  initialState: {
    sportId: localStorage.getItem(SPORT_ID_KEY) ? localStorage.getItem(SPORT_ID_KEY) : "",
    countryId: localStorage.getItem(COUNTRY_ID_KEY) ? localStorage.getItem(COUNTRY_ID_KEY) : "",
    tournamentId: localStorage.getItem(TOURNAMENT_ID_KEY) ? localStorage.getItem(TOURNAMENT_ID_KEY): "",
    selectedTournamentId: "64f0411e7bf55f571f120c46",
    teamId: localStorage.getItem(TEAM_ID_KEY) ? localStorage.getItem(TEAM_ID_KEY): "",
    seasonId: localStorage.getItem(SEASON_ID_KEY) ? localStorage.getItem(SEASON_ID_KEY): "",
    roundId: localStorage.getItem("roundIds") ? localStorage.getItem("roundIds") : "",
    stageId: localStorage.getItem("stageIds") ? localStorage.getItem("stageIds") : "",
    page: 1,
    perPage: 5,
    sportData: [],
    countryData: [],
    tournamentData: [],
    seasonsData: [],
    roundData: [],
    stagesRoundsData: [],
    filterRss: [],
    filterTeams: [],
    filterStandings: null,
    filterAllStandings: [],
    // filterFixture: null,
    filterTopList: [],
    loading: false,
    error: "",
    language: "tr",
    languageId: "",
    currency: "",
  },
  reducers: {
    setSportId: (state, action) => {
      state.sportId = action.payload;
    },
    setCountryId: (state, action) => {
      state.countryId = action.payload;
    },
    setTournamentId: (state, action) => {
      state.tournamentId = action.payload;
    },
    setTeamId: (state, action) => {
      state.teamId = action.payload;
    },
    setSeasonId: (state, action) => {
      state.seasonId = action.payload;
    },
    setRoundId: (state, action) => {
      state.roundId = action.payload;
    },
    setStageId: (state, action) => {
      state.stageId = action.payload;
    },
    setRoundData: (state, action) => {
      state.roundData = action.payload;
    },
    setStagesRoundsData: (state, action) => {
      state.stagesRoundsData = action.payload;
    },
    setSeasonsData: (state, action) => {
      state.seasonsData = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setLanguageId: (state, action) => {
      state.languageId = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    }
  },
  extraReducers: (builder) => {

    builder 
    
    // #region getRssThunk

    .addCase(getRssThunk.pending, (state, action) => {
      state.loading = true;  
    })
    
    .addCase(getRssThunk.fulfilled, (state, action) => {
      const {
        data,
        sportId,
        countryId,
        tournamentId,
        page,
        perPage
      } =  action.payload
      state.loading = false;
      state.filterRss = data?.data?.data?.data;
      state.sportId = sportId;
      state.countryId = countryId;
      state.tournamentId = tournamentId;
      state.page = page;
      state.perPage = perPage;
    })

    .addCase(getRssThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = "getRssThunk"
    })

    // #endregion


    // #region getTeamsThunk

    .addCase(getTeamsThunk.pending, (state, action) => {
      state.loading = true;  
    })
    
    .addCase(getTeamsThunk.fulfilled, (state, action) => {
      const {
        data,
        sportId,
        countryId,
        tournamentId
      } =  action.payload
      state.loading = false;
      state.filterTeams = data?.data?.data?.teams;
      state.sportId = sportId;
      state.countryId = countryId;
      state.tournamentId = tournamentId;

      state.selectedTournamentId = tournamentId;
    })

    .addCase(getTeamsThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = "getTeamsThunkError"
    })

    // #endregion

    // #region getStandingsThunk 

    .addCase(getStandingsThunk.pending, (state) => {
      state.loading = true;
    })
    
    .addCase(getStandingsThunk.fulfilled, (state, action) => {
      const {
        data,
        sportId,
        countryId,
        tournamentId
      } = action.payload;
      state.loading = false;
      state.filterAllStandings =  data?.data?.data;
      state.sportId = sportId;
      state.countryId = countryId;
      state.tournamentId = tournamentId;
      
    })

    .addCase(getStandingsThunk.rejected, (state) => {
      state.loading = false;
      state.error = "getStandingsThunkError"
    })

    // #endregion 

    // #region getFixtureThunk 

    // .addCase(getFixtureThunk.pending, (state) => {
    //   state.loading = true;
    // })

    // .addCase(getFixtureThunk.fulfilled, (state, action) => {
    //   const {sportId, countryId, tournamentId, stageId, roundId, data} = action.payload;
    //   state.loading = false;
    //   state.filterFixture = data?.data?.data;
    //   state.sportId = sportId;
    //   state.countryId = countryId;
    //   state.tournamentId = tournamentId;
    //   state.roundId = roundId;
    //   state.stageId = stageId;
    // })

    // .addCase(getFixtureThunk.rejected, (state) => {
    //   state.loading = false;
    //   state.error = "getFixtureThunkError"
    // })

    // #endregion
    









    /* Filter Data */

    // #region getCountryThunk 

    .addCase(getCountryThunk.pending, (state) => {
      state.loading = true;
    })

    .addCase(getCountryThunk.fulfilled, (state, action) => {
      state.loading = false;
      const { data, sportId } = action.payload;
      state.sportId = sportId
      state.countryData = data.data?.data?.countries
    })

    .addCase(getCountryThunk.rejected, (state) => {
      state.loading = false;
      state.error = "getCountryThunkError"
    })

    // #endregion 



    // #region getTournamentThunk 

    .addCase(getTournamentThunk.pending, (state) => {
      state.loading = true;
    })
    
    .addCase(getTournamentThunk.fulfilled, (state, action) => {
      const { data, countryId,sportId, tournamentId } = action.payload;
      state.loading = false;
      state.countryId = countryId;
      state.sportId = sportId;
      state.sportData =  data.data?.data?.sports
      state.tournamentId = tournamentId;
      state.tournamentData = data.data?.data?.tournaments
      if(!tournamentId) {
        state.filterTeams = [];
      }
    })

    .addCase(getTournamentThunk.rejected, (state) => {
      state.loading = false;
      state.error = "getTournamentThunkError"
    })

    // #endregion


    // #region getSeasonsThunk 

    .addCase(getSeasonsThunk.pending, (state) => {
      state.loading = true;
    })
    
    .addCase(getSeasonsThunk.fulfilled, (state, action) => {
      const {tournamentId, data} = action.payload;
      state.loading = false;
      state.tournamentId = tournamentId;
      state.seasonsData = data.data?.data;
    })

    .addCase(getSeasonsThunk.rejected, (state) => {
      state.loading = false;
      state.error = "getSeasonsThunkError"
    })

    // #endregion

    // #region getRoundThunk 

    // .addCase(getRoundThunk.pending, (state) => {
    //   state.loading = true;
    // })
    
    // .addCase(getRoundThunk.fulfilled, (state, action) => {
    //   const {sportId, tournamentId, data} = action.payload;
    //   state.loading = false;
    //   state.sportId = sportId;
    //   state.tournamentId = tournamentId;
    //   state.roundData = data.data?.data ? data.data?.data[0].rounds : null;
    //   state.stagesRoundsData = data.data?.data ? data.data?.data : null;

    //     // state.stageId = data.data?.data[0].id;

    //     // // rounds içinde active: true olanın id'sini bulma
    //     // const activeRound = data.data?.data[0].rounds.find(round => round.active === true);        
    //     // state.roundId = activeRound ? activeRound.id : null;

    //     // localStorage.setItem("activeRoundId",state.roundId);
    //     // localStorage.setItem("activeStageId",state.stageId); 
    //     // console.log("rec",state.stageId);
    // })

    // .addCase(getRoundThunk.rejected, (state) => {
    //   state.loading = false;
    //   state.error = "getRoundThunkError"
    // })

    // #endregion

  },

});

export const {
  setSportId,
  setCountryId,
  setTournamentId,
  setTeamId,
  setSeasonId,
  setSeasonsData,
  setRoundId,
  setStageId,
  setSportData,
  setRoundData,
  setStagesRoundsData,
  setLanguage,
  setLanguageId,
  setCurrency,

} = filterSlice.actions;

export default filterSlice.reducer;
