import React, { useState ,useRef } from 'react';
import { Link } from "react-router-dom";
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import { Image, rem } from '@mantine/core';
import styled from 'styled-components';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { useTranslation } from "react-i18next";


const RssCarousel = ({data}) => {
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedLink, setSelectedLink] = useState(null);

  const { t } = useTranslation();


  const handleLinkClick = (link) => {
    setSelectedLink(link);
    open();
  };

  const slides = Array.isArray(data)
  ? data.map(({ imageUrl, title, link }) => (
      <Carousel.Slide key={link}>
        <CarouselSlideContainer>
          <CarouselLink onClick={() => handleLinkClick(link)}>              
            <CarouselImage src={imageUrl} />
            <CarouselDesc className='rss-carousel-desc'>{title}</CarouselDesc>
          </CarouselLink>                  
        </CarouselSlideContainer>
      </Carousel.Slide>
    ))
  : null;


  return (
    <>
      <CarouselTitleWrapper>
        <CarouselTitleLeft>{t("news")}</CarouselTitleLeft>
        {/* <CarouselTitleRight>Tümünü Gör</CarouselTitleRight> */}
      </CarouselTitleWrapper>
      <CarouselContainer
        mx="auto"
        withIndicators
        loop
        // slideSize={{ base: '100%', sm: '50%', md: '33.333333%' }}
        slideSize="33.333333%"
        slideGap="xl"
        justify-content='space-around'
        align="center"
        slidesToScroll={1}
        styles={{
          indicator: {
            width: rem(10),
            height: rem(12),
            backgroundColor: '#d6b9b9',
            transition: 'width 250ms ease', 

            '&[data-active]': {
              width: rem(40),
              backgroundColor: '#000000d9',
            },
          },
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
      >
        {slides}
      </CarouselContainer>

      <Modal opened={opened} onClose={close} title={t("news")} fullScreen>
          {selectedLink && <IframeContent src={selectedLink} title="content" width="100%"/>}
      </Modal>
    </>
  );
}

export default RssCarousel

export const CarouselSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space=between; 
  align-items: center;  
`; 

export const CarouselContainer = styled(Carousel)`
  border-radius: 5px;
  padding: 0 0 50px 0;
`;

export const CarouselTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 25px;
`;
export const CarouselTitleLeft = styled.p``;
export const CarouselTitleRight = styled.button`
  background-color: transparent;
  border: 0;
`;

export const CarouselImage = styled(Image)`
  width: 400px;
  height: 300px;

  img {
    border-radius: 15px;
  }

  @media (max-width: 720px) {

    img {
      width: 100%;
      height: auto;
    }

  }
`;

export const CarouselDesc = styled.div`
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  max-width: 490px;
  padding: 0 15px;

  // @media (max-width: 720px) {
  //   display: none;
  // }
`;

export const CarouselLink = styled(Link)`
  color: black;
  text-decoration: none;
`;

const IframeContent = styled.iframe`
  height: 100vh;
  overflow-y: auto;
`;