import { fansupportPrivate } from "../index";

export const getPollUserAnswers = async ({page, perPage}) => {
    try {
        return fansupportPrivate.get('/poll/user-answers',{ params: { 
            page,
            perPage 
        }})
    } catch (error) {
        console.log("getPollUSerAnswers",error)
    }
} 