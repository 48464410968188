import React from "react";
import styled, { keyframes } from "styled-components";
import background from "../../assets/background.webp";
import { useTranslation } from "react-i18next";

const SmartLink = () => {

  const { t } = useTranslation();
    
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleContinueClick = () => {
    let storeLink = "";

    if (isAndroid) {
      storeLink =
        "https://play.google.com/store/apps/details?id=com.fansupport.fansupport_mobile";
    } else if (isIOS) {
      storeLink = "https://apps.apple.com/tr/app/fansupport/id1569960194";
    } else {
      storeLink = 'https://www.fansupport.com/';
    }

    window.location.href = storeLink;
  };

  return (
    <Container>
      <Content>  
        <Logo src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6ef27b40-e393-4ae6-8c5f-0d1442da7100/original" alt="Logo" />
        <Description>{t("shareApp")}</Description>
        <AnimatedButton onClick={handleContinueClick}>{t("cContinue")}</AnimatedButton>
      </Content>
    </Container>
  );
};

export default SmartLink;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  background: url(${background});
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const Description = styled.p`
  font-size: 1.2em;
  margin-bottom: 24px;
  padding: 0 30px;
  text-align: center;
`;

const Logo = styled.img`
  width: 400px;
  height: auto;
`;

const AnimatedButton = styled.button`
  padding: 12px 24px;
  font-size: 1.2em;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  animation: ${fadeIn} 1s ease;

  &:hover {
    background-color: #45a049;
  }
`;
