import { Outlet } from "react-router-dom";
import { NavbarAboutUs } from "../../components";
import styled from "styled-components";

const HelpLayout = () => {
  return (
    <>
    <NavbarContainerWrapper>
      <NavbarAboutUs/>
    </NavbarContainerWrapper>
    <Outlet/>
    </>
  )
}

export default HelpLayout

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;