import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import { RegisterInput } from "../../components";
import { getForgotPassword } from '../../services/identity/forgotPasswordService';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RemoveTurkishChars from '../../logic/RemoveTurkishChars';
import { getStartLanguage } from '../../logic/getStartLanguage';

const Forgot = () => {

  const [forgotMessage, setForgotMessage] = useState([]);
  const [email, setEmail] = useState('');

  const { t } = useTranslation();
  const startLanguage = getStartLanguage();

  const fetchForgotPassword = async (forgot) => {
    try {
      toast.dismiss();
      const res = await getForgotPassword(forgot);
      setForgotMessage(res?.data?.message);

      if (res?.data?.data === false) {
        toast.error(t("enterValidEmail"));
      } else if (res?.data?.data) {
        toast.success(t("resetPasswordSuccessInfo"));
      }

      
      // console.log("Forgot",res?.data?.data);  
      // console.log("ForgotError",res?.data);  
    } catch (error) {
      console.error("Failed to fetch forgot password:", error);
    }
  };


  const handleforgot = () => {
    fetchForgotPassword(email);
  };

  useEffect(()=> {
    fetchForgotPassword();
    
  },[])

  return (
    <>
      <ToastContainer/>
      <AuthContainer>
        <ImgContainer>
          <ImgLogin src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/5ceec3f7-8c94-4cda-c7ee-0cd391881c00/original" alt="Login" width="400" />
        </ImgContainer>
        <Card>
        <AuthContainerForm>
            <LoginTitle>{t("resetPassword")}</LoginTitle>
            <LoginForm>
              <RegisterInput
                name="email"
                type="text"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label={t("enterValidEmail")}
              />
              {forgotMessage}
              <ResetButton 
                type="button" 
                onClick={handleforgot}
              >
              {t("resetPassword")}
              </ResetButton>
              <ResetContent>
                <p>
                  <LoginLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`}>- {t("login")}</LoginLink>
                </p>
                <p>
                  <RegisterLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("register") })}`}>- {t("register")}</RegisterLink>
                </p>
              </ResetContent>
            </LoginForm>
        </AuthContainerForm>
        </Card>
      </AuthContainer>
    </>
  )
}

export default Forgot

export const AuthContainer = styled.section`
  width: 100%;
  padding: 4rem 0;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @keyframes slide-up {
    0% {
      transform: translateY(-5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slide-down {
    0% {
      transform: translateY(5rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 700px) {
    .img {
      display: none;
    }
  }
`;
export const ImgContainer = styled.div`
  animation: slide-down 1.5s ease;
  margin-right: 30px;
`;
export const ImgLogin = styled.img`

`;
export const Card = styled.div`
  width: 400px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;;
  overflow: hidden;
`;
export const AuthContainerForm = styled.div`
  padding: 1.5rem;
  animation: slide-up 1.5s ease;
`;
export const LoginTitle = styled.div`
  margin: 0px;
  font-size: 24px;
  color: black;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;
export const LoginForm = styled.form`
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    padding: 10px;
    margin: 10px 0;
    width: 94%;
    border: 1px solid rgb(119, 119, 119);
    border-radius: 5px;
    outline: none;
    height: 20px;
  }
`;
export const ResetContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;
export const RegisterLink = styled(Link)`
  font-weight: 500;
  color: black;
  text-decoration: none;
  margin-left: 5px;
`;
export const LoginLink = styled(Link)`
  font-weight: 500;
  color: black;
  text-decoration: none;
  margin-left: 5px;
`;
export const ResetButton = styled.button`
  width: 100%;
  color: #fff;
  // background: #007bff;
  background: linear-gradient(to right, #1b8a2f 0, #31ac47 100%);
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  height: 40px;

  &:hover {
    background: linear-gradient(to right, #1b8a2f 0, green 100%);
  }
`;