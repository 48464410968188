import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { getDocument } from '../../services/document/documentService';

const DisplayHtmlContent = ({ htmlContent }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const pTags = contentRef.current.querySelectorAll('p');
      const spanTags = contentRef.current.querySelectorAll('span');
      const tdTags = contentRef.current.querySelectorAll('td');
      pTags.forEach(p => p.classList.add('help'));
      spanTags.forEach(span => span.classList.add('help'));
      tdTags.forEach(td => td.classList.add('helpTd'));
    }
  }, [htmlContent]);

  return (
    <div
      ref={contentRef}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

const HelpDetail = () => {
  const [documentData, setDocumentData] = useState('');

  const { type } = useParams();

  const fetchDocument = async () => {
    try {
      const res = await getDocument({ type });
      setDocumentData(res?.data?.data?.textContent || '');
    } catch (error) {
      console.log("An error occurred while fetching documents", error);
    }
  };

  useEffect(() => {
    fetchDocument();
  }, [type]);

  return (
    <DocumentMain>
      <DocumentMainMessage>
        <DisplayHtmlContent htmlContent={documentData} />
      </DocumentMainMessage>
    </DocumentMain>
  );
};

export default HelpDetail;

export const DocumentMain = styled.main`
  max-width: 1100px;
  margin: 30px auto;
  padding: 50px 0px;
`;

export const DocumentMainMessage = styled.div`
`;
