import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CurrencyData = () => {

  const selectedCurrency = useSelector((state) => state.filter.currency);
  const { t } = useTranslation();

 
  const currencyData = [
    { code: "TRY", label: t("currencyTR"), number: "949", symbols: "₺" },
    { code: "USD", label: t("currencyUS"), number: "840", symbols: "$" },
    { code: "EUR", label: t("currencyEUR"), number: "978", symbols: "€" },
  ];

  const selectedCurrencyData = currencyData.find((cur) => cur.number === selectedCurrency);
  const currencySymbol = selectedCurrencyData ? selectedCurrencyData.symbols : "";

  return currencySymbol;
};

export default CurrencyData;
