import React, { useState, useEffect, useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import { rem } from '@mantine/core';
import styled from 'styled-components';
import { CampaignCompetitionCard } from "../../components";
import { NotFound } from '../../pages';
import { getCampaignRecommended } from '../../services/campaign/campaignRecommendedService';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const RecommendedCarousel = () => {
    const autoplay = useRef(Autoplay({ delay: 2000 }));
  
    
    const [recommendedCampaign, setRecommendedCampaign] = useState([]);

    const selectedSportId = useSelector(state => state.filter.sportId);
    const selectedCountryId = useSelector(state => state.filter.countryId);
    const selectedTournamentId = useSelector(state => state.filter.tournamentId);
    const selectedTeamId = useSelector((state) => state.filter.teamId);

    const { t } = useTranslation();
  
  
    const fetchRecommendedCampaign = async () => {
      try {

        const sportId = selectedSportId;
        const countryId = selectedCountryId;
        const tournamentId = selectedTournamentId;
        const teamId = selectedTeamId;

        const resCampaign = await getCampaignRecommended({ sportId, countryId, tournamentId, teamId });

        if (Array.isArray(resCampaign?.data?.data)) {
          setRecommendedCampaign(resCampaign.data.data);
        } else {
          setRecommendedCampaign([]);
        }
      } catch (error) {
        console.error("Error fetching recommended campaigns:", error);
        setRecommendedCampaign([]);
      }
    };
    
    
    useEffect(() => {
        fetchRecommendedCampaign();
    },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId]) 
    
    const slides = recommendedCampaign
    ? recommendedCampaign.map((campaignData) => (
        <Carousel.Slide key={campaignData.id}>
          <CarouselSlideContainer>
            <CampaignsBox className='recommended-carousel-campaigns-box'> 
              <CampaignsBoxTitle>{campaignData.title}</CampaignsBoxTitle>
              <CampaignCompetitionCard 
                key={campaignData.id}
                campaignData={campaignData}
              />
            </CampaignsBox>
          </CarouselSlideContainer>
        </Carousel.Slide>
      ))
    : null;

  
    return (
      <>
        {recommendedCampaign && recommendedCampaign.length > 0 ? (
          <CarouselContainer
            className="recommended-carousel-container"
            mx="auto"
            withIndicators
            loop
            slideSize={{ base: '100%', sm: '50%', md: '33.333333%' }}
            slideGap={{ base: 0, sm: 'md' }}
            align="start"
            
            styles={{
              indicator: {
                width: rem(12),
                height: rem(6),
                backgroundColor:'grey',
                transition: 'width 250ms ease',
                '&[data-active]': {
                  width: rem(40),
                  backgroundColor:'#000000d9',
                },
              },
              control: {
                '&[data-inactive]': {
                  opacity: 0,
                  cursor: 'default',
                },
              },
            }}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            {slides}
          </CarouselContainer>
        ) : (
          <NotFound text={t("notFoundCampaigns")} />
        )}
        </>
    );
  }
  
  export default RecommendedCarousel
  
  export const CarouselContainer = styled(Carousel)`
    border-radius: 5px;
    background-color: #eee;
    padding: 40px 0;

    @media (min-width: 100px) and (max-width: 900px) {
      width: 75%;
    }
    @media screen and (min-width: 900px) and (max-width: 1105px) {
      width: 50%;
    }
    @media screen and (min-width: 1105px) and (max-width: 1250px) {
      width: 60%;
    }
    @media screen and (min-width: 1250px) and (max-width: 1506px) {
      width: 75%;
    }
    @media screen and (min-width: 1506px) and (max-width: 1516px) {
      width: 93%;
    }
  `;
  
  export const CarouselSlideContainer = styled.div`
  
  `; 
  
  export const CampaignsBox = styled.div`
    max-width: 100%;
    width: 300px; 
    height: 150px;
    border-radius: 0;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 20px;
    background: #fff;
  `; 
  export const CampaignsBoxTitle = styled.div`
    font-size: 15px;
    margin-bottom: 20px;
    max-width: 350px;
    // width: 350px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
  `;