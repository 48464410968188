import { fansupportPublic } from "./index";

export const getFixture = async ({sportId, countryId, tournamentId, seasonId, teamId, stageId, roundId}) => {    
    return fansupportPublic.get('/sport/fixtures', { params: {
        sportId,
        countryId,
        tournamentId,
        seasonId,
        teamId,
        stageId,
        roundId,
    }})
}