import { fansupportPrivate } from "../index";

export const postUserPicture = async (picture) => {
    try {
        const data = await fansupportPrivate.post('identity/set-user-picture-base64',{
            picture: picture,
    })
        return data;        
    } catch (error) {
        console.log("postUserPicture Error:", error)
    }
};