import React from "react";
import styled from 'styled-components';
import { Breadcrumbs } from '../../components';
import image from "../../assets/3-slider.webp"
import { useTranslation } from "react-i18next";

const ForTheGoals = () => {

  const { t } = useTranslation();

  return (
    <>
      <DetailContainerMain className="home-main">
        <CampaignDetailBackground>
          <CampaignDetailImg src={image} alt=""/>

          <CampaignDetailTitleBreadcrumbsContainer>
            <CampaignDetailTitleContainer>
              <CampaignDetailTitle>
              Football For The Goals
              </CampaignDetailTitle>
            </CampaignDetailTitleContainer>

            <Breadcrumbs />
          </CampaignDetailTitleBreadcrumbsContainer>
        </CampaignDetailBackground>

        <DetailContainer className="home-main">
          <DetailWrapper className="campaign-card">
            <Title>
              {t("forTheGoalsTitle")}</Title>
            <Description className="news-from-us-detail">
              {t("forTheGoalsDesc")}
            </Description>
            
            <Section className="news-from-us-detail">
              <SubTitle>{t("detailedInformation")}</SubTitle>
              <Paragraph>
                <Link href="https://sdgs.un.org/goals" target="_blank" rel="noopener noreferrer">THE 17 GOALS | Sustainable Development</Link>
              </Paragraph>
              <Paragraph>
                <Link href="https://www.un.org/en/footballforthegoals" target="_blank" rel="noopener noreferrer">Football for the Goals | United Nations</Link>
              </Paragraph>
            </Section>

          </DetailWrapper>
        </DetailContainer>
        
      </DetailContainerMain>
    </>
  );
};

export default ForTheGoals;

export const DetailContainerMain = styled.main`
    background-color: #F8F9FA;
`;

export const DetailContainer = styled.div`
    margin: 30px 0;
    background-color: #fff;
`;
export const DetailWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 20px;
    padding-bottom: 30px;
    border-radius: 8px;
    border: 1px solid rgb(219, 224, 229); 
    &:hover{
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
       box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`;

export const Title = styled.h1`
    font-size: 24px;
    margin-bottom: 10px;
`;


export const Description = styled.p`
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    font-size: 18px;
    line-height: 1.6;
    color: #555;
`;


export const CampaignDetailBackground = styled.div`
  width: 100%;
`;

export const CampaignDetailImg = styled.img`
  width: 100%;
  height: auto;
`;

export const CampaignDetailTitleBreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 4px;
  padding: 20px 30px;
  background-color: #033036;
`;

export const CampaignDetailTitleContainer = styled.div`
  display: flex;
`;


export const CampaignDetailTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const Section = styled.section`
  margin-bottom: 20px;
`;

export const SubTitle = styled.h6`
  font-size: 15px;
  color: black;
  margin-bottom: 10px;
  font-weight: 500;
`;

export const Paragraph = styled.div`
  font-size: 16px;
  color: rgb(127, 140, 141);
  line-height: 1.6;
`;

export const Link = styled.a`
  color: #3498db;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
