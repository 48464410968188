import { fansupportPrivate } from "../index";

export const getAddFavoriteTeam = async ({ teamId, tournamentId }) => {
    try {
        const data = fansupportPrivate.post('/sport/add-favorite-team', {
            teamId,
            tournamentId,
        })
        return data;
        
    } catch (error) {
        console.log("getAddFavoriteTeam",error)
    }
}