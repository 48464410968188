import React from "react";
import styled from "styled-components";

const Card = (props) => {
  const { img, imageWidth, imageHeight, title, desc, backgroundColor, textColor, boxShadow, className  } = props;
  return (
    <>
      <CardBox 
        backgroundColor={backgroundColor} 
        textColor={textColor} 
        boxShadow={boxShadow}
        className={className}
      >
        <CardImageBar>
          <CardImage src={img} imageWidth={imageWidth} imageHeight={imageHeight}/>
        </CardImageBar>
        <CardTitle>{title}</CardTitle>
        <CardDesc>{desc}</CardDesc>
      </CardBox>
    </>
  );
};

export default Card;

export const CardBox = styled.div`
  height: 300px;
  background-color: ${props => props.backgroundColor || 'white'};
  color: ${props => props.textColor || 'black'};
  box-shadow: ${props => props.boxShadow || 'none'};
  padding: 15px;
  border-radius: 15px;
`;

export const CardTitle = styled.h4`
  text-align: center;
`;

export const CardImageBar = styled.div`
  text-align: center; 
`;
export const CardImage = styled.img`
  width: ${props => props.imageWidth || '50px'};
  height: ${props => props.imageHeight || '50px'};
`;

export const CardDesc = styled.p`
  text-align: center;
`;
