import React,{ useState, useEffect } from 'react';
import styled from 'styled-components';
import { AddFavoritesCard, Filterr, Breadcrumbs, AdImageCard } from '../../components';
import LanguageTag from '../../enums/LanguageTag';
import { getAds } from '../../services/ads/adsService';
import NotFound from '../not-found/NotFound';
import { useSelector } from 'react-redux';
import AdsLocation from '../../enums/AdsLocation';

const Teams = () => {

  const [adsData, setAdsData] = useState([]);
  const [localCountryId, setLocalCountryId] = useState(localStorage.getItem("startCountryId"))


  const selectedCountryId = useSelector(state => state.filter.countryId);



  const fetchAds = async () => {
    try {
      const storedLanguageId = localStorage.getItem("languageId");
      const originalLanguage = navigator.language;
      const newLanguage = originalLanguage.split('-')[0];

      const language = Object.keys(LanguageTag).find(
        (key) => LanguageTag[key] === newLanguage
      );
      
      const languageId = storedLanguageId ? storedLanguageId : language;

      const countryId = selectedCountryId === "" ? localCountryId : selectedCountryId;
      const platformType = 1;
      const location = AdsLocation['WEB_LEFT'];
  
      const res = await getAds({ countryId, languageId, platformType, location })
  
      setAdsData(res?.data?.data || {});
    } catch (error) {
      console.log("Ads Error:",error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAds()
  }, []);

  return (
    <>
      {adsData ? 
        <>
          <AdImageCard adsData={adsData} scrollValue={250} right="0" />
          <AdImageCard adsData={adsData} scrollValue={250} left="0" />
        </> : <NotFound />
      }  
      <Filterr useNavigateProp={true} showTeamSelect={false} />
      <TeamsContainer>
        <BreadcrumbsWrapper>
          <Breadcrumbs color="white"/>    
        </BreadcrumbsWrapper>

        <AddFavoritesCard />
      </TeamsContainer>
      <div style={{paddingTop: '618px'}} id='media-content'></div>
    </>
  )
}

export default Teams

export const BreadcrumbsWrapper = styled.div`
  margin: 10px 0 70px 5px;
`;

export const TeamsContainer = styled.div`
  max-width: 960px;
  margin: 60px auto 0 auto;
  padding: 15px;

  @media screen and (min-width: 1100px) and (max-width: 1412px) {
    width: 60%;
  }
  @media screen and (min-width: 900px) and (max-width: 1100px) {
    width: 50%;
  }
`;
