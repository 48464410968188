import React from "react";
import styled from "styled-components";
import notFoundIcon from "../../assets/search.svg"

const NotFound = ({ text, width, margin }) => {

  return (
    <NotFoundContainer width={width} margin={margin} className="not-found">
      <NotFoundIcon src={notFoundIcon} alt="Not Found" />
      <NotFoundText>{text}</NotFoundText>
    </NotFoundContainer>
  );
};

export default NotFound;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin: ${({ margin }) => margin ? margin : "20px auto"};
  width: ${({ width }) => width ? width : "auto"};
  max-width: 300px;
  max-height: 150px;
`;

export const NotFoundIcon = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
`;

export const NotFoundText = styled.p`
  font-size: 14px;
  color: #888;
  text-align: center;
`;
