import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageButton, SigninSignup, User } from "../../components";
import { FaChevronRight } from "react-icons/fa";
import { IoMdClose, IoMdPlayCircle } from "react-icons/io";
import { HiOutlineDownload } from "react-icons/hi";
import { BsChevronRight } from "react-icons/bs"; 
import { GrCertificate } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { getUserPicture } from "../../services/identity/getUserPictureService";
import { getUserPoint } from "../../services/payment/userPointsService";

const NavbarLandingPage = ({ onButtonClick }) => {

  const [extendNavbar, setExtendNavbar] = useState(false);
  const [userPictureData,setUserPictureData] = useState();
  const [userPointData, setUserPointData] = useState([]);

  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const languageCode = pathSegments[1];

  const { i18n, t } = useTranslation();

  if (i18n.language !== languageCode) {
    i18n.changeLanguage(languageCode);
  };

  const iconStyle = {
    color: "#c0c0c0",
    marginLeft: "10px",
  };

  const fetchUserPicture = async () => {
    try {
      const res = await getUserPicture();
      setUserPictureData(res?.data?.data);
      
    } catch (error) {
      console.log("UserPicture: ",error);
    }
  };

  const fetchUserPoint = async () => {
    try {

      const res = await getUserPoint();
  
      setUserPointData(res?.data?.data || []);
    } catch (error) {
      console.log("Ads Error:",error);
    }
  };

  const menuItems = [
    {
      label: t("homePage"),
      link: "/",
    },
    {
      label: t("whatIsIt"),
      link: "#what",
    },
    {
      label: t("supportConditions"),
      link: "#support-conditions",
    },
    {
      label: t("how"),
      link: "#how",
    },
    {
      label: t("features"),
      link: "#features",
    },
    {
      label: t("download"),
      link: "#download",
    },
    {
      label: t("faq"),
      link: "#faq",
    },
  ];

  const handleLinkClick = () => {
    setExtendNavbar(false);
  };

  useEffect(()=> {
    fetchUserPicture();
    fetchUserPoint();
  },[])

  return (
    <NavbarContainer extendNavbar={extendNavbar} className="navbar">
      <NavbarInnerContainer>

      <OpenLinksButton
        onClick={() => {
          setExtendNavbar((curr) => !curr);
        }}
      > 
        {extendNavbar ? <> <IoMdClose size={32} style={iconStyle}/> </> : <> <GiHamburgerMenu size={32} style={iconStyle}/> </>}
      </OpenLinksButton>

      <NavbarLeftContainer>
        <NavbarLinkToImg to="/">
          <Logo extendNavbar={extendNavbar} src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/c039dc19-4d88-4b95-0180-445a54c94c00/original"></Logo>
        </NavbarLinkToImg>
      </NavbarLeftContainer>

      <NavbarRightContainer>
        <NavbarLinkContainer>

          <NavbarLink href="#what">{t("whatIsIt")}</NavbarLink>
          <NavbarLink href="#support-conditions">{t("supportConditions")}</NavbarLink>
          <NavbarLink href="#how">{t("how")}</NavbarLink>
          <NavbarLink href="#features">{t("features")}</NavbarLink>
          <NavbarLink href="#download">{t("download")}</NavbarLink>
          <NavbarLink href="#questions">{t("faq")}</NavbarLink>

        </NavbarLinkContainer>


        <RightMenuContainer>

          <RightMenuSubContainer>

            <LanguageButton />
            <User 
              userPictureData={userPictureData}
              userPoint={userPointData}
            />
            <SigninSignup 
            children={t("login")}
            />

          </RightMenuSubContainer>

            <DiscoverButton  onClick={onButtonClick}>
              {t("discoverNow")}
              <FaChevronRight size={32}/>
            </DiscoverButton>
                
        </RightMenuContainer>

      </NavbarRightContainer>
      </NavbarInnerContainer>

      {extendNavbar && (
        <NavbarExtendedContainer>

          <MenuContainer>

            <MenuItemDownload>
              <MenuItemContainer>
                <NavbarLinkIconContainer>
                  <HiOutlineDownload size={32} />
                  <NavbarLinkDownload to="/app" activeClassName="active">
                    {t("Download App")}
                  </NavbarLinkDownload>
                </NavbarLinkIconContainer>
                <IoMdPlayCircle size={25} />
              </MenuItemContainer>
            </MenuItemDownload>

            {menuItems?.map((item, index) => (
              <MenuItem key={index}>
                <MenuItemContainer href={item.link}>
                  <NavbarLinkIconContainer>
                    <NavbarLinkExtended href={item.link} onClick={handleLinkClick} activeClassName="active">
                      {t(item.label)}
                    </NavbarLinkExtended>
                  </NavbarLinkIconContainer>
                  <BsChevronRight size={25} />
                </MenuItemContainer>
              </MenuItem>
            ))}   

          </MenuContainer>

        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  );
}

export default NavbarLandingPage

export const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  width: ${(props) => (props.extendNavbar ? "90%" : "100%")};
  height: ${(props) => (props.extendNavbar ? "100vh" : "60px")};
  background-color: var(--bg-navbar);
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: height 0.3s;
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center; 
`;

export const NavbarLeftContainer = styled.div`
  // flex: 20%;
  // display: flex;
  // align-items: center;

  flex: 30%;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex: 52%;
    justify-content: center;
  }
`;

export const Logo = styled.img`
  margin: 0 30px;
  max-width: ${(props) => (props.extendNavbar ? "140px" : "180px")};
  height: auto;

  @media (max-width: 768px) {
    max-width: 150px;
    margin: 0 5px;
  }
`;

export const NavbarRightContainer = styled.div`
  // flex: 80%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  // @media (max-width: 700px) {
  //   flex: 1%;
  //   display: flex;
  //   justify-content: flex-end;
  // }

  flex: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex: 1%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavbarLink = styled.a`
  color: #C0C0C0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  @media (max-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 900px) and (max-width: 1024px) {
    font-size: 9px;
  }
`;


export const NavbarLinkTo = styled(Link)`
  color: #C0C0C0;
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const NavbarLinkToImg = styled(Link)`
  
`;

export const OpenLinksButton = styled.button`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // width: 70px;
  // height: 50px;
  // background: none;
  // border: none;
  // color: white;
  // font-size: 45px;
  // cursor: pointer;

  // @media (min-width: 700px) {
  //   display: none;
  // }

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled.a`
  color: white;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const NavbarLinkExtendedTo = styled(Link)`
  color: white;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const RightMenuContainer = styled.div`
  // display: flex;
  // justify-content: end;
  // align-items: center;
  // width: 560px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RightMenuSubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 900px) {
    display: none;
  }
`;

export const DiscoverButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    // background-color: #012124;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
export const MenuContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 600px;

  @media (max-width: 376px) {
    max-height: 330px;
  };

  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;
export const MenuItemDownload = styled.div`
  border-bottom: 1px solid grey;
  align-items: center;
  text-decoration: none;
  color: white;
  // background: linear-gradient(270deg,#033036, #2ffcfa);
  // background: linear-gradient(270deg,#033036, #2ffcfa);
  // background: linear-gradient(270deg,#033036, #2ffceb);
  // background: linear-gradient(270deg,#033036, #b8ff53);
  // background: linear-gradient(270deg,#033036, #42ff4f);
  background: linear-gradient(270deg,#fec233, #033036);
  background: linear-gradient(151deg, rgb(254, 194, 51), rgb(203 34 118));
  background: linear-gradient(151deg, rgb(254, 194, 51), rgb(203, 34, 118));
  font-size: 20px;
  gap: 10px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #cf9224;
  }
`;
export const MenuItem = styled.div`
  border-bottom: 1px solid grey;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 20px;
  gap: 10px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #cf9224;
  }
`;

export const MenuItemContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  height: 60px;
  color: white;
  text-decoration: none;
`;

export const NavbarLinkIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  gap: 10px;
  width: 100%;
`;
export const NavbarLinkDownload = styled(NavLink)`
  width: 105px;
  text-align: center;
  text-transform: capitalize;
  color: white;
  font-size: medium;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  &.active {
    color: #cf9224;
    font-weight: bold;
  }

  @media (max-width: 900px) {
    width: 100%;
    text-align: start;
  }
`;
export const NavbarNavLink = styled(NavLink)`
  width: 105px;
  text-align: center;
  text-transform: capitalize;
  color: #c0c0c0;
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  &.active {
    color: #cf9224;
    font-weight: bold;
  }

  @media (max-width: 900px) {
    width: 100%;
    text-align: start;
  }
`;