import { fansupportPrivate } from "../index";

export const postLikeDisLikeCampaign = async ( campaignId ) => {
    //  console.log("payload", campaignId);
    try {
        const data =  fansupportPrivate.post('/campaign/like-dislike', {
            campaignId: campaignId,
    })
        return data;

    } catch (error) {
        console.log("postCampaignError",error);
    }
}