import React, { useEffect, useState } from 'react';
import { getPollUserAnswers } from '../../services/poll/pollUserAnswersService';
import { useTranslation } from 'react-i18next';
import { Text, Button, Loader, Pagination } from "@mantine/core";
import { FaArrowRight } from 'react-icons/fa';
import styled from "styled-components";
import { NotFound } from '../../pages';

const PollUserAnswers = () => {
  const [pollUserAnswers, setPollUserAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const { t } = useTranslation();

  const fetchPollUserAnswers = async () => {
    try {
      setLoading(true);

      const page = currentPage;
      const perPage = 1; 

      const res = await getPollUserAnswers({ page, perPage });
      setPollUserAnswers(res?.data?.data?.data || []);
      setTotalPages(res?.data?.data?.total || 0);
      setLoading(false);
    } catch (error) {
      console.log("fetchPollUserAnswers", error);
      setLoading(false);
    }
  }

  // console.log("useranswer",pollUserAnswers);

  useEffect(() => {
    fetchPollUserAnswers();
  }, [currentPage]);


  return (
    <>
      <DivContent>
        {loading ? (
          <Loader />
        ) : (
          <>
          <h1 style={{textAlign: 'center'}}>{t("pollUserAnswers")}</h1>
            {pollUserAnswers && pollUserAnswers.length > 0 ? (
              <Content>
                {pollUserAnswers?.map((poll, index) => (
                  <PollQuestion key={index} poll={poll} />
                ))}
              </Content>
            ) : (
              <NotFound margin="42px auto" text={t("pollUserWarning")} />
            )}
          </>
        )}
      </DivContent>
      <Pagination
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPages}                                    
        siblings={3}
        defaultValue={1}
        color="yellow"
        size="lg"
        withEdges 
      />
    </>
  )
}

const PollQuestion = ({ poll }) => {

  const { t } = useTranslation();

  return (
    <>
    <QuestionContainer>
    <h3>{poll.title}</h3>
      <OptionsContainer>
        {poll.questions.map((question, index) => (
          <Option key={index} selected={question.selected}>
            <QuestionText>{String.fromCharCode(65 + index)}.) {question.questionText}</QuestionText>
            <ScoreText>{question.score}</ScoreText>
          </Option>
        ))}
      </OptionsContainer>
      <small>{t("joinedPollExplain")}</small>
    </QuestionContainer>
    </>
  )
}

export default PollUserAnswers;

const Content = styled.div`
  padding: 5px;
`;

const QuestionContainer = styled.div`
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const QuestionText = styled(Text)`
  font-weight: 600;
  margin-bottom: 10px;
`;

const ScoreText = styled.span`
  font-size: 12px;
  color: #888;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${props => props.selected ? 'lightgreen' : 'transparent'};
`;

const DivContent = styled.div`
  width: auto;
  padding: 15px;
  border-radius: 10px;

  @media (max-width: 720px) {
    width: auto;
  }
`;
