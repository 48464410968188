import React from "react";
import styled from "styled-components";
import { FaFilter } from "react-icons/fa";
import { useDisclosure } from "@mantine/hooks";
import { Drawer, Button, Group } from "@mantine/core";
import { Filterr } from "../../components";
import { useTranslation } from "react-i18next";
import { getStartLanguage } from "../../logic/getStartLanguage";


const GlobalFilter = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const {t} = useTranslation();
  const startLanguage = getStartLanguage();

  const handleFilter = () => {
    close();
    const sportName = localStorage.getItem("selectedSportName") ? 
    `/${localStorage.getItem("selectedSportName")}` : "";
    const countryName = localStorage.getItem("selectedCountryName") ?
    `/${localStorage.getItem("selectedCountryName")}` : "";
    const tournamentName = localStorage.getItem("selectedTournamentName") ? 
    `/${localStorage.getItem("selectedTournamentName")}` : "";
    const teamName = localStorage.getItem("selectedTeamName") ? 
    `/${localStorage.getItem("selectedTeamName")}` : "";
  
    // if (sportName && countryName && tournamentName && teamName) {
      const filteredURL = `/${startLanguage}${sportName}${countryName}${tournamentName}${teamName}`;
      window.location.href = filteredURL;
    // } else {
    //   window.location.href = "/";
    // }
  }

  return (
    <>
      <GlobalFilterContainer>
        <Drawer 
            opened={opened} 
            onClose={close} 
            keepMounted={true}
            position="right"
            title="Global Filter Menü"
            overlayProps={{ opacity: 0.5, blur: 6 }}
            transitionProps={{ transition: 'rotate-left', duration: 150, timingFunction: 'linear' }}
        >      
          <Filterr max-width="550px" contentWidth="375px" margin="7px auto" />
            <FilterCloseButton onClick={handleFilter}>{t("applyFilter")}</FilterCloseButton>
        </Drawer>

        <Group position="center">
          <FilterOpenButton onClick={open}><FaFilter size={22} color="white" /></FilterOpenButton>
        </Group>
        
      </GlobalFilterContainer>
    </>
  );
};

export default GlobalFilter;

export const GlobalFilterContainer = styled.div`
  margin-right: 10px;
`;

export const FilterOpenButton = styled(Button)`
  background-color: transparent;

  &:hover {
      background-color: grey;
  }
`;

export const FilterCloseButton = styled.button`
  width: 100%;
  height: 40px;
  color: white;
  background-color: #384D50;
  cursor: pointer;
  transition-duration: 0.3s;
  font-size: 16px;

  &:hover {
    background-color: rgb(56 77 80 / 88%);
  }
`;
