import React from 'react';
import styled from 'styled-components';

const GeneralButton = (props) => {
  return (
    <StyledButton
      width={props.width}
      height={props.height}
      backgroundColor={props.backgroundColor}
      color={props.color}
      border={props.border}
      padding={props.padding}
      margin={props.margin}
      borderRadius={props.borderRadius}
      fontSize={props.fontSize}
      hoverBackgroundColor={props.hoverBackgroundColor}
      hoverColor={props.hoverColor}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </StyledButton>
  )
}

export default GeneralButton

export const StyledButton = styled.button`

  width: ${(props) => props.width || '100px'};
  height: ${(props) => props.height || '50px'};
  background: ${(props) => props.backgroundColor || 'linear-gradient(to right, #1b8a2f 0, #31ac47 100%)'};
  color: ${(props) => props.color || '#fff'};
  border: ${(props) => props.border || 'none'};
  padding: ${(props) => props.padding || ''};
  margin: ${(props) => props.margin || '20px 0px 0px'}
  border-radius: ${(props) => props.borderRadius || '5px'};
  font-size: ${(props) => props.fontSize || '16px'};
  border-radius: 5px;
  
  &:hover {
    background: ${(props) => props.hoverBackgroundColor || 'linear-gradient(to right, #1b8a2f 0, green 100%)'};
    color: ${(props) => props.hoverColor || 'white'};
    cursor: pointer;
  }
`;