import React from 'react';
import styled from "styled-components"

const AddFavoritesButton = ({ fontSize, text, isAdded, icon, onClick  }) => {
  return (
    <AddButton onClick={onClick} isAdded={isAdded}>
        <AddButtonContainer>
            <AddButtonDesc fontSize={fontSize} >{text}</AddButtonDesc>                         
            {icon}
        </AddButtonContainer>
    </AddButton>
  )
}

export default AddFavoritesButton;

export const AddButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AddButton = styled.button`
    background-color: ${props => props.isAdded ? '' : 'red'};
    border: ${props => props.isAdded ? '2px solid green' : '2px solid red'};    
    border-radius: 10px;
    color: ${props => props.isAdded ? 'green' : 'white'};
    cursor: pointer;
    transition-duration: 0.3s;
    padding: 0 10px;

    &:hover {
        background-color: ${props => props.isAdded ? 'green' : 'transparent'};
        color: ${props => props.isAdded ? 'white' : 'red'};
    }
`;

export const AddButtonDesc = styled.p`
    margin-right: 15px;
    font-size: ${props => props.fontSize ? '11px' : ''};
    text-transform: uppercase;
    
    @media (max-width: 720px){
        font-size: 11px;
    }
`;
