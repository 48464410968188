import { fansupportPublic } from "../index";

export const getForgotPassword = async ( email ) => {
    try {
        const data = await fansupportPublic.post('/identity/forgot-password', {
            value: email
    })
        return data;

    } catch (error) {
        console.log("getForgotPassword", error);
    }
}