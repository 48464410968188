import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { AdImageCard, Breadcrumbs, CampaignCompetitionCard, CampaignGeneralSuccess, CampaignTransfer, CampaignTypeCard, Filterr } from "../../components";
import { Container, Pagination } from "@mantine/core";
import { useSelector } from "react-redux";
import { getCampaign } from "../../services/campaign/campaignService";
import AdsLocation from "../../enums/AdsLocation";
import LanguageTag from "../../enums/LanguageTag";
import { getAds } from "../../services/ads/adsService";
import NotFound from "../not-found/NotFound";

const CampaignTypes = {
    TRANSFER: 0,
    EVENT: 1,
    CHAMPIONSHIP: 2,
    SCORE: 3,
    UNCONDITIONAL: 4,
    ROUND: 5,
    SOCIAL: 6,
  };

 
const CampaignAllDetail = () => {

    const [campaignAll,setCampaignAll] = useState();
    const [campaignType, setCampaignType] = useState();
    const [campaignTotal, setCampaignTotal] = useState();
    const [activePage, setPage] = useState(1);
    const [adsData, setAdsData] = useState([]);

    const selectedSportId = useSelector(state => state.filter.sportId);
    const selectedCountryId = useSelector(state => state.filter.countryId);
    const selectedTournamentId = useSelector(state => state.filter.tournamentId);
    const selectedTeamId = useSelector(state => state.filter.teamId);

    const fetchAds = async () => {
      try {
        const storedLanguageId = localStorage.getItem("languageId");
        const originalLanguage = navigator.language;
        const newLanguage = originalLanguage.split('-')[0];
  
        const language = Object.keys(LanguageTag).find(
          (key) => LanguageTag[key] === newLanguage
        );
        
        const languageId = storedLanguageId ? storedLanguageId : language;
          
        const countryId = selectedCountryId || "6498b1e91d6341510544c3b0";
        const platformType = 1;
        const location = AdsLocation['WEB_LEFT'];
    
        const res = await getAds({ countryId, languageId, platformType, location })
    
        setAdsData(res?.data?.data || {});
      } catch (error) {
        console.log("Ads Error:",error);
      }
    }
    
    const fetchCampaign = async () => {

      const campaignType = localStorage.getItem("type");
      const sportId = selectedSportId;
      const countryId = selectedCountryId;
      const tournamentId = selectedTournamentId;
      const teamId = selectedTeamId;
      const status = 1;
      const sort = "ASC";
      const page = activePage;
      const perPage = 10;
  
      const resCampaign = await getCampaign({ campaignType, sportId, countryId, tournamentId, teamId, status, sort, page, perPage })
  
      setCampaignAll(resCampaign?.data?.data?.data || {});
      setCampaignTotal(resCampaign?.data?.data?.total || {});
      setCampaignType(resCampaign?.data?.data?.data[0]?.type);
    };


    useEffect(() => {  
        fetchCampaign();
        fetchAds();
    },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId, activePage])

  return (
    <>
      {adsData ? 
        <>
          <AdImageCard adsData={adsData} scrollValue={250} right="0" />
          <AdImageCard adsData={adsData} scrollValue={250} left="0" />
        </> : <NotFound />
      }
      <Filterr useNavigateProp={true} />
      <CampaignDetailMain>
        <BreadcrumbsWrapper>
          <Breadcrumbs/>    
        </BreadcrumbsWrapper>

        <Container size="1160px" id="media-content">

          {campaignType === CampaignTypes.EVENT && (
            <>
              <EventWrapper>
                  {campaignAll?.map((campaignData) => (
                      <CampaignCompetitionCard
                          key={campaignData.id}
                          campaignData={campaignData}
                      />
                  ))}
              </EventWrapper>
            </>  
          )}

          {campaignType === CampaignTypes.CHAMPIONSHIP && (
            <>
              <GeneralSuccessWrapper>
                  {campaignAll?.map((campaignData) => (
                      <CampaignGeneralSuccess
                          key={campaignData.id}
                          campaignData={campaignData}
                      />
                  ))}
              </GeneralSuccessWrapper>
            </>  
          )}

          {campaignType === CampaignTypes.TRANSFER && (
            <>
              <TransferWrapper>
                  {campaignAll?.map((campaignData) => (
                      <CampaignTransfer
                          key={campaignData.id}
                          campaignData={campaignData}
                      />
                  ))}
              </TransferWrapper>
            </>  
          )}

        </Container>

        <PaginationContainer>
          <Pagination
              value={activePage} 
              onChange={setPage} 
              total={campaignTotal / 10 + 1} 
              siblings={3} 
              defaultValue={10}
              color="yellow"
              size="lg"
              withEdges 
          />
        </PaginationContainer>
        

      </CampaignDetailMain>
    </>
  );
};

export default CampaignAllDetail;

export const CampaignDetailMain = styled.main`
    max-width: 960px;
    margin: 60px auto;
    padding: 15px;
`;

export const BreadcrumbsWrapper = styled.div`
  margin: 10px 0 70px 5px;
`;

export const EventWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;

  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 10px;
  }
`;

export const GeneralSuccessWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;

  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 10px;
  }
`;

export const TransferWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;

  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 10px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
`;