import { fansupportPrivate } from "../index";

export const getUserPicture = async () => {
    try {
        const response = await fansupportPrivate.get('identity/user-picture-base64');
        return response;
    } catch (error) {
        console.error("getUserPicture Error:", error);
    }
};
