import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Group, TextInput } from '@mantine/core';
import { postChangeEmail } from "../../services/identity/changeEmailService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

const ChangeEmail = ({data, fetchUserDetails}) => {

    const [changeEmailData, setChangeEmailData] = useState([]);
    const [changeEmailMessage, setChangeEmailMessage] = useState([]);

    const userData = useSelector((state) => state.user.user?.data);
    const { t } = useTranslation();

    const formik = useFormik({
      initialValues: {
        newEmail: "",
      },
      onSubmit: async (values) => {        
        try {
          const res = await postChangeEmail(values.newEmail);
  
          setChangeEmailData(res?.data?.data?.isSuccess || []);
          setChangeEmailMessage(res?.data?.message || []);
  
          if (res?.data?.isSuccess === false) {
            
          } else if (changeEmailData) {

            fetchUserDetails();
          }
  
        } catch (error) {
          console.log("postChangeEmail",error)
        }      
      },
      // validationSchema: userSchema(t),
        
    });
    // console.log("changePassword",changePasswordData);

    useEffect(() => {

    },[changeEmailMessage])
      
  return (
      <>
        <ToastContainer />
        <RegisterForm onSubmit={formik.handleSubmit}>
          <RegisterTitle>{t("changeEmail")}</RegisterTitle>
          <TextInputLabel htmlFor="newEmail-input" className="text-input-label">{t("currentEmail")}</TextInputLabel>
          <TextInput
            id="currentEmail-input"
            name="currentEmail"
            size="md"     
            mb="xl"
            disabled
            placeholder={data || userData.email}       
          />
          <TextInputContainer>
            <TextInputLabel htmlFor="newEmail-input" className="text-input-label">{t("newEmail")}</TextInputLabel>
            <TextInput
              id="newEmail-input"
              name="newEmail"
              placeholder={t("newEmail")}
              size="md" 
              mb="xl" 
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </TextInputContainer>
          {changeEmailMessage && (
            <div style={{color: "red", fontSize: "17px", margin: "30px 0 10px 5px", fontWeight: "600"}}>{changeEmailMessage}</div>
          )}

          <Group  grow>
            <ChangeButton 
              type="submit"
              size="md"
            >
              {t("change")}
            </ChangeButton>
          </Group>
        </RegisterForm>
      </>
  );
};

export default ChangeEmail;

export const RegisterForm = styled.form`
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="password"] {
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    height: 32px;
    border-radius: 5px;
    outline: none;
  }
  width: 70%;
  padding: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const RegisterTitle = styled.div`
  margin-bottom: 50px;
  font-size: 26px;
  text-align: center;
  font-weight: 600;
`;
export const TextInputLabel = styled.label`
`;
export const TextInputContainer = styled.div`
  margin: 35px 0;
`;
export const ChangeButton = styled(Button)`
  height: 50px;
  background: linear-gradient(to right, #1b8a2f 0, #31ac47 100%);

  &:hover {
    background: linear-gradient(to right, #1b8a2f 0, green 100%);
  }
`;