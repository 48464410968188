import React from 'react';
import styled from "styled-components";
import { CampaignsUserCertificatesCard } from "../../components";

const UserCertificates = () => {
  return (
    <CampaignsUserCertificatesMain className='campaigns-user-certificates-main'>
    <CardContainer>
      <CampaignsUserCertificatesCard />
    </CardContainer>
  </CampaignsUserCertificatesMain>
  )
}

export default UserCertificates

export const CampaignsUserCertificatesMain = styled.main`
  background-color: #f8f8f8;
  padding: 80px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;