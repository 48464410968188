import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mantine/core';
import { NotFound } from '../../pages';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import RemoveTurkishChars from '../../logic/RemoveTurkishChars';
import { getStartLanguage } from '../../logic/getStartLanguage';

const CampaignCard = (props) => {

  const { 
    display,
    responsiveSmallWidth, 
    responsiveDonorList, 
    campaignType, 
    campaignTypeText, 
    seeAllTextEvent, 
    seeAllTextSuccess, 
    seeAllTextTransfer, 
    icon, 
    title, 
    desc, 
    children, 
    contents, 
    cardJustify, 
    cardGap, 
    cardWidth, 
    responsiveXXLWidth,
    responsiveXLWidth,
    responsiveLargeWidth,
    responsiveMediumWidth,
    responsiveSmWidth,
    responsiveWidth, 
    cardHeight, 
    cardPadding, 
    cardMargin, 
    seeAllText, 
    contentHeight, 
    overflow, 
    overflowX,
    darkIcon,
    loading 
  } = props;  
  
  const showNotFound = !children || !contents;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const startLanguage = getStartLanguage();
  // const isLoading = true;


  const handleAllEvent = () => {
    navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("campaigns") })}/${RemoveTurkishChars({ text: t(campaignTypeText) })}`);
    localStorage.setItem("type",campaignType);
  }
  const handleAllSuccess = () => {
    navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("campaigns") })}/${RemoveTurkishChars({ text: t(campaignTypeText) })}`);
    localStorage.setItem("type",campaignType);
  }
  const handleAllTransfer = () => {
    navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("campaigns") })}/${RemoveTurkishChars({ text: t(campaignTypeText) })}`);
    localStorage.setItem("type",campaignType);
  }

    return (
      <CardContainer className='campaign-card' cardWidth={cardWidth} responsiveXXLWidth={responsiveXXLWidth} responsiveXLWidth={responsiveXLWidth} responsiveLargeWidth={responsiveLargeWidth} responsiveMediumWidth={responsiveMediumWidth} responsiveSmWidth={responsiveSmWidth} responsiveSmallWidth={responsiveSmallWidth} responsiveWidth={responsiveWidth} responsiveDonorList={responsiveDonorList} cardHeight={cardHeight} cardPadding={cardPadding} cardMargin={cardMargin}>
        {title && 
        <CardHeaderContainer>

          <CardHeader>
            <CardHeaderIcon src={icon} className={darkIcon}/>
            <CardHeaderTitle className='campaign-card-header-title'>{title}</CardHeaderTitle>
          </CardHeader>
          
          <CardHeaderDesc>{desc}</CardHeaderDesc>
          
        </CardHeaderContainer>
        }
        <CardContent className='campaign-card-content' display={display} cardJustify={cardJustify} cardGap={cardGap} contentHeight={contentHeight} overflow={overflow} overflowX={overflowX}>
        {loading ? (
          <>
            <Skeleton height={150} mb={10} />
            <Skeleton height={150} mb={10} />
            <Skeleton height={150} mb={10} />
          </>
        ) : children && children?.length > 0 ? (
          children
        ) : contents ? (
          contents
        ) : showNotFound ? (
          <NotFound text={t("notFoundCampaigns")} margin= "5px auto"/>
        ) : null}
        </CardContent>
        {seeAllTextEvent && <SeeAllButton onClick={handleAllEvent} className='see-all-button'>{seeAllTextEvent}</SeeAllButton>}
        {seeAllTextSuccess && <SeeAllButton onClick={handleAllSuccess} className='see-all-button'>{seeAllTextSuccess}</SeeAllButton>}
        {seeAllTextTransfer && <SeeAllButton onClick={handleAllTransfer} className='see-all-button'>{seeAllTextTransfer}</SeeAllButton>}
      </CardContainer>
    );
  };
  
  export default CampaignCard;


  export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${props => props.cardWidth || '325px'};
    height: ${props => props.cardHeight || '635px'};
    padding: ${props => props.cardPadding || '10px'};
    margin: ${props => props.cardMargin || ''};
    background-color: white;
    border-radius: 8px;
    // border: 1px solid #efefef;
    border: 1px solid #dbe0e5;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &:hover {
      box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16); 
    }

    // @media (max-width: 720px) {
    //   width: ${props => props.responsiveSmallWidth || '340px'};
    // }

    // @media screen and (min-width: 900px) and (max-width: 1170px) {
    //   width: ${props => props.responsiveWidth};
    //   margin: 0px auto;
    // }
    // @media screen and (min-width: 900px) and (max-width: 1024) {
    //   width: ${props => props.responsiveDonorListWidth};
    //   margin: 0px auto;
    // }
    // @media (max-width: 1486px) {
    //   width: ${props => props.responsiveDonorList};
    //   margin: 0;
    // }
    @media screen and (min-width: 1407px) and (max-width: 1514px) {
      width: ${props => props.responsiveXXLWidth};
      margin: 0px auto;
    }
    @media screen and (min-width: 1320px) and (max-width: 1407px) {
      width: ${props => props.responsiveXLWidth};
      margin: 0px auto;
    }
    @media screen and (min-width: 1230px) and (max-width: 1320px) {
      width: ${props => props.responsiveLargeWidth};
      margin: 0px auto;
    }
    @media screen and (min-width: 1150px) and (max-width: 1230px) {
      width: ${props => props.responsiveMediumWidth};
      margin: 0px auto;
    }
    @media screen and (min-width: 1024px) and (max-width: 1150px) {
      width: ${props => props.responsiveSmWidth};
      margin: 0px auto;
    }
  `;

  export const CardHeaderContainer = styled.h2`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 18px;
    font-weight: bold;
    margin: 15px 10px;
    color: black;
  `;
  export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `;

  export const SeeAllButton = styled.button`
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
    font-size: 16px;
    color: black; 
    cursor: pointer;
  `;

  export const CardHeaderTitle = styled.div`
    @media screen and (min-width: 1320px) and (max-width: 1407px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1230px) and (max-width: 1320px) {
      font-size: 14px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1230px) {
      font-size: 13px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1150px) {
      font-size: 11px;
    }
  `;
  export const CardHeaderDesc = styled.div`
    font-size: 16px;
    margin-left: 35px;
    color: green;
  `;

  export const CardHeaderIcon = styled.img`
    width: 25px;
    height: 25px;
  `;

  export const CardContent = styled.div`
  display: ${props => props.display || 'flex'};
  flex-direction: column;
  justify-content: ${props => props.cardJustify || 'start'};
  justify-content: ${(props) => (props.children && props.children.length > 0 ? props.cardJustify : 'center')};
  gap: ${props => props.cardGap || '10px'};
  height: ${props => props.contentHeight || '550px'};
  max-height: ${props => props.contentHeight || '550px'};
  overflow: ${props => props.overflow || 'none'};
  overflow-x: ${props => props.overflowX || 'none'};
  background-color: white;

  /* Width and height of the scrollbar track */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 15px;
  }

  /* Background color of the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Color of the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  /* Color of the scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  `;