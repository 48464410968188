import { fansupportPublic } from "../index";

export const getSports = async (languageCode) => {
  try {
    const res = fansupportPublic.get('/sport/sports');

    return res;
    
  } catch (error) {
    throw error;
  }
};