import { useState } from "react";
import { Outlet } from "react-router-dom";
import { NavbarHome, NavbarLandingPage } from "../../components";
import styled from "styled-components";

const HomeLayout = () => {

  const [showNavbarHome, setShowNavbarHome] = useState(false);


  const handleKesfetButtonClick = () => {
    setShowNavbarHome(true);
  };

  return (
    <>
    <NavbarContainerWrapper>
    {showNavbarHome ? <NavbarHome /> : 
      <NavbarLandingPage 
      onKesfetButtonClick={handleKesfetButtonClick} />}
    </NavbarContainerWrapper>
    
    <Outlet/>
    </>
  )
}

export default HomeLayout

export const NavbarContainerWrapper = styled.div`
  padding-top: 60px;
`;