import { fansupportPrivate } from "../index";

export const getCampaignsUserJoinedList = async ({ page, perPage }) => {
    try {
        const data = fansupportPrivate.get('/campaign/user-joined-campaigns-list', {params: {
            page,
            perPage,
        }})
        return data;
        
    } catch (error) {
        console.log("getCampaignsUserJoinedList",error)
    }
}