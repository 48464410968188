import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { CampaignCard, CurrencyData, CurrencyFormat } from "../../components";
import { useTranslation } from 'react-i18next'; 
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaRegHeart,
  FaHeart,
} from "react-icons/fa";
import { 
  XIcon,
} from "react-share";
import styled from "styled-components";
import { postLikeDisLikeCampaign } from "../../services/campaign/campaignLikeDislikeService";
import { getCampaign } from "../../services/campaign/campaignService";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";
 
const CampaignNumbersDetailCard = (props) => {

  const [liked, setLiked] = useState(() => {
    const isLiked = localStorage.getItem("isLiked");
    return isLiked ? isLiked === "true" : false;
  });
  const [eventData, setEventData] = useState([]);

  const user  = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const startLanguage = getStartLanguage();

  const currencySymbol  = CurrencyData();
  const symbol = localStorage.getItem("selectedCurrency") || currencySymbol || '€';

  const campaignId = props.campaign.id


  const getCampaignDetail = async () => {

    const url = window.location.href;
    const pathId = url.substring(url.lastIndexOf('/') + 1);
    
    const id = pathId; 
    const status = 1;
    const sort = "DESC";
    const page = 1;
    const perPage = 1;

    const res = await getCampaign({ id, status, sort, page, perPage })

    setEventData(res?.data?.data?.data[0] || {});
  };

  const handleLikeClick = async () => {
    if (user.user) {
      const res = await postLikeDisLikeCampaign(campaignId);
      if(res?.data?.data === true){
        setLiked(true);
        localStorage.setItem("isLiked", true);
        window.location.reload();
      }
    }
    else{
      navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
    }
    
  };

  const handleDislikeClick = async () => {
    if (user.user) {
      const res = await postLikeDisLikeCampaign(campaignId);
      if(res?.data?.data === true){
        setLiked(false);
        localStorage.setItem("isLiked", false);
        window.location.reload();
      }
    }
    else{
      navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
    }

  };

  useEffect(() => {
    getCampaignDetail();
  },[liked])

  return (
    <>
      <CampaignCard
        cardWidth="auto"
        responsiveWidth="100%"
        responsiveSmallWidth="100%"
        cardHeight="none"
        contentHeight="none"
        cardPadding="10px"
        contents={
          <CampaignNumbersDetailCardChildren 
            props={props}
            symbol={symbol}
            liked={liked}
            onLikeClick={() => handleLikeClick(props.campaignId)}
            onDislikeClick={() => handleDislikeClick(props.campaignId)}
          />
        }
      />
    </>
  );
};

export default CampaignNumbersDetailCard;


const CampaignNumbersDetailCardChildren = (props) => {

  const { t } = useTranslation();

  const currencySymbol  = CurrencyData();
  const symbol = localStorage.getItem("selectedCurrency") || currencySymbol || '€';


  const iconStyle = {
    color: "#3C4F51",
    marginRight: "10px",
  };
  const style = {
    color: "red",
    marginRight: "10px",
  };

  return (
    <>
      <FooterSocialMedia>
        <FooterSocialMediaLink to="https://www.instagram.com/fansupport.tr/" target="_blank">
          <FaInstagramSquare style={{ color: "#e4405f", marginRight: "10px" }} size={25} />
        </FooterSocialMediaLink>
        <FooterSocialMediaLink to="https://www.facebook.com/fansupport.tr" target="_blank">
          <FaFacebookSquare style={{ color: "#1877f2", marginRight: "10px" }} size={25} />
        </FooterSocialMediaLink>
        <FooterSocialMediaLink to="https://twitter.com/fansupporttr" target="_blank">
          <XIcon style={{ color: "#1da1f2", marginRight: "10px" }} size={25} />
        </FooterSocialMediaLink>
        {props.liked ? (
          <FaHeart style={style} size={25} onClick={props.onDislikeClick} />
        ) : (
          <FaRegHeart style={iconStyle} size={25} onClick={props.onLikeClick} />          
        )}
      </FooterSocialMedia>

      <DetailCardChildrenDesc>

        <ChildrenDescRow >
          <ChildrenDescTitle>{props.props.homeTeam.name} {t("collectedAmount")}</ChildrenDescTitle>
          <ChildrenDescAmount 
            symbol={symbol}
          >
            <CurrencyFormat amount={props.props.homeTeam.homeAmount}/>
            </ChildrenDescAmount>
        </ChildrenDescRow>
        <ChildrenDescRow >
          <ChildrenDescTitle>{props.props.awayTeam.name} {t("collectedAmount")}</ChildrenDescTitle>
          <ChildrenDescAmount 
            symbol={symbol}
          >
            <CurrencyFormat amount={props.props.awayTeam.awayAmount}/>
          </ChildrenDescAmount>
        </ChildrenDescRow>
        <ChildrenDescRow >
          <ChildrenDescTitle>{t("numberParticipant")}</ChildrenDescTitle>
          <ChildrenDescAmount>{props.props.campaign.participant}</ChildrenDescAmount>
        </ChildrenDescRow>
        <ChildrenDescRow >
          <ChildrenDescTitle>{t("like")}</ChildrenDescTitle>
          <ChildrenDescAmount>{props.props.campaign.like}</ChildrenDescAmount>
        </ChildrenDescRow>


      </DetailCardChildrenDesc>
    </>
  );
};

export const FooterSocialMedia = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;
`;
export const DetailCardChildrenDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;
export const ChildrenDescRow = styled.div`
    border-top: 1px solid #eee;
    width: 100%;
    text-align: center;  
    padding: 5px 0;
`;
export const ChildrenDescTitle = styled.div`
    color: grey;
`;
export const ChildrenDescAmount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #555;

    ::after {
      content: "${(props) => props.symbol}";
      margin-left: 8px;
      color: #4caf50;
      font-size: 16px;
`;

export const FooterSocialMediaLink = styled(Link)`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;
