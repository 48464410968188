import { fansupportPrivate } from "../index";

export const postChangeEmail = async ( email ) => {
    //  console.log("email", email);
    try {
        const data =  fansupportPrivate.post('/identity/change-email', {
            email: email,
    })
        return data;

    } catch (error) {
        console.log("postChangeEmail",error);
    }
}