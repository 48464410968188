import React from 'react';
import { getStartLanguage } from '../../logic/getStartLanguage';

const CurrencyFormat = ({amount }) => {

  const startLanguage = getStartLanguage();

  const formatCurrency = new Intl.NumberFormat(startLanguage, {
    currency: localStorage.getItem("selectedCurrencyIso") || "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    roundingMode: 'floor'
  }).format(amount);

  return <>{formatCurrency}</>
}

export default CurrencyFormat