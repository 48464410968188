import React from "react";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../redux/features/auth/authSlice";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaUser } from "react-icons/fa";
import { getStartLanguage } from "../../logic/getStartLanguage";
import { useTranslation } from "react-i18next";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";

const SigninSignup = ({ fontSize, children }) => {

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { t } = useTranslation();
  const startLanguage = getStartLanguage();

  if (isAuthenticated) {
    return null;
  }
  return (
    <>
      <SigninSignupWrapper>
        <NavbarLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`} fontSize={fontSize}>
          <SignIn>
            <FaUser size={22} />
            {children}
          </SignIn>
        </NavbarLink>
      </SigninSignupWrapper>
    </>
  );
};

export default SigninSignup;

export const SigninSignupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 122px;
`;

export const NavbarLink = styled(NavLink)`
  color: #c0c0c0;
  // color: white;
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
  font-weight: bold;

  &:hover {
    color: black;
  }
`;

export const SignIn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c0c0c0;
  gap: 10px;
`;