import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { clearError } from "../../redux/features/error/errorSlice";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const ErrorComponent = () => {
  const isError = useSelector((state) => state.error.isError);
  const errorMessage = useSelector((state) => state.error.errorMessage);
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(true);

  const handleCloseError = () => {
    dispatch(clearError());
    close();
  };

  

  return (
    <>
      {isError && (
        <ModalMantine opened={opened} onClose={handleCloseError} centered>
            <ErrorImage src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/f430d17e-b310-4dda-d842-6fd11e6a6d00/original" alt="" />
            <ErrorMessage>{errorMessage}</ErrorMessage>
        </ModalMantine>
      )}
    </>
  );
};

export default ErrorComponent;

export const ErrorImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ModalMantine = styled(Modal)`
  padding: 0px;
`;

export const ErrorMessage = styled.p`
  color: black;
  margin-top: 20px;
  font-size: 18px;
`;

export const ButtonWrapper = styled.div`
  text-align: end;
`;
