import React,{ useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { GeneralButton, RegisterPassword } from '../../components';
import { PasswordInput } from "@mantine/core";
import { getResetPassword } from '../../services/identity/resetPasswordService';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Reset = () => {

  const [resetData, setResetData] = useState([]);
  const [resetMessage, setResetMessage] = useState([]);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const { t } = useTranslation();

  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter((segment) => segment);
  const token = pathSegments[1];

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    onSubmit: async (values) => {
      try {
        const { password, passwordConfirm } = values;

        const res = await getResetPassword(token, password, passwordConfirm);
        setResetData(res?.data?.isSuccess || false);
        setResetMessage(res?.data?.message || "");

        if (res?.data?.isSuccess === false) {
          toast.error(resetMessage);
        } else if (resetData ) {
          toast.success(t("changedPassword"));
        }

        // console.log("Reset",res);

        // console.log('Password reset successful', token, password, passwordConfirm);
      } catch (error) {
        console.error('Password reset failed', error);
      }
    },
    validate: (values) => {
      const errors = {};

      if (!values.password) {
        errors.password = 'Password is required';
      }
      if (!values.passwordConfirm) {
        errors.passwordConfirm = 'Password confirmation is required';
      } 
      else if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = t("passwordsDontMatch");
      }
      
      return errors;
    },
  });

  useEffect(() => {

    setPasswordsMatch(formik.values.password === formik.values.passwordConfirm);

  }, [formik.values.password, formik.values.passwordConfirm]);

  return (
    <>
      <ToastContainer/>
      <ResetMain>
        <ResetContainer>
          <ResetTitle>{t("resetPassword")}</ResetTitle>
          <form onSubmit={formik.handleSubmit}>
            <div>
                <RegisterPassword
                  handleChange={formik.handleChange}                   
                  value={formik.values.password}
                />
              {formik.touched.password && formik.errors.password && 
              <div style={{color: "red", fontSize: "13px", marginLeft: "5px"}}>{formik.errors.password}</div>
              }
            </div>
            <div>
              <ConfirmationPasswordInput  
                id="passwordConfirm" 
                name="passwordConfirm"
                placeholder={t("confirmPassword")}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.passwordConfirm}
                size="lg"
                my="lg"
                error={passwordsMatch ? (
                  ""
                ) : (
                  <ErrorMessage>{t("passwordsDontMatch")}</ErrorMessage>
              )}
              />
              {formik.touched.passwordConfirm && formik.errors.passwordConfirm && (
                <ErrorMessage>{t("passwordsDontMatch")}</ErrorMessage>
              )}
            </div>
            <div style={{color: "red", fontSize: "16px", marginLeft: "5px", marginBottom: "20px"}}>{resetMessage}</div>      
            <GeneralButton 
              type="submit"
              width="100%"
              margin="20px 0px"
            >
              {t("resetPassword")}
            </GeneralButton>
          </form>
        </ResetContainer>
      </ResetMain>
    </>
  )
}

export default Reset

export const ResetMain = styled.main`
  max-width: 1100px;
  margin: 100px auto;
`;
export const ResetContainer = styled.div`
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  margin: 50px auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
export const ResetTitle = styled.h2`
  text-align: center;
`;

export const ConfirmationPasswordInput = styled(PasswordInput)`
  input::placeholder {
    font-size: 14px;
    color: #999; 
  }
`;

export const ErrorMessage = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: red;
`;