import React, { useState, useEffect } from 'react';
import { Standing, Filterr, Breadcrumbs, AdImageCard } from '../../components';
import styled from "styled-components";
import { Container } from '@mantine/core';
import LanguageTag from '../../enums/LanguageTag';
import { useSelector } from 'react-redux';
import NotFound from '../not-found/NotFound';
import { getAds } from '../../services/ads/adsService';
import AdsLocation from '../../enums/AdsLocation';

const Standings = () => {

  const [adsData, setAdsData] = useState([]);
  const [localCountryId, setLocalCountryId] = useState(localStorage.getItem("startCountryId"))


  const selectedCountryId = useSelector(state => state.filter.countryId);


  const fetchAds = async () => {
    try {
      const storedLanguageId = localStorage.getItem("languageId");
      const originalLanguage = navigator.language;
      const newLanguage = originalLanguage.split('-')[0];

      const language = Object.keys(LanguageTag).find(
        (key) => LanguageTag[key] === newLanguage
      );
      
      const languageId = storedLanguageId ? storedLanguageId : language;

      const countryId = selectedCountryId === "" ? localCountryId : selectedCountryId;
      const platformType = 1;
      const location = AdsLocation['WEB_LEFT'];
  
      const res = await getAds({ countryId, languageId, platformType, location })
  
      setAdsData(res?.data?.data || {});
    } catch (error) {
      console.log("Ads Error:",error);
    }
  }

  useEffect(() => {
    fetchAds();
  }, [])

  return (
    <>
      {adsData ? 
        <>
          <AdImageCard adsData={adsData} scrollValue={250} right="0" />
          <AdImageCard adsData={adsData} scrollValue={250} left="0" />
        </> : <NotFound />
      }
      <StandingsMain>   
        <Filterr useNavigateProp={true} showTeamSelect={false} />
        <Container>
          <Breadcrumbs color="white"/>
        </Container>
        <Standing/>
      </StandingsMain>
      <div style={{paddingTop: '618px'}} id='media-content'></div>
    </>
  )
}

export default Standings

export const StandingsMain = styled.main`
  max-width: 1200px;
  margin: 0px auto;

  @media screen and (min-width: 900px) and (max-width: 1412px) {
    width: 68%
  }
  @media screen and (min-width: 900px) and (max-width: 1200px) {
    width: 60%;
  }
`;