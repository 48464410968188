import { fansupportPublic } from "./index";

export const getRound = async ({sportId, tournamentId, seasonId}) => {
    if(!sportId || !tournamentId || !seasonId) {
        return Promise.reject(new Error("Sport ID, Tournament ID is missing."));
    }
    return fansupportPublic.get('/sport/stages-rounds', {params: {
        sportId,
        tournamentId,
        seasonId
    }})
}