import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fansupportPublic, fansupportPrivate } from "../../../services/index";

const modulePrefix = "user";

const initialState = {
  user: JSON.parse(localStorage?.getItem("user")) || null,
  phoneVerificationData: null,
  resendSmsData: null,
  isExistData: false,
  loading: false,
  success: false,
  error: false,
  errorMessage: "",
};

export const selectIsAuthenticated = (state) => state.user.user?.data?.status === 0;

export const login = createAsyncThunk(
  `${modulePrefix}/login`,
  async ({ username, password }, { getState, rejectWithValue }) => {
    try {
      const res = await fansupportPublic.post("identity/sign-in", {
        username,
        password,
      });

      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      return res.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const logout = createAsyncThunk(
  `${modulePrefix}/logout`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const refreshToken = state.user.user?.data?.accessTokenDto?.refreshToken;

      const data = {
        value: refreshToken,
      };

      const res = await fansupportPrivate.post(`identity/sign-out`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }

      // localStorage.removeItem("user"); // Remove user data from local storage
      return null; // Logout successful
    } catch (error) {
      console.error("Logout error:", error);
      return rejectWithValue(error.toString());
    }
  }
);

export const deleteUser = createAsyncThunk(
  `${modulePrefix}/deleteUser`,
  async (_, { getState }) => {
    const state = getState();

    const res = await fansupportPrivate.delete(`identity/delete`, {
      headers: { authorization: `Bearer ${state.user.user?.data?.accessTokenDto?.accessToken}` },
    });

    return res.data;
  }
);

export const register = createAsyncThunk(
  `${modulePrefix}/register`,
  async ({ 
      username,
      firstname,
      lastname,
      email,
      birthday,
      countryId,
      phoneCode,    
      phoneNumber,
      genderId,
      password,
      passwordConfirmation,
      isTIOM,
      isKvKK,}, { getState, rejectWithValue }) => {
    try {
      const res = await fansupportPublic.post("identity/register", {
        username,
        firstname,
        lastname,
        email,
        birthday,
        countryId,
        phoneCode,    
        phoneNumber,
        genderId,
        password,
        passwordConfirmation,
        isTIOM,
        isKvKK,
      });

      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      return res.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);
export const verifyPhone = createAsyncThunk(
  `${modulePrefix}/verifyPhone`,
  async ( otp , { getState, rejectWithValue }) => {
    try {
      const res = await fansupportPublic.post("identity/verify-phone", {
        value: otp
      } );

      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      // console.log(otp);
      // console.log(res.data);
      return res.data;      
    }
    catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const resendSms = createAsyncThunk(
  `${modulePrefix}/resendSms`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      // console.log("state:", state);
      const { phoneCode, phoneNumber } = state.user.user?.data;
      // console.log("phoneCode:", phoneCode);
      // console.log("phoneNumber:", phoneNumber);

      const res = await fansupportPrivate.post(`identity/resend-sms`, {
        phoneCode,
        phoneNumber,
      });

      if (!res.data?.isSuccess) {
        return rejectWithValue(res.data.message);
      }
      // console.log(res.data); 
      return res.data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const isExist = createAsyncThunk(
  `$(modulePrefix)/isExist`,
  async ({
    username,
    email,
    phoneCode,
    phoneNumber,}, { rejectWithValue }) => {
      try {
        const res = await fansupportPublic.post("identity/is-exist", {
          username,
          email,
          phoneCode,
          phoneNumber,
        });
  
        if (!res.data?.isSuccess) {
          return rejectWithValue(res.data.message);
        }
        // console.log(res.data);
        return res.data;
      } catch (error) {
        return rejectWithValue(error.toString());
      }
    }
); 

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      .addCase(login.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          localStorage.setItem("user", JSON.stringify(action.payload));
          state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload;
      })

      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      .addCase(register.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          // state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload;
      })

      .addCase(verifyPhone.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      .addCase(verifyPhone.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          state.phoneVerificationData = action.payload;
      })
      .addCase(verifyPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload; 
      })

      .addCase(isExist.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      .addCase(isExist.fulfilled,(state, action) => {
          state.loading = false;
          state.success = true;
          state.isExistData = action.payload;
      })
      .addCase(isExist.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload; 
      })
      
      
      .addCase(logout.fulfilled, (state) => {
        localStorage.removeItem("user");
        state.user = null;
        state.success = false;
        state.error = false;
      })

      .addCase(deleteUser.pending, (state) => {
        state.success = false;
        state.error = false;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.success = true;
        localStorage.removeItem("user");
        state.user = null;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.error = true;
      })
  },
});

export default userSlice.reducer;