import { fansupportPrivate } from "../index";

export const postChangePassword = async ( currentPassword, newPassword, confirmPassword ) => {
    //  console.log("payload", currentPassword, newPassword, confirmPassword);
    try {
        const data =  fansupportPrivate.post('/identity/change-password', {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
    })
        return data;

    } catch (error) {
        console.log("postCampaignError",error);
    }
}