import { fansupportPublic } from "../index";

export const getCampaign = async ({ id, campaignType, sportId, countryId, tournamentId, teamId, organisationId, status, sort, page, perPage }) => {
    return fansupportPublic.get('/campaign/campaigns', {params: {
        id,
        campaignType,
        sportId, 
        countryId,
        tournamentId,
        teamId,
        organisationId,
        status,
        sort,
        page,
        perPage,
    }})
}