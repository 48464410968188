import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Carousels,
  RssCarousel,
  CampaignsCarousel,
  CampaignNumbersCard,
  CampaignCard,
  CampaignCompetitionCard,
  CampaignGeneralSuccess,
  CampaignTransfer,
  CampaignDonorsCard,
  CampaignOrganisationsCard,
  SEO,
  RecommendedCarousel,
  AdImageCard,
  AdsCard,
  PollCard
} from "../../components";
import announcement from "../../assets/announcement.svg";
import conditionalTrophy from "../../assets/conditional-banner/static-trophy.svg";
import conditionalTransfer from "../../assets/conditional-banner/static-transfer.svg";
import { getCampaignInNumbers } from "../../services/campaign/campaignInNumbersService";
import { useSelector } from 'react-redux';
import { getCampaignOrganisations } from "../../services/campaign/campaignOrganisations";
import NotFound from "../not-found/NotFound";
import { getSportTopOneHundred } from "../../services/campaign/sportTopOneHundredService";
import { getCampaign } from "../../services/campaign/campaignService";
import { selectIsAuthenticated } from "../../redux/features/auth/authSlice";
import { getAds } from "../../services/ads/adsService";
import AdsLocation from "../../enums/AdsLocation";
import LanguageTag from "../../enums/LanguageTag";
import MediaSize from "../../enums/MediaSize";
import { Skeleton } from "@mantine/core";
// import { getCampaignThunk } from "../../redux/features/filter/filterSlice";


const sportTypes = {
  '6498b1e91d6341510544c3a7': 'football',
  '6498b1e91d6341510544c3a8': 'basketball',
  '6498b1e91d6341510544c3a9': 'volleyball',
};

const getSportTitle = (sportId) => {
  return sportTypes[sportId] || "Genel";
};

const fetchAdsData = async (location, countryId, languageId) => {
  try {
    const platformType = 1;
    const res = await getAds({ countryId, languageId, platformType, location });
    return res?.data?.data || [];
  } catch (error) {
    console.log("Ads Error:", error);
    return [];
  }
};


const Home = () => {

  const [eventCampaign, setEventCampaign] = useState();
  const [championCampaign, setChampionCampaign] = useState();
  const [scoreCampaign, setScoreCampaign] = useState();
  const [transferCampaign, setTransferCampaign] = useState();
  const [campaignNumbersData, setCampaignNumbersData] = useState({});
  const [sportTopOneHundred, setSportTopOneHundred] = useState();
  const [socialData, setSocialData] = useState();
  const [social, setSocial] = useState();
  const [adsData, setAdsData] = useState([]);
  const [adsRight, setAdsRight] = useState([]);
  const [adsTop, setAdsTop] = useState();
  const [adsCenter, setAdsCenter] = useState();
  const [adsBottom, setAdsBottom] = useState([]);
  const [localCountryId, setLocalCountryId] = useState(localStorage.getItem("startCountryId"));
    // Individual loading states
  const [loadingAdsLeft, setLoadingAdsLeft] = useState(true);
  const [loadingAdsRight, setLoadingAdsRight] = useState(true);
  const [loadingAdsTop, setLoadingAdsTop] = useState(true);
  const [loadingAdsCenter, setLoadingAdsCenter] = useState(true);
  const [loadingAdsBottom, setLoadingAdsBottom] = useState(true);
  const [loadingEventCampaign, setLoadingEventCampaign] = useState(true);
  const [loadingChampionCampaign, setLoadingChampionCampaign] = useState(true);
  const [loadingScoreCampaign, setLoadingScoreCampaign] = useState(true);
  const [loadingTransferCampaign, setLoadingTransferCampaign] = useState(true);
  const [loadingCampaignNumbers, setLoadingCampaignNumbers] = useState(true);
  const [loadingSportTopOneHundred, setLoadingSportTopOneHundred] = useState(true);
  const [loadingSocialData, setLoadingSocialData] = useState(true);



  const { t } = useTranslation();

  const filterRss = useSelector((state) => state.filter.filterRss);
  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedCountryId = useSelector(state => state.filter.countryId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedTeamId = useSelector((state) => state.filter.teamId);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const seeAllTextSuccess = `${t("all")} >`;

  const storedLanguageId = localStorage.getItem("languageId");
  const originalLanguage = navigator.language;
  const newLanguage = originalLanguage.split('-')[0];
  const language = Object.keys(LanguageTag).find(
    (key) => LanguageTag[key] === newLanguage
  );
  const languageId = storedLanguageId ? storedLanguageId : language;

  const fetchData = useCallback(async () => {
  const countryId = selectedCountryId === "" ? localCountryId : selectedCountryId;

    setLoadingAdsLeft(true);
    setLoadingAdsRight(true);
    setLoadingAdsTop(true);
    setLoadingAdsCenter(true);
    setLoadingAdsBottom(true);
    setLoadingEventCampaign(true);
    setLoadingChampionCampaign(true);
    setLoadingScoreCampaign(true);
    setLoadingTransferCampaign(true);
    setLoadingCampaignNumbers(true);
    setLoadingSportTopOneHundred(true);
    setLoadingSocialData(true);

    try {
    // Fetch all ads in parallel
    const [adsLeft, adsRight, adsTop, adsCenter, adsBottom] = await Promise.all([
      fetchAdsData(AdsLocation['WEB_LEFT'], countryId, languageId),
      fetchAdsData(AdsLocation['WEB_RIGHT'], countryId, languageId),
      fetchAdsData(AdsLocation['WEB_TOP'], countryId, languageId),
      fetchAdsData(AdsLocation['WEB_CENTER'], countryId, languageId),
      fetchAdsData(AdsLocation['WEB_BOTTOM'], countryId, languageId)
    ]);

    setAdsData(adsLeft);
    setAdsRight(adsRight);
    setAdsTop(adsTop);
    setAdsCenter(adsCenter);
    setAdsBottom(adsBottom);
    setLoadingAdsLeft(false);
    setLoadingAdsRight(false);
    setLoadingAdsTop(false);
    setLoadingAdsCenter(false);
    setLoadingAdsBottom(false);

    // Fetch campaigns and other data
    const [
      campaignNumbers,
      sportTop100,
      campaignOrgs,
      campaignOrgsDetail,
      eventCampaigns,
      championCampaigns,
      scoreCampaigns,
      transferCampaigns
    ] = await Promise.all([
      getCampaignInNumbers({ sportId: selectedSportId }),
      getSportTopOneHundred({ sportId: selectedSportId, countryId, tournamentId: selectedTournamentId, teamId: selectedTeamId }),
      getCampaignOrganisations(),
      getCampaign({ campaignType: 6, organisationId: "" }),
      getCampaign({ campaignType: 1, sportId: selectedSportId, countryId: selectedCountryId, tournamentId: selectedTournamentId, teamId: selectedTeamId, status: 1, sort: "ASC", page: 1, perPage: 5 }),
      getCampaign({ campaignType: 2, sportId: selectedSportId, countryId: selectedCountryId, tournamentId: selectedTournamentId, teamId: selectedTeamId, status: 1, sort: "ASC", page: 1, perPage: 3 }),
      getCampaign({ campaignType: 3, sportId: selectedSportId, countryId: selectedCountryId, tournamentId: selectedTournamentId, teamId: selectedTeamId, status: 1, sort: "ASC", page: 1, perPage: 2 }),
      getCampaign({ campaignType: 0, sportId: selectedSportId, countryId: selectedCountryId, tournamentId: selectedTournamentId, teamId: selectedTeamId, status: 1, sort: "ASC", page: 1, perPage: 5 })
    ]);

    setCampaignNumbersData(campaignNumbers?.data?.data || []);
    setLoadingCampaignNumbers(false);
    
    setSportTopOneHundred(sportTop100?.data?.data?.top100 || []);
    setLoadingSportTopOneHundred(false);

    setSocialData(campaignOrgs?.data?.data || []);
    setSocial(campaignOrgsDetail?.data?.data?.data || []);
    setLoadingSocialData(false);

    setEventCampaign(eventCampaigns?.data?.data?.data || []);
    setLoadingEventCampaign(false);

    setChampionCampaign(championCampaigns?.data?.data?.data || []);
    setLoadingChampionCampaign(false);

    setScoreCampaign(scoreCampaigns?.data?.data?.data || []);
    setLoadingScoreCampaign(false);

    setTransferCampaign(transferCampaigns?.data?.data?.data || []);
    setLoadingTransferCampaign(false);

    } catch (error) {
      console.error("Error fetching data", error);
      setLoadingAdsLeft(false);
      setLoadingAdsRight(false);
      setLoadingAdsTop(false);
      setLoadingAdsCenter(false);
      setLoadingAdsBottom(false);
      setLoadingCampaignNumbers(false);
      setLoadingSportTopOneHundred(false);
      setLoadingSocialData(false);
      setLoadingEventCampaign(false);
      setLoadingChampionCampaign(false);
      setLoadingScoreCampaign(false);
      setLoadingTransferCampaign(false);
    }
  }, [selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId, localCountryId, languageId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
      <>
        <div>
          <SEO
            title={`${localStorage.getItem("selectedTournamentName") ? localStorage.getItem("selectedTournamentName") + " " + t("tournamentMetaTitle") : t("homeMetaTitle")}`}
            description={`${localStorage.getItem("selectedTournamentName") ? localStorage.getItem("selectedTournamentName") + " " + t("tournamentMetaDescription") : t("homeMetaDescription")}`}
            keywords={`${localStorage.getItem("selectedTournamentName") ? localStorage.getItem("selectedTournamentName") + " " + t("tournamentMetaKeywords") : t("homeMetaKeywords")}`}
            url="https://www.fansupport.com/"
          />
        </div>
        <FootballHomeMain className="home-main">       
          <Carousels />

          {loadingAdsTop ? (
            <Skeleton width="1100px" style={{ margin: '15px auto' }} height={150} radius="md" animate />
          ) : (
            adsTop && adsTop.length > 0 && (
              <AdsContaniner>
                <AdsCard ads={adsTop} />
              </AdsContaniner>
            )
          )}

          <CampaignsCarouselSection>

          <CampaignsCarouselContainer>
              <CampaignsCarousel />
          </CampaignsCarouselContainer>


          <CampaignNumbersCard
              title={t("campaignsFigures")}
              campaignNumbers={campaignNumbersData}
              loading={loadingCampaignNumbers}
          />

          </CampaignsCarouselSection>

          <PollCard useDialog={true} />
        
          {adsData ? 
            <>
              <AdImageCard loading={loadingAdsLeft} adsData={adsData} scrollValue={adsTop ? 900 : 750} left="0" />
            </> : ""
          }                    
          {adsRight ? 
            <>
              <AdImageCard loading={loadingAdsRight} adsData={adsRight} scrollValue={adsTop ? 900 : 750} right="0" />
            </> : ""
          }                         

          <CampaignCardContainer>
          
            <CampaignCardContent>

              <CampaignCard
                icon={conditionalTrophy}
                title={t("eventCampaigns")}
                seeAllTextEvent={seeAllTextSuccess}
                campaignType={1}
                campaignTypeText="eventCampaign"
                responsiveWidth="450px"
                responsiveXXLWidth="300px"
                responsiveXLWidth="280px"
                responsiveLargeWidth="250px"
                responsiveMediumWidth="220px"
                responsiveSmWidth="200px"
                contentHeight="550px"
                cardJustify="start"
                cardGap="10px"
                loading={loadingEventCampaign}
                children={eventCampaign?.map((campaignData) => (
                <CampaignCompetitionCard
                    key={campaignData.id}
                    campaignData={campaignData}
                />
                ))}
              />
                

              <CampaignCard
              icon="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/29acdf0f-c1c9-42ef-d75d-1b10ef217d00/original"
              title={t("overallSuccessCampaigns")}
              seeAllTextSuccess={seeAllTextSuccess}
              campaignType={2}
              campaignTypeText="overallSuccessCampaign"
              responsiveWidth="450px"
              responsiveXXLWidth="300px"
              responsiveXLWidth="280px"
              responsiveLargeWidth="250px"
              responsiveMediumWidth="220px"
              responsiveSmWidth="200px"
              contentHeight="550px"
              cardGap="30px"
              loading={loadingChampionCampaign}
              >
              {scoreCampaign?.length === 0 && championCampaign?.length === 0 && (
                <CardContent className='campaign-card-content'>
                  <NotFound text={t("notFoundCampaigns")} />
                </CardContent>
              )}

              {scoreCampaign?.map((championData) => (
                <CampaignGeneralSuccess
                  key={championData.id}
                  campaignData={championData}
                />
              ))}

              {championCampaign?.map((championData) => (
                <CampaignGeneralSuccess
                  key={championData.id}
                  campaignData={championData}
                />
              ))}
              </CampaignCard>


              <CampaignCard
              icon={conditionalTransfer}
              title={t("transferCampaigns")}
              seeAllTextTransfer={seeAllTextSuccess}
              campaignType={0}
              campaignTypeText="transferCampaign"
              responsiveWidth="450px"
              responsiveXXLWidth="300px"
              responsiveXLWidth="280px"
              responsiveLargeWidth="250px"
              responsiveMediumWidth="220px"
              responsiveSmWidth="200px"
              contentHeight="550px"
              cardGap="30px"
              loading={loadingTransferCampaign}
              children={transferCampaign?.map((transferData) => (
                  <CampaignTransfer
                  key={transferData.id}
                  campaignData={transferData}
                  />
              ))}
              />
                
            </CampaignCardContent>

          </CampaignCardContainer>

          { isAuthenticated &&  

          <RecommendedCarouselContainer>
            
            <RecommendedTitle className='recommended-carousel-title'>{t("recommendedCampaigns")}</RecommendedTitle>
            
            <RecommendedCarousel/>

          </RecommendedCarouselContainer>  
          
          }

          {loadingAdsCenter ? (
            <Skeleton width="1100px" style={{ margin: '15px auto' }} height={150} radius="md" animate />
          ) : (
            adsCenter && adsCenter.length > 0 && (
              <AdsContaniner>
                <AdsCard ads={adsCenter} />
              </AdsContaniner>
            )
          )}

          <CampaignDonorsContainer>
            <CampaignDonorsContent>

              <CampaignCard
              icon={announcement}
              darkIcon={"icon-dark"}
              title={`${t(getSportTitle(selectedSportId))} ${t("campaignDonors")}`}
              desc={t("top100List")}
              cardWidth="550px"
              responsiveWidth="auto"
              responsiveSmallWidth="340px"
              responsiveDonorList="75%"
              cardHeight="none"
              contentHeight="480px"
              cardPadding="10px"
              overflow="auto"
              overflowX="hidden"
              loading={loadingSportTopOneHundred}
              children={
                sportTopOneHundred?.length > 0 ? sportTopOneHundred?.map(({ id, username, amount }, index) => (
                  <CampaignDonorsCard
                  key={index}
                  id={index}
                  username={username}
                  amount={amount}
                  />
                  )): (
                  <NotFound text="Campaign not found.." />
                  )
              }
              />
            <CampaignDonorsContentRight id="media-content">
              <CampaignCard
              display="block"
              icon={announcement}
              darkIcon={"icon-dark"}
              title={t("socialSupportCampaign")}
              seeAllText=""
              cardWidth="auto"
              responsiveWidth="auto"
              responsiveDonorList="auto"
              responsiveSmallWidth="auto"
              cardHeight="none"
              contentHeight="180px"
              cardPadding="15px"
              overflow="auto"
              overflowX="hidden"
              loading={loadingSocialData}
              contents={ social && social.length > 0 &&
                  <CampaignOrganisationsCard
                  organisationsData={socialData}
                  social={social}
                  />
              }
              />

              <PollCard useDialog={false} />

            </CampaignDonorsContentRight>
            
            </CampaignDonorsContent>
          </CampaignDonorsContainer>

          {loadingAdsBottom ? (
            <Skeleton width="1100px" style={{ margin: '0px auto' }} height={300} radius="md" animate />
          ) : (
            adsBottom && adsBottom.length > 0 && (
              <AdsContaniner>
                <AdsCard ads={adsBottom} adsHeight="300px" />
              </AdsContaniner>
            )
          )}      

          {filterRss && filterRss.length > 0 &&
            <NewsCarouselSection className="rss-carousel-section">
              <RssCarousel data={filterRss} />
            </NewsCarouselSection>
          }       

        </FootballHomeMain>
      </>
  )
};

export default Home;

export const FootballHomeMain = styled.main`
  background-color: #FFFFF;
`;

export const CampaignsCarouselSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
export const CampaignsCarouselContainer = styled.div`
  width: 100%;
  // background-color: white;
  border-radius: 15px;
  margin: 0 10px;

  @media (min-width: ${MediaSize.medium}) {
    width: 75%; /* Adjust the width for medium screens */
    margin: 0 auto;
  }

  @media (min-width: ${MediaSize.large}) {
    width: 70%; /* Adjust the width for large screens */
  }
`;

export const CardTitle = styled.div`
  color: orange;
  padding: 50px 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

export const CampaignCardContainer = styled.section`
  width: 70%;
  margin: 0px auto;

  // @media (max-width: 720px) {
  //   width: 86%;
  // }

  @media screen and (min-width: 1220px) and (max-width: 1320px) {
    width: 68%;
    margin: 0px auto;
  }
  @media screen and (min-width: 1024px) and (max-width: 1220px) {
    width: 65%;
    margin: 0px auto;
  }

  // @media screen and (min-width: 900px) and (max-width: 1024px) {
  //   width: 62%;
  //   margin: 10px auto;
  // }

  // @media screen and (min-width: 1024px) and (max-width: 1170px) {
  //   width: 62%;
  //   margin: 10px auto; 
  // }
`;
export const CampaignCardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  max-width: 1100px;
  margin: 50px auto;

  @media screen and (min-width: 300px) and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 1100px;
    margin: 50px auto;
  }

  // @media (max-width: 1506px) {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: center;
  //   align-items: center;
  //   gap: 30px;
  // }
  @media screen and (min-width: 1506px) and (max-width: 1514px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const NewsCarouselSection = styled.section`
  background-color: white;
  border-radius: 15px;
  margin: 100px 20px;

  @media (max-width: 900px){
    display: none;
  }
`;

export const CampaignDonorsContainer = styled.section`
  width: 70%;
  margin: 0px auto;

  @media (max-width: 720px) {
    width: 100%;
  }

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    width: 62%;
    margin: 10px auto;
  }

  @media screen and (min-width: 1024px) and (max-width: 1170px) {
    width: 55%;
    margin: 10px auto; 
  }

  @media screen and (min-width: 1440px) {
    width: 70%;
  }
`;
export const CampaignDonorsContent= styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 50px auto;

  @media (max-width: 720px) {
    gap: 30px;
  }

  @media (max-width: 1506px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 1486px) {
    
  }
`;

export const CampaignDonorsContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;
  width: 450px;

  @media (max-width: 720px) {
    width: 340px;
  }
  // @media (max-width: 1486px) {
  //   width: 78%;
  //   margin-top: 20px;
  // }
`;

export const RecommendedCarouselContainer = styled.section`
  max-width: 1100px;
  margin: 90px auto;
`;

export const RecommendedTitle = styled.h1`
  max-width: 600px;
  padding: 0;
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  transition: all 0.4s ease;

  text-align: center;
  font-size: 30px;
  font-weight: 300;
  color: #222;
  letter-spacing: 1px;
  // text-transform: uppercase;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;

  &:after,
  &:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #c50000;
    border-top: 1px solid #c50000;
    height: 5px;
    background-color: #f8f8f8;
  }
`;

export const AdsContaniner = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1100px;
  margin: 30px auto;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    max-width: 600px;
    margin: 30px auto;
  }
`;
export const CardContent = styled.div`
display: ${props => props.display || 'flex'};
flex-direction: column;
justify-content: ${props => props.cardJustify || 'start'};
justify-content: ${(props) => (props.children && props.children.length > 0 ? props.cardJustify : 'center')};
gap: ${props => props.cardGap || '10px'};
height: ${props => props.contentHeight || '550px'};
max-height: ${props => props.contentHeight || '550px'};
overflow: ${props => props.overflow || 'none'};
overflow-x: ${props => props.overflowX || 'none'};
background-color: white;

/* Width and height of the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  border-radius: 15px;
}

/* Background color of the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Color of the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Color of the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
`;