const AdsLocation = {
    'MOBILE_TOP': 0,
    'MOBILE_CENTER': 1,
    'MOBILE_BOTTOM': 2,
    'WEB_TOP': 3,
    'WEB_CENTER': 4,
    'WEB_BOTTOM': 5,
    'WEB_LEFT': 6,
    'WEB_RIGHT': 7 
}

export default AdsLocation;