import { fansupportPrivate } from "../index";

export const getDeleteFavoriteTeam = async ({ teamId, tournamentId }) => {
    try {
        const data = fansupportPrivate.delete('/sport/delete-favorite-team', {data: {
            teamId,
            tournamentId,
        }}) 
        return data;

    } catch (error) {
        console.log("getDeleteFavoriteTeam",error)
    }
}