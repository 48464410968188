import { fansupportPrivate } from "./index";

export const getFavoriteTeams = async ({ sportId, page, perPage }) => {
    try {
        return fansupportPrivate.get('/sport/favorite-teams', {params: {
            sportId,
            page,
            perPage,
        }})
        return DataTransfer;
    } catch (error) {
        console.log("getFavoriteTeams",error)
    }
} 