import React, { useEffect, useState } from "react";
import { Stepper, Button, Group, PasswordInput } from "@mantine/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";  

// import { IoMdArrowBack } from "react-icons/io";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { register, verifyPhone, resendSms, login, isExist } from "../../redux/features/auth/authSlice";
import { Loader, RegisterInput, RegisterPassword, GeneralButton } from "../../components";
import { getUsername } from "../../services/identity/checkUsernameService";
// import { getIsExist } from "../../services/identity/isExistService";
import userSchema from "./RegisterValidation";
import { getDetailedCountries } from "../../services/sport/detailedCountryService";
import VerifyPhoneCheck from "../../components/verify-phone/VerifyPhoneCheck";
import { getAllCountry } from "../../services/sport/AllCountryService";
import { getStartLanguage } from "../../logic/getStartLanguage";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
// import VerifyPhone from "../../components/verify-phone/VerifyPhone";


const Register = () => {
  const dispatch = useDispatch();

  const [showRegisterSpan, setShowRegisterSpan] = useState(true);
  const [countryData, setCountryData] = useState();
  const [usernameData, setUsernameData] = useState();
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const { loading, error, errorMessage, isExistData } = useSelector((state) => state.user);

  const { t } = useTranslation();
  const startLanguage = getStartLanguage();
  
  const isExistCheck = isExistData?.data?.isExist;
  const isExistCheckMesaage = isExistData?.data?.message; 

  const [active, setActive] = useState(0);

  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [otp, setOtp] = useState(null);
  

  const formik = useFormik({
    initialValues: {
      username: "",
      firstname: "",
      lastname: "",
      email: "",
      birthday: "",
      countryId: "",
      phoneCode: "",    
      phoneNumber: "",
      genderId: 2,
      password: "",
      passwordConfirmation: "",
      isTIOM: false,
      isKvKK: false,
    },
    onSubmit: (values) => {
      // console.log("Form gönderildi");
      values.phoneCode = values.phoneCode.replace(' ', '');
      values.phoneNumber = values.phoneNumber.replace(values.phoneCode, '').replace(/\s/g, '');
      // console.log("SplitForm",values);
      const { username, email, phoneCode, phoneNumber } = values;
      // console.log(username, email, phoneCode, phoneNumber );
      
      dispatch(isExist({ username, email, phoneCode, phoneNumber }));
      if(!isExistCheck) {
      dispatch(register(values));
      setShowRegisterSpan(false);
      nextStep();
      window.scrollTo(0, 0);
      }
    },
    validationSchema: userSchema(t),
    
  });
  // console.log(formik.values);

  // const user  = useSelector((state) => state.user);

  // console.log("user",user?.user?.data?.accessTokenDto);
  
  const handlePhoneChange = (value, country, event, formattedValue) => {
    formik.setFieldValue('phoneCode', "+" + country.dialCode);
    formik.setFieldValue('phoneNumber', formattedValue);
  };

  const handleVerifyPhone = () => {

    dispatch(verifyPhone(`${otp}`))

    const { username, password } = formik.values;
    // console.log(username,password);
    dispatch(login({ username, password }))
    nextStep();     
  };
  

  const handleResend = () => {
    // console.log("resend click"); 
    dispatch(resendSms());
  };
  
 
  const fetchCountry = async () => {
    try {
      const res = await getAllCountry();
      const sortedCountryData = res?.data?.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setCountryData(sortedCountryData  || {});
    } catch (error) {
      console.log("Country", error);
    }
  }

  const fetchUsername = async (inputUsername) => {
    const res = await getUsername({ Value: inputUsername });
    return res.data?.data;
  };


  useEffect(() => {
    fetchCountry();
    if (formik.values.username) {
      fetchUsername(formik.values.username).then(data => {
        setUsernameData(data);
      });
    }
    setPasswordsMatch(
      formik.values.password === formik.values.passwordConfirmation
    );
  }, [formik.values.username, formik.values.password, formik.values.passwordConfirmation, isExistCheck]);

  return (
    <>
      <AuthContainer>
        <Card>
          <AuthContainerForm>
            <Stepper
              size="xs"
              active={active}
              onStepClick={setActive}
              breakpoint="xs"
              allowNextStepsSelect={false}
              padding="0px"
            >
              <Stepper.Step
                label={t("personalInformation")}
                description={t("createAccount")}
                className="footer-title"
              >
                <RegisterContainer>

                  <RegisterForm onSubmit={formik.handleSubmit} className="register-card">

                    <RegisterTitle>{t("register")}</RegisterTitle>

                    <RegisterInputContainer>

                    <RegisterInput
                      id="username-input"
                      type="text"
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      label={t("username")}
                      style={{
                        borderColor: usernameData === true ? "red" : null, // Hata durumunda kenarlık rengini kırmızı yap
                      }}
                    />
                    {
                    usernameData === true 
                    ? 
                    <div style={{ color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "10px", marginTop: "0px" }}>
                        {t("youCannotUsername")}</div> 
                    : ""
                    }

                    {formik.touched.username && (
                      <div style={{ color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "10px"}}>
                        {formik.errors.username ||
                          (usernameData === true && t("enterNewUsername"))}
                      </div>
                    )}

                    <RegisterInput
                      id="firstname-input"
                      type="text"
                      name="firstname"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      label={t("name")}
                    />
                    {formik.errors.firstname && formik.submitCount > 0 && (
                      <div style={{color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "10px"}}>{formik.errors.firstname}</div>
                    )}
                    <RegisterInput
                      id="lastname-input" 
                      name="lastname"
                      type="text"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      label={t("surname")}
                    />
                    {formik.errors.lastname && formik.submitCount > 0 && (
                      <div style={{color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "10px"}}>{formik.errors.lastname}</div>
                    )}
                    <RegisterCountry                           
                      id="countryId" 
                      name="countryId"
                      type="text"
                      value={formik.values.countryId}
                      onChange={formik.handleChange}
                    >
                      <PlaceholderOption value="" disabled={!formik.values.countryId} hidden>
                        {formik.values.countryId ? "" : t("selectCountry")}
                      </PlaceholderOption>
                      {countryData?.map((list) => (
                        <option 
                          value={list.id} 
                          key={list.id}
                          style={{color: "black", padding: "10px"}}
                        >
                          {list.name} 
                        </option>
                      ))}
                    </RegisterCountry>

                    <PhoneInputContainer>
                      <PhoneInput
                        id="phoneNumber" 
                        name="phoneNumber"
                        country={"tr"}
                        enableSearch={true}
                        value={formik.values.phoneNumber}
                        onChange={handlePhoneChange}
                        containerStyle={{ margin: "10px 0" }}
                        inputStyle={{ width: "100%", borderRadius: "5px" }}
                      />
                    </PhoneInputContainer>
                    {formik.errors.phoneNumber && formik.submitCount > 0 && (
                      <div style={{color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "10px"}}>{formik.errors.phoneNumber}</div>
                    )}

                    <RegisterInput
                      id="email-input" 
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      label={t("yourEmailAddress")}
                    />
                    {formik.errors.email && (
                      <div style={{color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "10px"}}>{formik.errors.email}</div>
                    )}

                    <RegisterInput
                      id="birthday-input" 
                      name="birthday"
                      type="date"
                      value={formik.values.birthday}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.birthday && (
                      <div style={{color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "10px"}}>{formik.errors.birthday}</div>
                    )}

                    <RegisterGender                          
                      id="genderId" 
                      name="genderId"
                      type="text"
                      value={formik.values.genderId}
                      onChange={formik.handleChange}
                    >
                      <PlaceholderOption value="" disabled={!formik.values.genderId} hidden>
                        {formik.values.genderId ? "" : "Select a gender"}
                      </PlaceholderOption>
                      <option 
                        value="0" 
                        style={{color: "black", padding: "10px"}}
                      >
                        {t("male")}
                      </option>
                      <option 
                        value="1" 
                        style={{color: "black", padding: "10px"}}
                      >
                        {t("female")}
                      </option>
                      <option 
                        value="2" 
                        style={{color: "black", padding: "10px"}}
                      >
                        {t("dontWantSpecify")} 
                      </option>
                    </RegisterGender>    


                      <RegisterPassword
                        handleChange={formik.handleChange}                   
                        value={formik.values.password}
                      />
                      {formik.errors.password && formik.submitCount > 0 && (
                        <div style={{color: "red", fontSize: "13px", marginLeft: "5px"}}>{formik.errors.password}</div>
                      )}
                      <ConfirmationPasswordInput  
                        id="passwordConfirmation-input" 
                        name="passwordConfirmation"
                        placeholder={t("confirmPassword")}
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        size="lg"
                        my="lg"
                        error={passwordsMatch ? (
                          ""
                        ) : (
                          <ErrorMessage>{t("passwordsDontMatch")}</ErrorMessage>
                      )}
                      />

                      <RegisterAcceptTerms>
                        <RegisterAcceptTermsInput
                          id="isKvKK-input"
                          name="isKvKK"
                          type="checkbox"
                          value={formik.values.isKvKK}
                          onChange={formik.handleChange}
                        />
                        <RegisterAcceptTermsLabel htmlFor="isTIOM-input">
                          <StyledLink href="/help/kullanicisozlesmesi" target="_blank">{t("userAgreement")}</StyledLink> & 
                          <StyledLink href="/help/acikrizametni" target="_blank">{t("clearConsentText")}</StyledLink> {t("readAccept")}
                        </RegisterAcceptTermsLabel>
                      </RegisterAcceptTerms>

                      <RegisterAcceptKVKK>
                        <RegisterAcceptKVKKInput
                          id="isTIOM-input"
                          name="isTIOM"
                          type="checkbox"
                          checked={formik.values.isTIOM}
                          value={formik.values.isTIOM}
                          onChange={formik.handleChange}

                        />
                        <RegisterAcceptKVKKLabel htmlFor="isKvKK-input">                        
                          <StyledLink href="/help/tiom" target="_blank">{t("commercialElectronicMessageText")}</StyledLink> {t("readAccept")}
                        </RegisterAcceptKVKKLabel>                    
                      </RegisterAcceptKVKK>
                      <RegisterAcceptKVKKDesc>                          
                          <StyledLink href="/help/kvkk" target="_blank">{t("kvkkAndPrivacyPolicy")}</StyledLink> & 
                          <StyledLink href="/help/aydinlatmametni" target="_blank">{t("clarificationText")}</StyledLink> {t("youCanReachHere")}
                        </RegisterAcceptKVKKDesc>

                      {error && <p style={{ color: "red", marginLeft: "10px" }}>{errorMessage}</p>}
                      {isExistCheck   && <p style={{ color: "red", marginLeft: "5px" }}>{isExistCheckMesaage }</p>} 
                      {formik.errors.isKvKK && formik.submitCount > 0 && (
                        <div style={{color: "red", fontSize: "15px", margin: "15px", fontWeight: "bold"}}>{formik.errors.isKvKK}</div>
                      )}  
                      <GeneralButton 
                      type="submit"
                      width="100%"
                      margin="20px 0px"
                      >
                        {loading && (
                          <Loader size={10} color="grey" borderSize={3} />
                        )}
                        {t("register")}
                      </GeneralButton>
                    </RegisterInputContainer>

                  </RegisterForm>

                </RegisterContainer>
              </Stepper.Step>
              
              {/* <VerifyPhone
                otp={otp}
                setOtp={setOtp}
                handleVerifyPhone={handleVerifyPhone}
                handleResend={handleResend}
                loading={loading}
              /> */}


              {/* <VerifyPhoneCheck 
                prevStep={prevStep} 
                nextStep={nextStep} 
              /> */}

              <Stepper.Step
                label={t("membershipActivation")}
                description={t("emailVerification")}
                className="footer-title"
              >

              <VerificationContainer>

                <CircularBackground>
                  <CheckmarkIcon>
                    <CheckmarkPath d="M10 20 L15 25 L30 10" />
                  </CheckmarkIcon>
                </CircularBackground>
                    
                <StatusText className="footer-title">
                  {t("verificationMail")}
                </StatusText>

                <Group position="center" mt="xl">
                  <SaveButton to="/" >
                    <Button>{t("homePage")}</Button>
                  </SaveButton>
                </Group>

              </VerificationContainer>               
                
              </Stepper.Step>
            </Stepper>

            {showRegisterSpan && (
              <RegisterSpan className="register-span">
                <RegisterText>{t("alreadyHaveAccount")}</RegisterText>
                <RegisterLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`}>{t("login")}</RegisterLink>
              </RegisterSpan>
            )}
          </AuthContainerForm>
        </Card>

      </AuthContainer>
    </>
  );
};

export default Register;

export const AuthContainer = styled.section`
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @keyframes slide-up {
    0% {
      transform: translateY(-5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slide-down {
    0% {
      transform: translateY(5rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 700px) {
    .img {
      display: none;
    }
  }
`;

export const Card = styled.div`
  width: 600px;
  // height: 530px;
  border: 1px solid transparent;
  border-radius: 5px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  overflow: hidden;
`;
export const AuthContainerForm = styled.div`
  padding: 15px 0 15px 15px;
  animation: slide-up 1.5s ease;

  @media (max-width: 720px){
    padding: 15px;
  }
`;
export const RegisterTitle = styled.div`
  padding: 20px 0 25px 0;
  font-size: 26px;
  color: orange;
  text-align: center;
  font-weight: 600;
`;

export const RegisterContainer = styled.div`
  margin-top: 50px;
  max-width: 575px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
export const RegisterForm = styled.form`
input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="password"] {
    padding: 10px;
    margin-top: 10px;
    width: 96%;
    height: 32px;
    border-radius: 5px;
    outline: none;
  }
`;
export const RegisterInputContainer = styled.div`
  padding: 0 20px;
  // overflow: auto;
  // overflow-x: hidden;

  // ::-webkit-scrollbar {
  //   width: 5px;
  //   height: 10px;
  //   border-radius: 15px;
  // }

  // ::-webkit-scrollbar-track {
  //   background-color: #f1f1f1;
  // }

  // ::-webkit-scrollbar-thumb {
  //   background-color: #888;
  // }

  // ::-webkit-scrollbar-thumb:hover {
  //   background-color: #555;
  // }
`;

export const RegisterAcceptTerms = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const RegisterAcceptTermsInput = styled.input`
  width: 16px;
  height: 16px;
`;
export const RegisterAcceptTermsLabel = styled.label`
  font-size: 14px;
  margin-left: 8px;
`;
export const RegisterAcceptKVKK = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
export const RegisterAcceptKVKKInput = styled.input`
  width: 16px;
  height: 16px;
`;
export const RegisterAcceptKVKKLabel = styled.label`
  font-size: 14px;
  margin-left: 8px;
`;
export const RegisterAcceptKVKKDesc = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;
export const StyledLink = styled.a`
  color: blue;
  margin-left: 5px;
  text-decoration: none;
`;


export const RegisterSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 0;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;
export const RegisterText = styled.p`
  margin: 0;
  color: grey;
`;
export const RegisterLink = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  margin-left: 5px;
`;
export const SaveButton = styled(Link)`
  // background-color: #007bff;
  // color: #fff;
  // border: none;
  // padding: 10px 20px;
  // border-radius: 5px;
  // font-size: 16px;
  // text-decoration: none;

  // &:hover {
  //   background-color: #0056b3;
  // }
`;
// export const RegisterButton = styled.button`
//   width: 100%;
//   color: #fff;
//   background: #007bff;
//   height: 45px;
//   margin-top: 20px;
//   font-size: 17px;
//   border: 1px solid transparent;
//   border-radius: 3px;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: all 0.3s;
// `;













export const RegisterCountry = styled.select`
  width: 100%; 
  height: 50px;
  margin: 10px 0;
  padding: 0 20px 0 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 15px;
  color: ${({ value }) => (value ? "black" : "#999")};
`;

export const PlaceholderOption = styled.option`
  display: none;
`;

export const PhoneInputContainer = styled.div``;

export const RegisterGender = styled.select`
  width: 100%; 
  height: 50px;
  margin: 10px 0;
  padding: 0 20px 0 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 15px;
  color: ${({ value }) => (value ? "black" : "#999")};
`;

export const ErrorMessage = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: red;
`;
export const ConfirmationPasswordInput = styled(PasswordInput)`
  input::placeholder {
    font-size: 14px;
    color: #999; 
  }
`;


export const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 450px;
  // height: 250px;
  padding: 25px 15px;
  margin: 20px auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media (max-width: 720px){
    width: 100%;
    padding: 0px;
  }
`;



export const CheckmarkContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const drawCheckmark = keyframes`
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;






export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;



export const CircularBackground = styled.div`
  width: 100px;
  height: 100px;
  border: 6px solid #00cc00;
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 2s linear infinite;
`;
export const CheckmarkIcon = styled.svg`
  width: 40px;
  height: 40px;
`;

export const CheckmarkPath = styled.path`
  fill: none;
  stroke: #00cc00;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: ${drawCheckmark} 1s ease-in-out forwards;
`;
export const StatusText = styled.p`
  font-size: 17px;
  width: 350px;
  text-align: center;
  font-weight: 400;
`;