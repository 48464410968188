import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaLinkedin,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { 
  XIcon,
} from "react-share";
import { MdOutlineLocationOn, MdOutlineEmail } from "react-icons/md";
// import { IoDocumentTextOutline } from "react-icons/io5";
import { CgSun } from "react-icons/cg";
import { BsMoonStars } from "react-icons/bs";
// import { LanguageButton } from "../../components";
import { getDocumentTypes } from "../../services/document/documentTypesService";
import Tooltip from "../tooltip/Tooltip";
import { getStartLanguage } from "../../logic/getStartLanguage";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import LanguageTag from "../../enums/LanguageTag";


const date = new Date();
const year = date.getFullYear();

const Footer = ({ toggle, theme }) => {

  const {t} = useTranslation();
  const startLanguage = getStartLanguage();

  const [faq, setFaq] = useState([]);

  const fetchDocumentTypes = async () => {
    try {
      const storedLanguageId = localStorage.getItem("languageId");
      const originalLanguage = navigator.language;
      const newLanguage = originalLanguage.split('-')[0];
      const language = Object.keys(LanguageTag).find(
        (key) => LanguageTag[key] === newLanguage
      );
      const languageId = storedLanguageId ? storedLanguageId : language;

      const res = await getDocumentTypes({ languageId })
      setFaq(res?.data?.data);
    } catch (error) {
      console.error("Request failed with error:", error);
    }
  };
  // console.log("faq",faq);

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  const iconStyle = {
    color: "#3C4F51",
    marginRight: "10px",
  };
  const iconLightStyle = {
    color: "#3C4F51",
    marginRight: "10px",
  };
  const iconDarkStyle = {
    color: "white",
    marginLeft: "10px",
  };

  const newsFromUs = t("newsFromUs"); 
  const formattedNewsFromUs = RemoveTurkishChars({ text: newsFromUs }).replace(/ /g, "").toLowerCase();

  return (
    <>
      <FooterMain>

        <FooterContainer>

          <FooterGrid>

            <Footer1>

              <div>
                <FooterTitle className="footer-title">FAN SUPPORT</FooterTitle>
                <Footer1Ul>
                <OpenProfileLink to={`/${startLanguage}/${formattedNewsFromUs}`} >
                  <OpenProfileBox className="footer-news-from-us">
                    {/* <IoDocumentTextOutline size={20}/> */}
                    <OpenProfileA className="footer-news-from-us">{t("newsFromUs")}</OpenProfileA>
                  </OpenProfileBox>
                </OpenProfileLink>

                  {faq.map((faqs, index) => (
                    <Footer1Li key={index}>
                      <FooterLink 
                      to={`help/${faqs.type.toLowerCase()}`}
                      className="footer-news-from-us"
                      >
                        {faqs.title}
                      </FooterLink>
                    </Footer1Li>
                  ))}
                </Footer1Ul>
              </div>

              <CopyrightIcon>

                <div>

                  <FooterSocialMedia>
                    <FooterSocialMediaLink to="https://www.instagram.com/fansupport.tr/" target="_blank">
                      <FaInstagramSquare style={{ color: "#e4405f", marginRight: "10px" }} size={25} />
                    </FooterSocialMediaLink>
                    <FooterSocialMediaLink to="https://www.facebook.com/fansupport.tr" target="_blank">
                      <FaFacebookSquare style={{ color: "#1877f2", marginRight: "10px" }} size={25} />
                    </FooterSocialMediaLink>
                    <FooterSocialMediaLink to="https://twitter.com/fansupporttr" target="_blank">
                      <XIcon style={{ color: "#1da1f2", marginRight: "10px" }} size={25} />
                    </FooterSocialMediaLink>
                    <FooterSocialMediaLink to="https://www.linkedin.com/company/fansupport/" target="_blank">
                      <FaLinkedin style={{ color: "#0077b5", marginRight: "10px" }} size={25} />
                    </FooterSocialMediaLink>        
                  </FooterSocialMedia>

                  <Copyright>Copyright&copy; FanSupport {year}</Copyright>
                
                </div>

                <DarkThemeLanguageButton>
                  {/* <LanguageButton color="black" showIcon={true}/> */}
                  <div className="switch">
                    {/* <label style={{width: "50px"}}> {theme === "light" ? t("Light Mode") : "" } </label> */}
                    <label> {theme === "light" ? <CgSun style={iconLightStyle} size={25} /> : "" } </label> 
                    <label> {theme === "light" ? "" : <BsMoonStars style={iconDarkStyle} size={25} /> } </label>
                    <div>        
                      <ReactSwitch onChange={toggle} checked={theme === "dark"} />
                    </div>
                    
                    {/* <label style={{width: "50px", margin: "0"}}> {theme === "light" ? "" : t("Dark Mode") } </label> */}
                  </div>
                </DarkThemeLanguageButton>

              </CopyrightIcon>

            </Footer1>

            <Footer2>
              <FooterTitle className="footer-title">{t("contact")}</FooterTitle>

              <AddressFirst>
                <MdOutlineLocationOn size={25} />
                <AddressFirstTexts>
                  <AddressFirstText>
                    Halkalı Merkez Mah. DereboyuCad. No: 4/50
                  </AddressFirstText>
                  <AddressFirstText>
                    34303 Küçükçekmece/İstanbul
                  </AddressFirstText>
                </AddressFirstTexts>
              </AddressFirst>

              <AddressSecond>
                <MdOutlineLocationOn size={25} />
                <AddressSecondTexts>
                  <AddressSecondText>
                    Zaim Teknopark, Halkalı Merkez Mah.
                  </AddressSecondText>
                  <AddressSecondText>
                    Halkalı Cad. No: 281/23 Ofis No:54
                  </AddressSecondText>
                  <AddressSecondText>
                    34303 Küçükçekmece İstanbul
                  </AddressSecondText>
                </AddressSecondTexts>
              </AddressSecond>

              <AddressThird>
                <MdOutlineLocationOn size={25} />
                <AddressThirdTexts>
                  <AddressThirdText>Huobmattstrasse, 3</AddressThirdText>
                  <AddressThirdText>6045 Meggen Switzerland</AddressThirdText>
                </AddressThirdTexts>
              </AddressThird>

              <MailFirst>
                <MdOutlineEmail size={25} />
                <MailFirstText>info@fansupport.com</MailFirstText>
              </MailFirst>
            </Footer2>

            <Footer3>
              <Footer3ImageFrist src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/4b717d44-d941-4c96-45b3-738b07a95000/original" alt="sustainableGoals" />
              <Footer3ImageSecond src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/3d02fb73-3cbe-4181-b48f-ab321a4d6500/original" alt="ituZaim" />
              <Footer3ImageThird src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/72c12e94-eecc-4e81-7bb3-a13482fe7700/original" alt="forGoals" />
            </Footer3>

          </FooterGrid>          
          
        </FooterContainer>


      </FooterMain>
    </>
  );
};

export default Footer;

export const FooterMain = styled.footer`
  padding: 40px 60px;

  @media (max-width: 720px) {
    padding: 50px 10px;
  }
`;

export const FooterGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
    
  @media screen and (min-width: 900px) and (max-width: 1270px) {
    display: flex;
    justify-content: center;
    row-gap: 50px;
  }
  @media  (max-width: 900px) {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
`;

export const Footer1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 2.1;
  gap: 30px;

  @media (max-width: 720px) {
    line-height: 1.7;
    text-align: center;
    padding: 0;
    gap: 60px;
  }
`;
export const Footer2 = styled.div`
  max-width: 380px;
  padding-left: 70px;
  
  @media (max-width: 720px) {
    margin-top: 10%;
    padding: 0;
  }
`;

export const AddressFirst = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
`;

export const AddressFirstTexts = styled.div``;
export const AddressFirstText = styled.div`
  margin-bottom: 5px;
`;

export const AddressSecond = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
`;
export const AddressSecondTexts = styled.div``;
export const AddressSecondText = styled.div`
  margin-bottom: 5px;
`;

export const AddressThird = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
`;

export const AddressThirdTexts = styled.div``;
export const AddressThirdText = styled.div`
  margin-bottom: 5px;
`;

export const MailFirst = styled.div`
  display: flex;
  align-items: center;
`;
export const MailFirstText = styled.div`
  margin-left: 20px;
`;

export const Footer3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 40px;
  width: 250px;

  @media screen and (min-width: 1020px) and (max-width: 1270px) {
    display: flex;
    flex-direction: row;
    margin-right: 550px;
    row-gap: 50px;
  }
  @media screen and (min-width: 900px) and (max-width: 1020px) {
    display: flex;
    flex-direction: column;
  }
  @media  (max-width: 900px) {
    display: flex;
    row-gap: 50px;
  }
`;

export const FooterTitle = styled.div`
  font-size: 17px;
  color: black;
  margin-bottom: 40px;
  font-weight: bold;

  @media (max-width: 720px) {
    text-align: center;
  }
`;

export const Footer1Li = styled.li`
  list-style: none;
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
  font-family: inherit;
  color: grey;

  @media (max-width: 720px) {
    padding: 2px 0;
  }
`;

export const Footer1Ul = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 190px;
  margin: 0px;
  padding: 0px;

  @media (max-width: 720px) {
    max-height: none;
  }
`;

export const FooterLink = styled(Link)`
  margin-bottom: 20px;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  color: grey;

  &:hover {
    // color: #f6d13b;
    color: black;
    text-decoration: underline;
  }
`;

export const FooterContainer = styled.div`
  
`;

export const DarkThemeLanguageButton = styled.div`

`;

export const CopyrightIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const OpenProfileLink = styled(Link)`
  text-decoration: none;
`;

export const OpenProfileBox = styled.li`
  list-style: none;
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
  font-family: inherit;
  color: grey;

  &:hover {
    color: black;
    text-decoration: underline; 
  }
`;

export const OpenProfileA = styled.a`
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  color: grey;

    &:hover {
    color: black;
  }
`;

export const FooterSocialMedia = styled.div`
  display: flex;

  @media (max-width: 720px) {
    display: flex;
    justify-content: center;
  }
`;

export const FooterSocialMediaLink = styled(Link)`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const Copyright = styled.div`
  @media (max-width: 720px) {
    text-align: center;
  }
`;

export const Footer3ImageFrist = styled.img`
  width: 250px;
  height: auto;
`;
export const Footer3ImageSecond = styled.img`
  width: 250px;
  height: auto;
`;
export const Footer3ImageThird = styled.img`
  width: 250px;
  height: auto;
`;