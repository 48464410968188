import React, { useEffect, useState } from "react";
import { ChangeEmail, CurrencyFormat, DateTimeFormatter, GeneralButton, Loader, PollUserAnswers, RegisterInput, RegisterPassword } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { deleteUser } from "../../redux/features/auth/authSlice";
import styled from "styled-components";
import { useDisclosure } from '@mantine/hooks';
import { Modal, PasswordInput } from '@mantine/core';
import { useFormik } from "formik";
// import userSchema from "./RegisterValidation";
import { postChangePassword } from "../../services/identity/changePasswordService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserPoint } from "../../services/payment/userPointsService";
import { getUserDetails } from "../../services/identity/userDetailsService";
import { deleteUser } from "../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";


const Account = () => {
  const userData = useSelector((state) => state.user.user?.data);

  const [userPoints, setUserPoints] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [activeButton, setActiveButton] = useState("account");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState([]);
  const [changePasswordMessage, setChangePasswordMessage] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storedLanguage = localStorage.getItem("selectedLanguage");

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
    onSubmit: async (values) => {
      // console.log("Form gönderildi");

      // console.log("SplitForm",values);
      const { currentPassword, password, passwordConfirmation } = values;
      // console.log(currentPassword, password, passwordConfirmation );

      try {
        toast.dismiss();
        
        const res = await postChangePassword(currentPassword, password, passwordConfirmation);

        setChangePasswordData(res?.data?.isSuccess || false);
        setChangePasswordMessage(res?.data?.message || "")

        if (res?.data?.isSuccess === false) {
          toast.error(changePasswordMessage);
        } else if (changePasswordData) {
          toast.success(t("changedPassword"));
          // toast.success(changePasswordMessage);
        }

      } catch (error) {
        console.log("postChangePassword",error)
      }      
    },
    // validationSchema: userSchema(t),
    
  });
  // console.log("changePassword",changePasswordData);

  const handleDeleteUserModal = () => {
    open();
    setActiveButton("deleteAccount");
  }
  const handleDeleteUser = () => {
    dispatch(deleteUser());
    close();
    navigate("/")
  }

  const fetchUserPoints = async () => {

    try {
      const res = await getUserPoint();
      setUserPoints(res?.data?.data || []);
    } catch (error) {
      console.log("userPoints",error)
    }
  }

  const fetchUserDetails = async () => {

    try {
      const res = await getUserDetails();
      setUserDetails(res?.data?.data || {});
    } catch (error) {
      console.log("userPoints",error)
    }
  }

  const formattedDate = new Date(userDetails.birthday).toLocaleDateString("tr-TR");  

  useEffect(() => {
    setPasswordsMatch(
      formik.values.password === formik.values.passwordConfirmation
    );
    fetchUserPoints();
    fetchUserDetails();
  }, [changePasswordMessage, formik.values.password, formik.values.passwordConfirmation]);

  return (
    <>
      <ToastContainer/>
      <AccountMain className="account-main">

        <AccountSettingsBackground>    
          <AccountSettingsImg src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/f798c193-4b31-45dc-ac85-e68ed3691300/original" />
        </AccountSettingsBackground>

        <AccountSettingsTitle>{t("accountSettings")}</AccountSettingsTitle>

        {userDetails ? (
          <Container className="account-container">
            <UserInfoAndInfoSection>
              <UserInfo>

                <AvatarInfo>

                  <Avatar src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" alt="User Avatar" />

                  <NameInfo>
                    <FullName className="account-container">{`${userDetails.firstname} ${userDetails.lastname}`}</FullName>           
                    <Username className="account-container">{userDetails.username}</Username>
                  </NameInfo>

                </AvatarInfo>

                <UserInfoContent>
                  
                  <UserButtons>
                    <UserButton active={activeButton === "account"} onClick={() => setActiveButton("account")}>{t("account")}</UserButton>
                    <UserButton active={activeButton === "poll"} onClick={() => setActiveButton("poll")}>{t("pollUserAnswers")}</UserButton>
                    <UserButton active={activeButton === "changeEmail"} onClick={() => setActiveButton("changeEmail")}>{t("changeEmail")}</UserButton>
                    <UserButton active={activeButton === "changePassword"} onClick={() => setActiveButton("changePassword")}>{t("changePassword")}</UserButton>
                    <UserDeleteButton active={activeButton === "deleteAccount"} onClick={handleDeleteUserModal}>{t("deleteMyAccount")}</UserDeleteButton>
                  </UserButtons>

                </UserInfoContent>

              </UserInfo>

              <InfoSection>
                {activeButton === "account" && (

                  <InfoSectionContent>

                    <SectionTitle>{t("personalInformations")}</SectionTitle>

                    <InfoItemContainer>

                      <InfoItem>
                        <Label>{t("name")}:</Label>
                        <Value>{userDetails.firstname}</Value>
                      </InfoItem>
                      <InfoItem>
                        <Label>{t("surname")}:</Label>
                        <Value>{userDetails.lastname}</Value>
                      </InfoItem>
                      <InfoItem>
                        <Label>{t("birthDate")}:</Label>
                        <Value>{formattedDate}</Value>
                      </InfoItem>
                      <InfoItem>
                        <Label>{t("phoneNumber")}:</Label>
                        <Value>{userDetails.phoneNumber}</Value>
                      </InfoItem>                     
                      <InfoItem>
                        <Label>{t("emailAddress")}:</Label>
                        <Value>{userDetails.email}</Value>
                      </InfoItem>
                      <InfoItem>
                        <Label>{t("userScore")}:</Label>
                        <Value><CurrencyFormat amount={userPoints || 0}/></Value>
                      </InfoItem>

                    </InfoItemContainer>

                  </InfoSectionContent>

                )}
                {activeButton === "poll" && (

                  <InfoSectionContent>

                    <PollUserAnswers />

                  </InfoSectionContent>

                )}
                {activeButton === "changeEmail" && (
                  
                  <ChangeEmail data={userDetails.email} fetchUserDetails={fetchUserDetails} />
                  
                )}
                {activeButton === "changePassword" && (

                  <RegisterForm onSubmit={formik.handleSubmit}>
                    <RegisterTitle>{t("changePassword")}</RegisterTitle>

                      <CurrentPasswordInput
                        id="currentPassword-input"
                        name="currentPassword"
                        size="lg"
                        my="lg"
                        placeholder={t("currentPassword")}
                        onChange={formik.handleChange}                   
                        value={formik.values.currentPassword}
                      />
                      <RegisterPassword
                        handleChange={formik.handleChange}                   
                        value={formik.values.password}
                      />
                      {formik.errors.password && formik.submitCount > 0 && (
                        <div style={{color: "red", fontSize: "13px", marginLeft: "5px"}}>{formik.errors.password}</div>
                      )}
                      <ConfirmationPasswordInput  
                        id="passwordConfirmation-input" 
                        name="passwordConfirmation"
                        placeholder={t("confirmPassword")}
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        size="lg"
                        my="lg"
                        error={passwordsMatch ? (
                          ""
                        ) : (
                          <ErrorMessage>{t("passwordsDontMatch")}</ErrorMessage>
                      )}
                      />

                      <GeneralButton 
                        type="submit"
                        width="100%"
                        margin="20px 0px"
                      >
                        {t("change")}
                      </GeneralButton>

                  </RegisterForm>
                )}
                {activeButton === "deleteAccount" && (
                  <>
                    <Modal opened={opened} onClose={close} withCloseButton={false} centered>
                      <DeleteAccountDesc>{t("accountDeletionQuestion")}</DeleteAccountDesc>     
                      <DeleteAccountGroupButton>
                        <DeleteAccountYesButton onClick={handleDeleteUser}>{t("yes")}</DeleteAccountYesButton>
                        <DeleteAccountNoButton onClick={close}>{t("no")}</DeleteAccountNoButton>
                      </DeleteAccountGroupButton>                 
                    </Modal>             
                  </>
                )}
                
              </InfoSection>
            </UserInfoAndInfoSection>
          </Container>
        ) : (
          <Loader size={40} color="orange" borderSize={8} />
        )}

      </AccountMain>
    </>
  );
};

export default Account;

export const AccountMain = styled.main`
  background-color: #f8f8f8;
  padding-bottom: 80px;
  height: 100vh;
`;

export const AccountSettingsBackground = styled.div` 
  position: absolute;
  width: 100%
`;

export const AccountSettingsTitle = styled.div` 
  position: relative;
  max-width: 1100px;
  padding: 0 40px;
  margin: auto;
  top: 110px;
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const AccountSettingsImg = styled.img`
  width: 100%;
  height: 230px;
`;

export const Container = styled.div`
  position: relative;
  top: 140px;
  max-width: 1100px;
  padding: 40px;
  margin: auto;
  font-family: "Arial", sans-serif;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const UserInfoAndInfoSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const UserInfo = styled.div`

`;

export const AvatarInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #ddd;
`;

export const UserInfoContent = styled.div`
  flex: 1;
`;

export const NameInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
`;

export const FullName = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #555;
`;

export const Username = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #555;
`;

export const UserButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 20px;
`;

export const UserButton = styled.button`
  padding: 12px 16px;
  margin-bottom: 10px;
  background-color: ${props => props.active ? "#555" : "white"};
  color: ${props => props.active ? "#fff" : "#555"};
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #555;
    color: #fff;
  }
`;

export const UserDeleteButton = styled.button`
  padding: 12px 16px;
  margin-bottom: 10px;
  background-color: ${props => props.active ? "#555" : "red"};
  color: ${props => props.active ? "#fff" : "#fff"};
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    border: 2px solid red;
    background-color: white;
    color: red;
    font-weight: bold;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-left: 20px;
  border-left: 1px solid rgb(192, 192, 192);
`;

export const InfoSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 50px;
`;

export const InfoItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  max-width: 750px;
  margin: 50px 10px 10px 10px;
  column-gap: 40px;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  max-width: 355px;
  margin-bottom: 30px;
`;

export const Label = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
`;

export const Value = styled.p`
  font-size: 18px;
  margin: 0;
  color:grey;
`;

export const RegisterForm = styled.form`
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="password"] {
    padding: 10px;
    margin-top: 10px;
    width: 96%;
    height: 32px;
    border-radius: 5px;
    outline: none;
  }
  width: 70%;
  padding: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
export const RegisterTitle = styled.div`
  margin-bottom: 70px;
  font-size: 26px;
  text-align: center;
  font-weight: 600;
`;

export const RegisterEmailInput = styled.input``;
export const RegisterEmailLabel = styled.label``;

// export const RegisterPassword = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
// `;
export const RegisterPasswordInput = styled.input``;
export const RegisterPasswordLabel = styled.label``;
export const RegisterCPassword = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const RegisterCPasswordInput = styled.input``;
export const RegisterCPasswordLabel = styled.label``;

export const RegisterButton = styled.button`
  width: 100%;
  color: #fff;
  background: #007bff;
  height: 35px;
  margin-top: 20px;
  font-size: 17px;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
`;

export const DeleteAccountDesc = styled.p`
  text-align: center;
  font-size: 17px;
`;

export const DeleteAccountGroupButton = styled.div`
  display : flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
`;
export const DeleteAccountYesButton = styled.button`
  width: 100px;
  padding: 12px 16px;
  margin-bottom: 10px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    border: 1px solid red;
    background-color: white;
    color: black;
  }
`;
export const DeleteAccountNoButton = styled.button`
width: 100px;
  padding: 12px 16px;
  margin-bottom: 10px;
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #555;
    color: #fff;
  }
`;

export const ConfirmationPasswordInput = styled(PasswordInput)`
  input::placeholder {
    font-size: 14px;
    color: #999; 
  }
`;

export const ErrorMessage = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: red;
`;

const CurrentPasswordInput = styled(PasswordInput)`
  width: 100%;

  input::placeholder {
    font-size: 14px;
    color: #999; 
  }
`;