import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FixtureCard, Filterr, Breadcrumbs, SEO, AdImageCard } from '../../components';
import { GrPrevious, GrNext } from "react-icons/gr"
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { getFixture } from '../../services/fixtureService';
import { getAds } from '../../services/ads/adsService';
import LanguageTag from '../../enums/LanguageTag';
import NotFound from '../not-found/NotFound';
import AdsLocation from '../../enums/AdsLocation';
import { setRoundId, setStageId } from '../../redux/features/filter/filterSlice';
    

const Fixtures = ({ stageRoundsData }) => {

  const [fixtureData,setFixtureData] = useState();
  const [activeStageId,setActiveStageId] = useState();
  const [activeRoundId,setActiveRoundId] = useState();
  const [roundLength,setRoundLength] = useState();
  const [adsData, setAdsData] = useState([]);
  const [selectedStage, setSelectedStage] = useState(true);
  const [localCountryId, setLocalCountryId] = useState(localStorage.getItem("startCountryId"));
  const [showRound, setShowRound] = useState(false);


  

  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedCountryId = useSelector(state => state.filter.countryId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedSeasonId = useSelector(state => state.filter.seasonId);
  const selectedStageId = useSelector(state => state.filter.stageId);
  const selectedRoundId = useSelector(state => state.filter.roundId);
  const selectedTeamId = useSelector((state) => state.filter.teamId);
  const {roundData} = useSelector((state) => state.filter);


  let [selectedRoundIndex, setSelectedRoundIndex] = useState(roundLength);


  const {t} = useTranslation();
  const dispatch = useDispatch();
  let selectRef = useRef();
  let buttonNextRef = useRef();

  
  const handlePrevious = () => {
    const selectedIndex = selectRef.current.selectedIndex;
    setSelectedRoundIndex(selectedIndex);
    console.log("selectedIndex: ",selectedIndex);
    // console.log("roundData",roundData);
    if (selectedIndex > 0) {
      selectRef.current.selectedIndex = selectedIndex - 1;  
      setActiveRoundId(selectRef.current.value); 
      dispatch(setRoundId(selectRef.current.value));
    }
  };

  const handleNext = () => {
    let selectedIndex = selectRef.current.selectedIndex;
    console.log("selectedIndex: ",selectedIndex);
    console.log("roundData",roundData);
    // console.log("roundId",roundId);
    if (selectedIndex < roundLength) {
      selectedIndex++;
      selectedRoundIndex = selectedIndex;
      selectRef.current.selectedIndex = selectedIndex;
      setSelectedRoundIndex(selectedIndex);
      setActiveRoundId(selectRef.current.value); 
      dispatch(setRoundId(selectRef.current.value));
    }
  };

  const selectStageId = async (e) => {
    e.preventDefault();
    const newStageId = e.target.value;
    setActiveStageId(newStageId);
    dispatch(setStageId(newStageId));
    setSelectedStage(true);
    // console.log("stageID",newStageId);

    if(newStageId === t("all")){
      setActiveStageId("");
      setActiveRoundId("");
      dispatch(setStageId(""));
      dispatch(setRoundId(""));
    } else {
        const selectedStage = stageRoundsData?.find((stage) => stage.id === newStageId);
        if (selectedStage) {
          const activeRoundId = selectedStage?.rounds.find((round) => round.active)?.id;
          const length = selectedStage?.rounds?.length;
          // console.log("activeRoundId", activeRoundId);
          // console.log("roundLength", length);
          setActiveRoundId(activeRoundId);
          dispatch(setRoundId(activeRoundId));
          setRoundLength(length);
        }
    }
  };

  const selectRoundId = async (e) => {
    e.preventDefault();
    const newRoundId = e.target.value;
    setActiveRoundId(newRoundId);   
    dispatch(setRoundId(newRoundId));
    if(newRoundId === t("all")){
      setActiveRoundId("");
      dispatch(setRoundId(""));
    }
  };

  const fetchAds = async () => {
    try {
      const storedLanguageId = localStorage.getItem("languageId");
      const originalLanguage = navigator.language;
      const newLanguage = originalLanguage.split('-')[0];

      const language = Object.keys(LanguageTag).find(
        (key) => LanguageTag[key] === newLanguage
      );
      
      const languageId = storedLanguageId ? storedLanguageId : language;

      const countryId = selectedCountryId === "" ? localCountryId : selectedCountryId;
      const platformType = 1;
      const location = AdsLocation['WEB_LEFT'];
  
      const res = await getAds({ countryId, languageId, platformType, location })
  
      setAdsData(res?.data?.data || {});
    } catch (error) {
      console.log("Ads Error:",error);
    }
  }
  
  const fetchFixture = async () => {
    try {
      const sportId = selectedSportId;
      const countryId = selectedCountryId;
      const tournamentId = selectedTournamentId;
      const seasonId = selectedSeasonId;
      const teamId = selectedTeamId;
      const stageId = selectedStageId;
      const roundId = selectedRoundId;
  
      const res = await getFixture({ sportId, countryId, tournamentId, seasonId, teamId, stageId, roundId, })
  
      setFixtureData(res?.data?.data || {});
    } catch (error) {
      console.log("fetchFixture",error)
    }
  
  };
  
  useEffect(() => {  
    fetchFixture();
    fetchAds();
    {Array.isArray(stageRoundsData) && stageRoundsData.map((list) => {
      if (list.id === selectedStageId) {
        list?.rounds.length === 0 ? setShowRound(false) : setShowRound(true);
      }
    })}
  },[dispatch, showRound, stageRoundsData, selectedSportId, selectedCountryId, selectedTournamentId, selectedSeasonId, selectedStageId, selectedRoundId, activeStageId, activeRoundId, roundLength, selectedRoundIndex])


  return (
    <>
      <div>
        <SEO
          title={`${localStorage.getItem("selectedSportName") || ""} ${localStorage.getItem("selectedCountryName") || ""} ${localStorage.getItem("selectedTournamentName") || ""} ${localStorage.getItem("selectedTeamName") || ""} ${t("fixtureMetaTitle")}`}
          description={`${localStorage.getItem("selectedSportName") || ""} ${localStorage.getItem("selectedCountryName") || ""} ${localStorage.getItem("selectedTournamentName") || ""} ${localStorage.getItem("selectedTeamName") || ""} ${t("fixtureMetaDescription")}`}
          keywords={`${localStorage.getItem("selectedSportName") || ""} ${localStorage.getItem("selectedCountryName") || ""} ${localStorage.getItem("selectedTournamentName") || ""} ${localStorage.getItem("selectedTeamName") || ""} ${t("fixtureMetaKeywords")}`}
          url="https://www.fansupport.com/"
        />
      </div>
      {adsData ? 
        <>
          <AdImageCard adsData={adsData} scrollValue={250} right="0" />
          <AdImageCard adsData={adsData} scrollValue={250} left="0" />
        </> : <NotFound />
      }  
      <Filterr margin="60px auto 0px auto" useNavigateProp={true} showTeamSelect={false} showSeasonSelect={true} />
      
         <RoundContainer>

          <SelectContainer contentWidth={"48%"}>
            {Array.isArray(stageRoundsData) && stageRoundsData.length > 1 && 
              <>
                <FilterSelectLabel className="filter-select-label">{t("selectSeasonTour")}</FilterSelectLabel>
                <RoundSelect
                    onChange={ selectStageId }
                    ref={selectRef} 
                  >
                    {
                      (!Array.isArray(stageRoundsData) || stageRoundsData.length === 0) && <option>{t("all")}</option>
                    }
                    {Array.isArray(stageRoundsData) && stageRoundsData.map((list) => (
                        <option 
                          key={list.id} 
                          value={list.id} 
                          selected={list.active}
                          >
                            {list.name}
                        </option>
                    ))}
                </RoundSelect>
              </>
            }

          </SelectContainer>

          {showRound &&

          <SelectContainer contentWidth={"48%"}>
          {Array.isArray(stageRoundsData) && stageRoundsData.length > 0 &&
            <>
              <FilterSelectLabel className="filter-select-label">{t("selectWeek")}</FilterSelectLabel>
              <RoundSelect
                onChange={selectRoundId}
                ref={selectRef}
              >
                {
                  (!Array.isArray(stageRoundsData) || stageRoundsData.length === 0) && <option>{t("all")}</option>
                }
                {Array.isArray(stageRoundsData) && stageRoundsData.map((list) => {
                  if (list.id === selectedStageId) {
                      return list.rounds.map((round) => (
                        <option
                          key={round.id}
                          value={round.id}
                          selected={round.active}
                        >
                          {round.name}
                        </option>
                      ));
                  } else {
                    return null;
                  }
                })}
              </RoundSelect>
            </>
          }
          </SelectContainer>
          }

          {/* {selectedStage && (
            <>
              <RoundPrev
                onClick={handlePrevious}
                disabled={selectedRoundIndex == 0 ? true : false}
              >
                <GrPrevious className='icon' size={18} />
                <RoundPrevText>{t("Last Week")}</RoundPrevText>
              </RoundPrev>

              <RoundNext
                ref={buttonNextRef}
                onClick={handleNext}
                disabled={roundLength >= selectedRoundIndex || roundLength ? false : true}
              >
                <RoundNextText>{t("Next Week")}</RoundNextText>
                <GrNext className='icon' size={18} />
              </RoundNext>
            </>

          )} */}

        </RoundContainer>

        <FixtureMain>

          <BreadcrumbsWrapper>
            <Breadcrumbs color="white"/> 
          </BreadcrumbsWrapper>
          
          <FixtureCard data={fixtureData || [] } />

        </FixtureMain>

      <div style={{paddingTop: '618px'}} id='media-content'></div>
    </>
  );
}

export default Fixtures

export const RoundsContainer = styled.div`
  display: flex;
`;


export const RoundSelect = styled.select`
  height: 50px;
  padding: 0 20px 0 5px;
  font-size: 15px;
  border-radius: 10px;

  @media (max-width: 720px){
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const RoundPrev = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: white;
  cursor: pointer;
  height: 50px; 
  border-radius: 10px;
  padding-top: 30px;
  
  // &:hover {
  //   color: grey;
  // }
`;
export const RoundPrevText = styled.span`
  margin-left: 10px;
`;

export const RoundNext = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: white;
  cursor: pointer;
  height: 50px; 
  border-radius: 10px;
  padding-top: 30px;

  // &:hover {
  //   color: grey;
  // }
`;
export const RoundNextText = styled.text`
  margin-right: 10px;
`;

export const RoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  margin: 0 auto 10px auto;
  padding: 0 15px;
  gap: 10px;

  @media screen and (min-width: 900px) and (max-width: 1412px) {
    width: 68%;
  }
`;

export const BreadcrumbsWrapper = styled.div`
  margin: 10px 0 20px 5px;
`;

export const FixtureMain = styled.div`
  max-width: 1106px;
  margin: 0px auto;

  @media screen and (min-width: 900px) and (max-width: 1530px) {
    width: 68%
  }
  @media screen and (min-width: 900px) and (max-width: 1300px) {
    width: 60%;
  }
  @media (max-width: 900px) {
    width: 92%;
    margin: 0px auto;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.contentWidth || "475px"};
  margin: 10px 0;
`;
export const FilterSelectLabel = styled.div`
  margin: 7px 10px;
  text-align: start;
  font-size: 13px;
  font-weight: bold;
  position: inherit;
  color: var(--color);
`;