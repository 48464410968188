import React,{useState, useEffect} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FavoritesButton } from "../../components";
import { useSelector } from "react-redux";
import { NotFound } from "../../pages";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { getAddFavoriteTeam } from "../../services/sport/addFavoriteTeamService";
import { getDeleteFavoriteTeam } from "../../services/sport/deleteFavoriteTeamService";
import { getFavoriteTeams } from "../../services/favoriteTeamsService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getStartLanguage } from "../../logic/getStartLanguage";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";

const AddFavoritesCard = () => {

  const [favouriteTeamsData, setFavouriteTeamsData] = useState([]);

  const filterTeams = useSelector((state) => state.filter.filterTeams);
  const tournamentId = useSelector(state => state.filter.selectedTournamentId);
  
  const {t} = useTranslation();
  const user  = useSelector((state) => state.user);
  const navigate = useNavigate();
  const startLanguage = getStartLanguage();

  // console.log("filterTeams",filterTeams);

  const handleAddClick = (teamId) => {
    fetchAddFavoriteTeam(teamId);
  };

  const handleRemoveClick = (teamId) => {
    fetchDeleteFavoriteTeam(teamId);
  };

  const fetchFavouriteTeams = async () => {

    try {
      const sportId = "";
      const page = 1;
      const perPage = 25;
      const res = await getFavoriteTeams({ sportId, page, perPage });
      setFavouriteTeamsData(res.data?.data?.data);
    } catch (error) {
      console.error("Request failed with error:", error);
    }
  };

  // console.log("favouriteTeamsData",favouriteTeamsData);

  const fetchAddFavoriteTeam = async (teamId) => {
    try {

      if(user.user){
        const res = await getAddFavoriteTeam({ teamId, tournamentId });

        if (res?.data?.data === true) {
          toast.success(t("favoriteTeamAdded"));

          navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("favouriteTeams") })}`)
        }
      }
      else {
        navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`)
      }

    } catch (error) {
        console.error("Request failed with error:", error);
    }
  };

  const fetchDeleteFavoriteTeam = async (teamId) => {
    try {
        const res = await getDeleteFavoriteTeam({ teamId, tournamentId });

      if (res?.data?.data === true) {
        toast.error(t("favoriteTeamRemoved")); 
      }

      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);

      navigate(`/${startLanguage}/${RemoveTurkishChars({ text: t("favouriteTeams") })}`)

    } catch (error) {
        console.log("Request failed with error:", error);
    }
  };


  const convertToTitleCase = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const handleLogoClick = (team, teamId) => {

    if (team, teamId) {
      const startLanguage = getStartLanguage();
      const formattedTeamName = convertToTitleCase(team);
      const sportType = localStorage.getItem("selectedSportName");

      window.location.href = `/${startLanguage}/${sportType}/${RemoveTurkishChars({ text: t("team") })}/${formattedTeamName}/${teamId}`;
      // console.log(`Tıklanan takım: ${formattedTeamName}`);
      // console.log("Tıklanan takım", teamId);

    } else{
      return <NotFound />
    }

  };

  useEffect(() => {
    fetchFavouriteTeams()
  }, []);

  return (
    <>
      <ToastContainer />
      <NotFoundsContainer>
      {filterTeams.length > 0 ? (
          filterTeams.map((list) => {
            const isFavorite = favouriteTeamsData.some((item) => item.teamId === list.id);

            return (
              <FavoritesCardContainer key={list.id} className="add-favorites-card-container">
                <FavoritesCardLeft
                  className="favorites-card-left"
                  onClick={() => handleLogoClick(list.name, list.id)}
                >
                  <TeamsLogo src={list.largeBadge ? list.largeBadge : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/6f6a87c5-fd75-45c3-0f8c-33977a168700/original"} alt={""} />
                  <TeamsName>{list.name}</TeamsName>
                </FavoritesCardLeft>
                <FavoritesCardRight>
                  {isFavorite ? (
                    <FavoritesButton
                      text={t("removeFavorites")}
                      icon={<CiCircleRemove size={25} />}
                      isAdded={false}
                      onClick={() => handleRemoveClick(list.id)}
                    />
                  ) : (
                    <FavoritesButton
                      text={t("addFavorites")}
                      icon={<MdOutlineCheckCircleOutline size={25} />}
                      isAdded={true}
                      onClick={() => handleAddClick(list.id)}
                    />
                  )}
                </FavoritesCardRight>
              </FavoritesCardContainer>
            );
          })
        ) : (
          <NotFound text={t("teamFilter")} />
        )}
      </NotFoundsContainer>
    </>
  );
};

export default AddFavoritesCard;

export const FavoritesCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 20px;
`;

export const FavoritesCardLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  @media (max-width: 720px){
    justify-content: start;
    width: 165px;
  }
  
`;

export const TeamsLogo = styled.img`
  width: 36px;
  height: 36px;
`;

export const TeamsName = styled.span``;

export const FavoritesCardRight = styled.div``;

export const NotFoundsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
