import { configureStore } from '@reduxjs/toolkit';
import  authSlice  from "../features/auth/authSlice";
import filterSlice from "../features/filter/filterSlice";
import errorSlice from "../features/error/errorSlice";


export const store = configureStore({
  reducer: {
    user: authSlice,
    filter: filterSlice,
    error: errorSlice,
  },

});
