import { fansupportPrivate } from "../index";

export const getPollPrivate = async ({ sportId, countryId, tournamentId, teamId, languageId, page, perPage}) => {
    try {
        return fansupportPrivate.get('/poll',{ params: { 
            sportId,
            countryId,
            tournamentId,
            teamId,
            languageId,
            page,
            perPage
        }})   
    } catch (error) {
        console.log("getPoll",error);
    }    
} 