import { fansupportPrivate } from "../index";

export const postAnswer = async ({pollId, answerId}) => {
     console.log("payload", pollId, answerId);
    try {
        const data =  fansupportPrivate.post('/poll/answer', {
            pollId: pollId,
            answerId: answerId
    })
        return data;

    } catch (error) {
        console.log("postAnswer",error);
    }
}