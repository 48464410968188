import React, { useEffect, useState } from 'react'
import { NewsFromUsCard } from '../../components';
import styled from 'styled-components';
import backgroundSVG from "../../assets/gradient-topography.svg"
import { useSelector } from 'react-redux';
import { getNews } from '../../services/news/NewsService';
import LanguageTag from '../../enums/LanguageTag';


const NewsFromUs = () => {

  const [newsData,setNewsData] = useState();


  const selectedSportId = useSelector(state => state.filter.sportId);
  const selectedCountryId = useSelector(state => state.filter.countryId);
  const selectedTournamentId = useSelector(state => state.filter.tournamentId);
  const selectedTeamId = useSelector((state) => state.filter.teamId);

  const fetchNews = async () => {

    const storedLanguageId = localStorage.getItem("languageId");
    const originalLanguage = navigator.language;
    const newLanguage = originalLanguage.split('-')[0];

    const language = Object.keys(LanguageTag).find(
      (key) => LanguageTag[key] === newLanguage
    );

    const languageId = storedLanguageId ? storedLanguageId : language;
    const status = true;
    // const sportId = selectedSportId;
    // const countryId = selectedCountryId;
    // const tournamentId = selectedTournamentId;
    // const teamId = selectedTeamId;    
    const page = 1;
    const perPage = 25;
  
    const res = await getNews({ languageId, status, page, perPage })
  
    setNewsData(res?.data?.data?.data || []);
  };
  
  // console.log("news",newsData);

  useEffect(() => {
    fetchNews();
  },[selectedSportId, selectedCountryId, selectedTournamentId, selectedTeamId])

  return (
    <NewsFromUsMain>

      <NewsFromUsContainer>
        {newsData?.map((news) => (
          <NewsFromUsCard 
            key={news.id}
            id={news.id}
            date={news.publishDate}
            title={news.title}
            desc={news.description}
            image={news.imageUrl}
          />
        ))}        
      </NewsFromUsContainer>
    </NewsFromUsMain>
  )
}

export default NewsFromUs

export const NewsFromUsMain = styled.main`
  background-image: url(${backgroundSVG});
  background-size: cover;
  background-position: center;
`;
export const NewsFromUsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 1240px;
  margin: 0 auto;
  padding: 30px 0;
`;