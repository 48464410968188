import React from 'react';

const DateTimeFormatter = ({ apiTarih, dil, onlyDayAndMonth }) => {
  const languageOptions = {
    'tr': 'tr-TR',
    'en': 'en-US',
    'es': 'es-ES'
  };

  const formatOptions = {
    'tr': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    },
    'en': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    },
    'es': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }
  };

  try {
    const dateObject = new Date(apiTarih);
    if (isNaN(dateObject.getTime())) {
      throw new Error('Invalid date');
    }

    const formattedTarih = new Intl.DateTimeFormat(languageOptions[dil], formatOptions[dil]).format(dateObject);
    
    if (onlyDayAndMonth) {
      const [day, month] = formattedTarih.split(" ");
      return <>{`${day} ${month}`}</>;
    }
    
    return <>{formattedTarih}</>;
    
  } catch (error) {
    console.error('Error formatting date:', error);
    return <></>;
  }
};

export default DateTimeFormatter;