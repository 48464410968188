import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Tooltip = ({ text, children, position, delay, backgroundColor, color, borderRadius }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setTimeout(() => {
      setShowTooltip(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <TooltipWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {showTooltip && (
        <TooltipContent position={position} backgroundColor={backgroundColor} color={color} borderRadius={borderRadius}>
          {text}
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  delay: PropTypes.number,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  borderRadius: PropTypes.string,
};

Tooltip.defaultProps = {
  position: "top",
  delay: 0,
  backgroundColor: "#333",
  color: "#fff",
  borderRadius: "4px",
};

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipContent = styled.div`
  position: absolute;
  ${({ position }) => {
    switch (position) {
      case "top":
        return "top: calc(100% + 5px); left: 50%; transform: translateX(-50%);";
      case "bottom":
        return "bottom: calc(100% + 5px); left: 50%; transform: translateX(-50%);";
      case "left":
        return "top: 50%; left: calc(100% + 5px); transform: translateY(-50%);";
      case "right":
        return "top: 50%; right: calc(100% + 5px); transform: translateY(-50%);";
      default:
        return "";
    }
  }}
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  padding: 5px 10px;
  border-radius: ${({ borderRadius }) => borderRadius};
  white-space: nowrap;
  z-index: 1;
`;

export default Tooltip;
