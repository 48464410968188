import { fansupportPublic } from "./index";

export const getStandings = async ({ sportId, countryId, tournamentId }) => {
    if (!sportId || !countryId || !tournamentId) {
        return Promise.reject(new Error("Sport ID, country ID, or tournament ID is missing."));
    }
    return fansupportPublic.get('/sport/standings', {
        params: {
            sportId,
            countryId,
            tournamentId,
        },
        headers: {
            "Accept-Language": "tr-TR"
        }
    });
};