import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mantine/core';

const AdImageCard = ({ adsData, right, left, scrollValue, loading  }) => {
  const [isFixed, setIsFixed] = useState(false);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const intervalRefs = useRef([]);


  useEffect(() => {
    if (adsData.length > 0) {
      intervalRefs.current = adsData?.map(ad => setInterval(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % adsData.length);
        // console.log("ree",ad.switchInterval);
      }, ad.switchInterval * 1000));
      
      return () => {
        // Component unmount olduğunda interval'ları temizle
        intervalRefs.current.forEach(interval => clearInterval(interval));
      };
    }
  }, [adsData]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const newsCarouselSection = document.querySelector('#media-content');
  
      if (newsCarouselSection) {
        const newsCarouselSectionTop = newsCarouselSection.getBoundingClientRect().top + window.scrollY;
  
        if (scrollY > scrollValue && scrollY < newsCarouselSectionTop) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollValue]);
  

  return (
    <Container>
      {loading ? (
        <SkeletonContainer
          right={right}
          left={left}
        >
          <Skeleton radius="md" animate/>
        </SkeletonContainer>
      ) : adsData && adsData.length > 0 ? (
      adsData?.map((ad, index) => (
        <AdImageLink key={index} to={ad.url} target="_blank" rel="noopener noreferrer">
          <AdImage
            key={index}
            src={ad.image}
            isFixed={isFixed}
            alt={``}
            isDisplayed={index === currentAdIndex}
            right={right}
            left={left}
          />
        </AdImageLink>
      )) ) : (
        ""
      )}
    </Container>
  )
};

export default AdImageCard;

export const AdImage = styled.img`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
  top: ${({ isFixed }) => (isFixed ? 0 : '')};
  ${({ right }) => (right ? `right: ${right};` : '')}
  ${({ left }) => (left ? `left: ${left};` : '')}
  width: 120px;
  height: auto;
  z-index: 9;
  margin-top: 50px;
  padding: 0 10px;
  ${({ isDisplayed }) => (isDisplayed ? 'opacity: 1;' : 'opacity: 0;')}
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Container = styled.div``;

const AdImageLink = styled(Link)``;

const SkeletonContainer = styled.div`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
  ${({ right }) => (right ? `right: ${right};` : '')}
  ${({ left }) => (left ? `left: ${left};` : '')}
  top: ${({ isFixed }) => (isFixed ? 0 : '')};
  width: 120px;
  height: auto;
`;
