import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationTR from "../src/locales/translationTR.json"
import translationEN from "../src/locales/translationEN.json"
import translationES from "../src/locales/translationES.json"
import { getStartLanguage } from './logic/getStartLanguage';

const startLanguage = getStartLanguage();

i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: translationEN,
      },
      tr: {
        translation: translationTR,
      },
      es: {
        translation: translationES,
      },
    },
    lng: startLanguage, // Kullanıcının tercih ettiği dil
    fallbackLng: startLanguage, // Yedek dil
    interpolation: {
      escapeValue: false,
    },
  });
  
  export default i18n;