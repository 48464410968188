import { fansupportPublic } from "../index";

export const getResetPassword = async ( token, password, passwordConfirm ) => {
    // console.log("payload", token);
    try {
        const data = await fansupportPublic.post('/identity/reset-password', {
            token: token,
            password: password,
            passwordConfirm: passwordConfirm
    })
        return data;

    } catch (error) {
        console.log("getResetPassword", error);
    }
}